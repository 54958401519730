import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faBold,
  faChevronDown,
  faChevronUp,
  faCode,
  faHighlighter,
  faItalic,
  faListOl,
  faListUl,
  faQuoteRight,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faTextWidth,
  faUnderline,
  faParagraph,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import { RichUtils } from "draft-js";

const Toolbar = ({ editorState, setEditorState, handleKeyCommand }) => {
  const tools = [
    {
      label: "Bold",
      style: "BOLD",
      icon: <FontAwesomeIcon icon={faBold} />,
      method: "inline",
      shortcut: "Ctrl+B",
    },
    {
      label: "Italic",
      style: "ITALIC",
      icon: <FontAwesomeIcon icon={faItalic} />,
      method: "inline",
      shortcut: "Ctrl+I",
    },
    // {
    //   label: "Underline",
    //   style: "UNDERLINE",
    //   icon: <FontAwesomeIcon icon={faUnderline} />,
    //   method: "inline",
    //   shortcut: "Ctrl+U",
    // },
    // {
    //   label: "Highlight",
    //   style: "HIGHLIGHT",
    //   icon: <FontAwesomeIcon icon={faHighlighter} />,
    //   method: "inline",
    //   shortcut: "Ctrl+H",
    // },
    {
      label: "Strikethrough",
      style: "STRIKETHROUGH",
      icon: <FontAwesomeIcon icon={faStrikethrough} />,
      method: "inline",
      shortcut: "Ctrl+Shift+S",
    },
    // {
    //   label: "Superscript",
    //   style: "SUPERSCRIPT",
    //   icon: <FontAwesomeIcon icon={faSuperscript} />,
    //   method: "inline",
    //   shortcut: "Ctrl+Shift+.",
    // },
    // {
    //   label: "Subscript",
    //   style: "SUBSCRIPT",
    //   icon: <FontAwesomeIcon icon={faSubscript} />,
    //   method: "inline",
    //   shortcut: "Ctrl+Shift+,",
    // },
    {
      label: "Monospace",
      style: "CODE",
      icon: <FontAwesomeIcon icon={faTextWidth} transform="grow-3" />,
      method: "inline",
      shortcut: "Ctrl+Shift+M",
    },
    // {
    //   label: "Blockquote",
    //   style: "blockQuote",
    //   icon: <FontAwesomeIcon icon={faQuoteRight} transform="grow-2" />,
    //   method: "block",
    //   shortcut: "Ctrl+Shift+Q",
    // },
    {
      label: "Unordered List",
      style: "unordered-list-item",
      method: "block",
      icon: <FontAwesomeIcon icon={faListUl} transform="grow-6" />,
      shortcut: "Ctrl+Shift+U",
    },
    {
      label: "Ordered List",
      style: "ordered-list-item",
      method: "block",
      icon: <FontAwesomeIcon icon={faListOl} transform="grow-6" />,
      shortcut: "Ctrl+Shift+O",
    },
    // {
    //   label: "Code Block",
    //   style: "CODEBLOCK",
    //   icon: <FontAwesomeIcon icon={faCode} transform="grow-3" />,
    //   method: "inline",
    //   shortcut: "Ctrl+Shift+C",
    // },
    // {
    //   label: "Uppercase",
    //   style: "UPPERCASE",
    //   icon: <FontAwesomeIcon icon={faChevronUp} transform="grow-3" />,
    //   method: "inline",
    //   shortcut: "Ctrl+Shift+U",
    // },
    // {
    //   label: "Lowercase",
    //   style: "LOWERCASE",
    //   icon: <FontAwesomeIcon icon={faChevronDown} transform="grow-3" />,
    //   method: "inline",
    //   shortcut: "Ctrl+Shift+L",
    // },
    // {
    //   label: "Left Align",
    //   style: "leftAlign",
    //   icon: <FontAwesomeIcon icon={faAlignLeft} transform="grow-2" />,
    //   method: "block",
    //   shortcut: "Ctrl+Shift+L",
    // },
    // {
    //   label: "Center Align",
    //   style: "centerAlign",
    //   icon: <FontAwesomeIcon icon={faAlignCenter} transform="grow-2" />,
    //   method: "block",
    //   shortcut: "Ctrl+Shift+E",
    // },
    // {
    //   label: "Right Align",
    //   style: "rightAlign",
    //   icon: <FontAwesomeIcon icon={faAlignRight} transform="grow-2" />,
    //   method: "block",
    //   shortcut: "Ctrl+Shift+R",
    // },
    {
      label: "H1",
      style: "header-one",
      method: "block",
      shortcut: "Ctrl+Shift+1",
    },
    {
      label: "H2",
      style: "header-two",
      method: "block",
      shortcut: "Ctrl+Shift+2",
    },
    {
      label: "H3",
      style: "header-three",
      method: "block",
      shortcut: "Ctrl+Shift+3",
    },
    {
      label: "H4",
      style: "header-four",
      method: "block",
      shortcut: "Ctrl+Shift+4",
    },
    {
      label: "H5",
      style: "header-five",
      method: "block",
      shortcut: "Ctrl+Shift+5",
    },
    {
      label: "H6",
      style: "header-six",
      method: "block",
      shortcut: "Ctrl+Shift+6",
    },
    {
      label: "Paragraph",
      style: "unstyled",
      method: "block",
      icon: <FontAwesomeIcon icon={faParagraph} />,
      shortcut: "Ctrl+Shift+P",
    },
  ];

  const applyStyle = (style, method) => {
    method === "block"
      ? setEditorState(RichUtils.toggleBlockType(editorState, style))
      : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const isActive = (style, method) => {
    if (method === "block") {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  return (
    <div className="toolbar-grid">
      {tools.map((item, idx) => (
      
          <button
            style={{
              color: isActive(item.style, item.method)
                ? "rgba(0, 0, 0, 1)"
                : "rgba(0, 0, 0, 0.3)",
                marginLeft: "0.3rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              applyStyle(item.style, item.method);
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {item.icon || item.label}
          </button>

      ))}
    </div>
  );
};

export default Toolbar;
