import React, { useState, useEffect } from "react";
import { LoginForm, SignUpForm } from "../../components";
import rh_logo from "../../images/rh_logo.png";

import "./AuthForms.css";
const AuthForms = (props) => {
  const [isLogin, setIsLogin] = useState(props.isLogin || true);

  useEffect(() => {
    localStorage.setItem("legacy", "false");
  }, []);

  return (
    <div>
      <div className="logo">
        <img src={rh_logo} alt="logo" />
      </div>

      {isLogin ? (
        <LoginForm setIsLogin={setIsLogin} />
      ) : (
        <SignUpForm setIsLogin={setIsLogin} />
      )}
    </div>
  );
};

export default AuthForms;
