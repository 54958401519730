import React, { useState, useEffect } from "react";

import {
  Feed,
  Receipt,
  ArrowBack,
  Done,
  MapsHomeWork,
  Shortcut,
} from "@mui/icons-material";
import {
  Editor,
  ContentState,
  EditorState,
  convertFromRaw,
  convertFromHTML,
  RichUtils,
} from "draft-js";
import "./ViewArrivalForm.css";
import DraftEditor from "../Editor/DraftEditor";
import {
  AddPropertyForm,
  AddReservationForm,
  AddTicketForm,
  ViewProperty,
  TicketTable,
} from "../../components";
import axios from "axios";
import { Alert } from "@mui/material";
import apiUrl from "../api";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const convertHtmlToEditorState = (html) => {
  const contentBlock = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks,
    contentBlock.entityMap
  );
  return EditorState.createWithContent(contentState);
};

const convertHTMLToText = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent ||
    doc.body.innerText ||
    doc.body.innerHTML.replace(/<[^>]*>/g, "")
    ? doc.body.textContent ||
        doc.body.innerText ||
        doc.body.innerHTML.replace(/<[^>]*>/g, "")
    : "";
};
const ViewArrivalForm = (props) => {
  const closeForm = props.closeForm;
  const doneArrival = props.doneArrival;
  const [propertyName, setPropertyName] = useState(props.propertyName || "");
  const [proeprtyId, setPropertyId] = useState(props.propertyId || null);
  const [arrivalDate, setArrivalDate] = useState(props.arrivalDate || "");
  const [arrivalTime, setArrivalTime] = useState(props.arrivalTime || "");
  const [arrivalType, setArrivalType] = useState(props.arrivalType || "");
  const [assignedTo, setAssignedTo] = useState(props.assignedTo || "");
  const [notes, setNotes] = useState(props.notes || "");
  const [reservationId, setReservationId] = useState(
    props.reservationId || null
  );
  const [newNote, setNewNote] = useState(convertHtmlToEditorState(""));
  const [done, setDone] = useState(props.done || false);
  const [reservationView, setReservationView] = useState(false);
  const [propertyView, setPropertyView] = useState(false);
  const [ticketView, setTicketView] = useState(false);
  const [reletadTicketsView, setReletadTicketsView] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [actionData, setActionData] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));

  const handleDone = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/arrival/finish/${props.arrivalId}`,
        {
          notes: newNote.getCurrentContent().getPlainText(),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setError("");
      setSuccess("Arrival completed successfully");
      doneArrival();
      setTimeout(() => {
        closeForm();
      }, 1000);
    } catch (err) {
      console.log(err);
      setError("Error completing arrival");
      setSuccess("");
    }
  };

  const handleNewNoteChange = (blockType) => {
    setNewNote(blockType);
  };

  useEffect(() => {
    const fetchReservation = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/reservation/getReservation/${props.reservationId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const checkinDate = new Date(response.data.reservation.checkIn);
        const checkoutDate = new Date(response.data.reservation.checkOut);

        const checkInDayAfter = new Date(
          checkinDate.setDate(checkinDate.getDate() + 1)
        )
          .toISOString()
          .split("T")[0];
        const checkOutDayAfter = new Date(
          checkoutDate.setDate(checkoutDate.getDate() + 1)
        )
          .toISOString()
          .split("T")[0];

        setActionData({
          reservation_id: response.data.reservation.reservationId,
          property: {
            value: response.data.reservation.property,
            label: response.data.reservation.property.name,
          },
          nights: response.data.reservation.nights,
          adults: response.data.reservation.adults,
          kids: response.data.reservation.kids,
          checkin: response.data.reservation.checkIn,
          checkout: response.data.reservation.checkOut,
          staffNotes: response.data.reservation.staffNotes,
          firstName: response.data.reservation.firstName,
          lastName: response.data.reservation.lastName,
          email: response.data.reservation.email,
          phone: response.data.reservation.phone,
          cribs: response.data.reservation.cribs,
          highChairs: response.data.reservation.highChairs,
          idPassport: response.data.reservation.idPassport,
          guestTotal: response.data.reservation.guestTotal,
          channel: response.data.reservation.channel,
          channelAmount: response.data.reservation.channelAmount,
          customerNotes: response.data.reservation.customerNotes,
          arrivalTime: response.data.reservation.arrivalTime,
          arrivalLocation: response.data.reservation.arrivalLocation,
          arrivalFlight: response.data.reservation.arrivalFlight,
          departureTime: response.data.reservation.departureTime,
          departureLocation: response.data.reservation.departureLocation,
          departureFlight: response.data.reservation.departureFlight,
        });

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchReservation();
  }, []);

  return (
    <>
      <div className="view-arrival-form">
        {/* Return */}
        {(ticketView ||
          propertyView ||
          reservationView ||
          reletadTicketsView) && (
          <div
            className="add-ticket-button"
            style={{
              display: JSON.parse(localStorage.getItem("user")).permissions
                .canCreateTicket
                ? "flex"
                : "none",
            }}
          >
            <button
              className="add-ticket-button__button"
              style={{
                backgroundColor: props.color || "var(--secondary-color)",
                color: props.textColor || "var(--primary-text-color)",
                fontSize: props.fontSize || "15px",
                fontWeight: props.fontWeight || "600",
                width: props.width || "100%",
              }}
              onClick={() => {
                setTicketView(false);
                setPropertyView(false);
                setReservationView(false);
                setReletadTicketsView(false);
              }}
            >
              <ArrowBack />
              <span>Return Back</span>
            </button>
          </div>
        )}
        {ticketView && (
          <AddTicketForm
            setShowAddTicketForm={() => {}}
            reservation={reservationId}
          />
        )}
        {propertyView && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              selfAlign: "center",
              justifySelf: "center",

              width: "100%",
            }}
          >
            <ViewProperty propertyId={proeprtyId} />
          </div>
        )}
        {reservationView && (
          <AddReservationForm
            view={true}
            reservationId={reservationId}
            property={actionData.property.label}
            nights={actionData.nights}
            adults={actionData.adults}
            kids={actionData.kids}
            checkIn={actionData.checkin}
            checkOut={actionData.checkout}
            staffNotes={actionData.staffNotes}
            firstName={actionData.firstName}
            lastName={actionData.lastName}
            email={actionData.email}
            phone={actionData.phone}
            cribs={actionData.cribs}
            highChairs={actionData.highChairs}
            idPassport={actionData.idPassport}
            guestTotal={actionData.guestTotal}
            channel={actionData.channel}
            channelAmount={actionData.channelAmount}
            customerNotes={actionData.customerNotes}
            arrivalTime={actionData.arrivalTime}
            arrivalLocation={
              actionData.arrivalLocation
                ? actionData.arrivalLocation
                : "No arrival location"
            }
            arrivalFlight={actionData.arrivalFlight}
            departureTime={actionData.departureTime}
            departureLocation={
              actionData.departureLocation
                ? actionData.departureLocation
                : "No departure location"
            }
            departureFlight={actionData.departureFlight}
            reservation_id={actionData.reservation_id}
          />
        )}
        {reletadTicketsView && (
          <TicketTable
            visibleSearch={false}
            searchProperty={[
              {
                value: {
                  _id: proeprtyId,
                },
              },
            ]}
          />
        )}

        {/* info */}
        {!propertyView &&
          !ticketView &&
          !reservationView &&
          !reletadTicketsView && (
            <>
              <div className="view-arrival-property-name">
                <h2>{propertyName}</h2>
              </div>
              <div className="view-arrival-date">
                <h3>
                  {formatDate(arrivalDate)}{" "}
                  {arrivalTime ? arrivalTime : "No arrival time"}
                </h3>
              </div>

              <div className="view-arrival-type">
                <h4>{arrivalType}</h4>
              </div>

              {notes && (
                <div className="view-arrival-notes">
                  <h4>Important Notes:</h4>
                  {convertHTMLToText(notes) !== "" && (
                    <Editor
                      editorState={convertHtmlToEditorState(notes)}
                      readOnly={true}
                    />
                  )}

                  {convertHTMLToText(notes) === "" && <p>No notes</p>}
                </div>
              )}

              {actionData.staffNotes && (
                <div className="view-arrival-notes">
                  <h4>Staff Notes:</h4>
                  {convertHTMLToText(actionData.staffNotes) !== "" && (
                    <Editor
                      editorState={convertHtmlToEditorState(
                        actionData.staffNotes
                      )}
                      readOnly={true}
                    />
                  )}

                  {convertHTMLToText(actionData.staffNotes) === "" && (
                    <p>No notes</p>
                  )}
                </div>
              )}

              {actionData.customerNotes && (
                <div className="view-arrival-notes">
                  <h4>Customer Notes:</h4>
                  {convertHTMLToText(actionData.customerNotes) !== "" && (
                    <Editor
                      editorState={convertHtmlToEditorState(
                        actionData.customerNotes
                      )}
                      readOnly={true}
                    />
                  )}

                  {convertHTMLToText(actionData.customerNotes) === "" && (
                    <p>No notes</p>
                  )}
                </div>
              )}
            </>
          )}

        {/* Menu Buttons */}
        {!ticketView &&
          !propertyView &&
          !reservationView &&
          !reletadTicketsView && (
            <>
              <div
                className="add-ticket-button"
                style={{
                  display: JSON.parse(localStorage.getItem("user")).permissions
                    .canCreateTicket
                    ? "flex"
                    : "none",
                  paddingTop: "20px",
                }}
              >
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    setTicketView(true);
                  }}
                >
                  <Feed />
                  <span>Report a problem</span>
                </button>
              </div>
              <div className="add-ticket-button">
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    setPropertyView(true);
                  }}
                >
                  <MapsHomeWork />
                  <span>Property Info</span>
                </button>
              </div>
              <div
                className="add-ticket-button"
                style={{
                  display:
                    JSON.parse(localStorage.getItem("user")).permissions
                      .canCreateTicket && reservationId
                      ? "flex"
                      : "none",
                }}
              >
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    setReservationView(true);
                  }}
                >
                  <Receipt />
                  <span>Reservation Info</span>
                </button>
              </div>

              <div className="add-ticket-button">
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                  onClick={() => {
                    setReletadTicketsView(true);
                  }}
                >
                  <Shortcut />
                  <span>Related Tickets</span>
                </button>
              </div>

              <div>
                <h4>Note for completion:</h4>
                <DraftEditor
                  editorState={newNote}
                  setEditorState={handleNewNoteChange}
                />
              </div>
              {!done && (
                <div
                  className="add-ticket-button"
                  style={{
                    display: JSON.parse(localStorage.getItem("user"))
                      .permissions.canCreateTicket
                      ? "flex"
                      : "none",
                  }}
                >
                  <button
                    className="add-ticket-button__button"
                    style={{
                      backgroundColor: props.color || "var(--primary-color)",
                      color: props.textColor || "var(--primary-text-color)",
                      fontSize: props.fontSize || "15px",
                      fontWeight: props.fontWeight || "600",
                      width: props.width || "100%",
                    }}
                    onClick={() => {
                      handleDone();
                    }}
                  >
                    <Done />
                    <span>Complete Arrival</span>
                  </button>
                </div>
              )}
            </>
          )}

        {/* Return */}
        {(ticketView ||
          propertyView ||
          reservationView ||
          reletadTicketsView) && (
          <div
            className="add-ticket-button"
            style={{
              display: JSON.parse(localStorage.getItem("user")).permissions
                .canCreateTicket
                ? "flex"
                : "none",
            }}
          >
            <button
              className="add-ticket-button__button"
              style={{
                backgroundColor: props.color || "var(--secondary-color)",
                color: props.textColor || "var(--primary-text-color)",
                fontSize: props.fontSize || "15px",
                fontWeight: props.fontWeight || "600",
                width: props.width || "100%",
              }}
              onClick={() => {
                setTicketView(false);
                setPropertyView(false);
                setReservationView(false);
                setReletadTicketsView(false);
              }}
            >
              <ArrowBack />
              <span>Return Back</span>
            </button>
          </div>
        )}
        {/* Success/Error */}
        {success && (
          <div className="success">
            <Alert severity="success">{success}</Alert>
          </div>
        )}
        {error && (
          <div className="error">
            <Alert severity="error">{error}</Alert>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewArrivalForm;
