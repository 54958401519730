import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import apiUrl from "../api";

import "./addTicketForm.css";
import DraftEditor from "../Editor/DraftEditor";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const AddTicketForm = (props) => {
  const [ticketData, setTicketData] = useState({
    title: "",
    description: EditorState.createEmpty(), // Initialize the editor state
    type: "",
    priority: "",
    status: "",
    group: [],
    assignedTo: [],
    property: "",
    reservationId: "",
    customerFirstName: "",
    customerLastName: "",
    dueDate: "",
    gonnaHandleAt: "",
    checkInDate: "",
    checkOutDate: "",
    attachments: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [users, setUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [reservationIds, setReservationIds] = useState([]);
  const [groups, setGroups] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const closeWindow = () => {
    props.setShowAddTicketForm(false);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  // Fetching

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/getUsers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
      setUsersOptions(
        response.data.users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/group/getGroups`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchReservationIds = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/reservation/getReservationsIds`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setReservationIds(response.data.reservations);

      if (props.reservation) {
        const reservation = response.data.reservations.find(
          (reservation) =>
            reservation.reservationId === props.reservation ||
            reservation._id === props.reservation
        );
        if (reservation) {
          setTicketData({
            ...ticketData,
            reservationId: {
              value: reservation,
              label: reservation.reservationId,
            },
            customerFirstName: reservation.firstName,
            customerLastName: reservation.lastName,
            checkInDate: formatDateData(reservation.checkIn),
            checkOutDate: formatDateData(reservation.checkOut),
            property: {
              value: properties.find((property) =>
                localStorage.getItem("legacy") === "true"
                  ? property.name === reservation.property
                  : property._id === reservation.property
              ),
              label: reservation.property,
            },
          });
        }
      }
    } catch (error) {
      console.error("Failed to fetch reservation IDs: ", error);
    }
  };

  const fetchReservation = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/reservation/getReservation/${props.reservation}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const reservation = response.data.reservation;
      if (reservation) {
        setTicketData({
          ...ticketData,
          reservationId: {
            value: reservation,
            label: reservation.reservationId,
          },
          customerFirstName: reservation.firstName,
          customerLastName: reservation.lastName,
          checkInDate: formatDateData(reservation.checkIn),
          checkOutDate: formatDateData(reservation.checkOut),
          property: {
            value: reservation.property,
            label:
              localStorage.getItem("legacy") === "true"
                ? reservation.property
                : reservation.property.name,
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch reservation: ", error);
    }
  };
  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/property/getPropertiesNames`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket-options/getStatusOptions`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setStatusOptions(response.data.statusOptions);
    } catch (error) {
      console.error("Failed to fetch status options: ", error);
    }
  };

  const fetchPriorityOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket-options/getPriorityOptions`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setPriorityOptions(response.data.priorityOptions);
    } catch (error) {
      console.error("Failed to fetch priority options: ", error);
    }
  };

  const fetchCategoryOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket-options/getCategoryOptions`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCategoryOptions(response.data.categoryOptions);
    } catch (error) {
      console.error("Failed to fetch category options: ", error);
    }
  };

  const handleGroupChange = (selectedGroups) => {
    const newUsers = selectedGroups.flatMap((group) =>
      group.value.users.map((user) => ({
        value: user,
        label: user.username,
        tooltip: user.name,
      }))
    );

    // Combine existing assignedTo and newUsers, then use a Set to deduplicate
    const uniqueAssignedTo = [...ticketData.assignedTo, ...newUsers];
    const uniqueAssignedToSet = new Set(uniqueAssignedTo.map(JSON.stringify)); // Convert to JSON for Set comparison
    const deduplicatedAssignedTo = Array.from(uniqueAssignedToSet).map(
      JSON.parse
    ); // Convert back from JSON

    setTicketData({
      ...ticketData,
      group: selectedGroups,
      assignedTo: deduplicatedAssignedTo,
    });

    setUsersOptions(
      usersOptions.filter(
        (user) =>
          !selectedGroups
            .flatMap((group) => group.value.users.map((user) => user._id))
            .includes(user.value._id)
      )
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleDescriptionChange = (editorState) => {
    setTicketData({ ...ticketData, description: editorState });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files);
    const fileNames = newFiles.map((file) => file.name);
    const allFiles = [...ticketData.attachments, ...newFiles];
    setTicketData({ ...ticketData, attachments: allFiles });
    setSelectedFileNames([...selectedFileNames, ...fileNames]);
  };

  const removeFile = (fileNameToRemove) => {
    const updatedFiles = ticketData.attachments.filter(
      (file) => file.name !== fileNameToRemove
    );
    setTicketData({ ...ticketData, attachments: updatedFiles });
    const updatedFileNames = selectedFileNames.filter(
      (fileName) => fileName !== fileNameToRemove
    );
    setSelectedFileNames(updatedFileNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (ticketData.title === "") {
      setIsLoading(false);
      setErrorMessage("Title is required.");
      return;
    }

    if (ticketData.description.getCurrentContent().getPlainText() === "") {
      setIsLoading(false);
      setErrorMessage("Description is required.");
      return;
    }

    if (ticketData.assignedTo.length === 0) {
      setIsLoading(false);
      setErrorMessage("Assign To is required.");
      return;
    }

    const dataToSend = {
      title: ticketData.title,
      description: draftToHtml(
        convertToRaw(ticketData.description.getCurrentContent())
      ),
      type:
        ticketData.type?.value?._id ||
        categoryOptions.find((category) => category.title === "Other")?._id ||
        null,
      priority:
        ticketData.priority?.value?._id ||
        priorityOptions.find((priority) => priority.title === "Low")?._id ||
        null,
      status:
        ticketData.status?.value?._id ||
        statusOptions.find((status) => status.title === "Open")?._id ||
        null,
      assignedTo: ticketData.assignedTo?.map((user) => user.value._id) || [],
      group: ticketData.group?.map((group) => group.value._id) || [],
      gonnaHandleAt: ticketData.gonnaHandleAt || "",
      property: ticketData.property?.value?._id || null,
      reservationId: ticketData.reservationId?.value?._id || null,
      customerFirstName: ticketData.customerFirstName || null,
      customerLastName: ticketData.customerLastName || null,
      checkInDate: ticketData.checkInDate || "",
      checkOutDate: ticketData.checkOutDate || "",
      dueDate: ticketData.dueDate || "",
      attachments: ticketData.attachments || [],
      userId: user.userId,
    };

    try {
      const formData = new FormData();
      formData.append("title", dataToSend.title);
      formData.append("description", dataToSend.description);
      formData.append("type", dataToSend.type);
      formData.append("priority", dataToSend.priority);
      formData.append("status", dataToSend.status);
      formData.append("groupAssignedTo", JSON.stringify(dataToSend.group));
      formData.append("assignedTo", JSON.stringify(dataToSend.assignedTo));
      formData.append("property", dataToSend.property);
      formData.append("reservationId", dataToSend.reservationId);
      formData.append("customerFirstName", dataToSend.customerFirstName);
      formData.append("customerLastName", dataToSend.customerLastName);
      formData.append("checkInDate", dataToSend.checkInDate);
      formData.append("checkOutDate", dataToSend.checkOutDate);
      formData.append("dueDate", dataToSend.dueDate);
      formData.append("gonnaHandleAt", dataToSend.gonnaHandleAt);
      formData.append("userId", dataToSend.userId);
      for (let i = 0; i < dataToSend.attachments.length; i++) {
        formData.append("attachments", dataToSend.attachments[i]);
      }

      const response = await axios.post(
        `${apiUrl}/ticket/createTicket`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      setIsLoading(false);
      setSuccessMessage("Ticket created successfully!");
      setTimeout(() => {
        closeWindow();
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        error.response?.data?.message || "Failed to create ticket."
      );
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchProperties();
    if (props.reservation) {
      fetchReservation();
    } else {
      fetchReservationIds();
    }
    fetchStatusOptions();
    fetchPriorityOptions();
    fetchCategoryOptions();
  }, []);

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <form className="ticket-form" onSubmit={handleSubmit}>
          {/* Title */}
          <div className="form-group">
            <div className="form-field">
              <label className="form-field-label" htmlFor="title">
                Title*:
              </label>
              <input
                className="form-field-input"
                type="text"
                id="title"
                name="title"
                value={ticketData.title}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          {/* Description */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="description" className="form-field-label">
                Description*:
              </label>
              <DraftEditor
                editorState={ticketData.description}
                setEditorState={handleDescriptionChange}
              />
            </div>
          </div>

          {/* ReservationID || Type */}
          <div className="form-group">
            <div className="form-field">
              <Autocomplete
                disablePortal
                id="reservationId"
                name="reservationId"
                filterOptions={createFilterOptions({
                  limit: 25,
                })}
                disabled={props.reservation ? true : false}
                options={
                  reservationIds &&
                  reservationIds.map((reservation) => ({
                    value: reservation,
                    label: reservation.reservationId,
                    tooltip: `${formatDate(reservation.checkIn)} - ${formatDate(
                      reservation.checkOut
                    )} | ${reservation.property} | ${reservation.firstName} ${
                      reservation.lastName
                    }`,
                    tooltipPlacement: "right",
                  }))
                }
                value={ticketData.reservationId}
                onChange={(event, newValue) => {
                  if (!newValue) {
                    setTicketData({
                      ...ticketData,
                      reservationId: "",
                      customerFirstName: "",
                      customerLastName: "",
                      checkInDate: "",
                      checkOutDate: "",
                      property: "",
                    });
                    return;
                  }
                  setTicketData({
                    ...ticketData,
                    reservationId: {
                      value: newValue.value,
                      label: newValue.label,
                    },
                    customerFirstName: newValue.value.firstName,
                    customerLastName: newValue.value.lastName,
                    checkInDate: formatDateData(newValue.value.checkIn),
                    checkOutDate: formatDateData(newValue.value.checkOut),
                    property: {
                      value: properties.find((property) =>
                        localStorage.getItem("legacy") === "true"
                          ? property.name === newValue.value.property
                          : property._id === newValue.value.property
                      ),
                      label:
                        localStorage.getItem("legacy") === "true"
                          ? newValue.value.property
                          : properties.find(
                              (property) =>
                                property._id === newValue.value.property
                            ).name,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Reservation ID" />
                )}
              />
            </div>
            <div className="form-field">
              <label htmlFor="type" className="form-field-label">
                Type:
              </label>

              <Select
                id="type"
                name="type"
                value={ticketData.type}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, type: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  categoryOptions &&
                  categoryOptions.map((category) => ({
                    value: category,
                    label: category.title,
                  }))
                }
                isClearable
              />
            </div>
          </div>

          {/* Ticket Due Date || Gonna Handle At */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="dueDate" className="form-field-label">
                Ticket Due Date:
              </label>
              <input
                id="dueDate"
                name="dueDate"
                type="date"
                min={formatDateData(new Date())}
                className="form-field-input"
                value={ticketData.dueDate}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-field">
              <label htmlFor="gonnaHandleAt" className="form-field-label">
                Gonna Handle At:
              </label>
              <input
                id="gonnaHandleAt"
                name="gonnaHandleAt"
                type="date"
                min={formatDateData(new Date())}
                className="form-field-input"
                value={ticketData.gonnaHandleAt}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Group || Assign To */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="group" className="form-field-label">
                Group:
              </label>

              <Select
                id="group"
                name="group"
                value={ticketData.group}
                onChange={handleGroupChange}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  groups &&
                  groups.map((group) => ({
                    value: group,
                    label: group.name,
                  }))
                }
                isMulti
                isClearable
              />
            </div>
            <div className="form-field">
              <label htmlFor="assignTo" className="form-field-label">
                Assign To*:
              </label>

              <Select
                required
                id="assignTo"
                name="assignTo"
                value={ticketData.assignedTo}
                onChange={(selectedOption) => {
                  setUsersOptions(
                    users
                      .filter(
                        (user) =>
                          !selectedOption
                            .map((option) => option.value._id)
                            .includes(user._id)
                      )
                      .map((user) => ({
                        value: user,
                        label: user.username,
                        tooltip: user.name,
                      }))
                  );
                  setTicketData({
                    ...ticketData,
                    assignedTo: selectedOption,
                  });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={usersOptions}
                isMulti
                isClearable
                components={{ Option: CustomOption }}
              />
            </div>
          </div>

          {/* Importance || Status*/}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="priority" className="form-field-label">
                Importance:
              </label>

              <Select
                id="priority"
                name="priority"
                value={ticketData.priority}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, priority: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  priorityOptions &&
                  priorityOptions.map((priority) => ({
                    value: priority,
                    label: priority.title,
                  }))
                }
                isClearable
              />
            </div>

            <div className="form-field">
              <label htmlFor="status" className="form-field-label">
                Status:
              </label>

              <Select
                id="status"
                name="status"
                value={ticketData.status}
                onChange={(selectedOption) =>
                  setTicketData({ ...ticketData, status: selectedOption })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  statusOptions &&
                  statusOptions.map((status) => ({
                    value: status,
                    label: status.title,
                  }))
                }
                isClearable
              />
            </div>
          </div>

          {/* Property || Customer First Name || Customer Last Name */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="property" className="form-field-label">
                Property:
              </label>
              <Select
                id="property"
                name="property"
                value={ticketData.property}
                onChange={(selectedOption) => {
                  setTicketData({ ...ticketData, property: selectedOption });
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: "var(--secondary-color)",
                    primary25: "var(--tertiary-color)",
                  },
                })}
                options={
                  properties &&
                  properties.map((property) => ({
                    value: property,
                    label: property.name,
                  }))
                }
                isDisabled={props.reservation ? true : false}
              />
            </div>

            <div className="form-field">
              <label htmlFor="customerFirstName" className="form-field-label">
                Customer First Name:
              </label>
              <input
                id="customerFirstName"
                name="customerFirstName"
                type="text"
                className="form-field-input"
                value={ticketData.customerFirstName}
                onChange={handleInputChange}
                disabled={props.reservation ? true : false}
              />
            </div>

            <div className="form-field">
              <label htmlFor="customerLastName" className="form-field-label">
                Customer Last Name:
              </label>
              <input
                id="customerLastName"
                name="customerLastName"
                type="text"
                className="form-field-input"
                value={ticketData.customerLastName}
                onChange={handleInputChange}
                disabled={props.reservation ? true : false}
              />
            </div>
          </div>

          {/* Check In Date || Check Out Date */}
          <div className="form-group">
            <div className="form-field">
              <label htmlFor="checkInDate" className="form-field-label">
                Check In Date:
              </label>
              <input
                id="checkInDate"
                name="checkInDate"
                type="date"
                className="form-field-input"
                value={ticketData.checkInDate}
                onChange={handleInputChange}
                disabled={props.reservation ? true : false}
              />
            </div>

            <div className="form-field">
              <label htmlFor="checkOutDate" className="form-field-label">
                Check Out Date:
              </label>
              <input
                id="checkOutDate"
                name="checkOutDate"
                type="date"
                className="form-field-input"
                value={ticketData.checkOutDate}
                onChange={handleInputChange}
                disabled={props.reservation ? true : false}
              />
            </div>
          </div>

          {/* Attachments  */}
          <div
            className="form-group"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            <Tooltip
              title="Upload Files"
              PopperProps={{
                sx: {
                  "& .MuiTooltip-tooltip": {
                    fontSize: "15px",
                  },
                },
                style: { zIndex: 10001, fontSize: "16px" },
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -10],
                    },
                  },
                ],
              }}
              arrow
              placement="bottom"
            >
              <div className="form-field">
                <label
                  htmlFor="attachments"
                  className="form-field-label custom-file-upload"
                >
                  Attachments:
                  <input
                    type="file"
                    className="form-field-input"
                    id="attachments"
                    name="attachments"
                    multiple
                    onChange={handleFileChange}
                  />
                  <FileUpload
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "40px",
                      cursor: "pointer",
                      alignSelf: "center",
                      pt: "10px",
                      pb: "10px",
                      "&:hover": {
                        color: "var(--secondary-color)",
                      },
                      transition: "color 0.3s",
                    }}
                  />
                </label>
              </div>
            </Tooltip>
            {selectedFileNames.length > 0 && (
              <div className="file-list">
                <p>Selected Files:</p>
                <ul>
                  {selectedFileNames.map((fileName, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {fileName}
                      <Tooltip
                        title="Remove File"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "15px",
                            },
                          },
                          style: { zIndex: 10001, fontSize: "16px" },
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                        }}
                        arrow
                        placement="right"
                      >
                        <Delete
                          sx={{
                            color: "var(--primary-color)",
                            fontSize: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              color: "var(--secondary-color)",
                            },
                            transition: "color 0.3s",
                            alignSelf: "center",
                            textAlign: "center",
                            padding: "10px",
                          }}
                          onClick={() => removeFile(fileName)}
                        />
                      </Tooltip>
                    </li>
                  ))}
                </ul>
                <p>Total Files: {selectedFileNames.length}</p>
              </div>
            )}
          </div>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "10px" }}>
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert severity="success" sx={{ marginBottom: "10px" }}>
              {successMessage}
            </Alert>
          )}

          {/* Submit Button */}

          <div className="form-group">
            <div className="form-field">
              <button
                type="submit"
                className="add-ticket-button__button"
                style={{
                  backgroundColor: props.color || "var(--primary-color)",
                  color: props.textColor || "var(--primary-text-color)",
                  fontSize: props.fontSize || "15px",
                  fontWeight: props.fontWeight || "600",
                  width: props.width || "100%",
                }}
              >
                Create Ticket
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddTicketForm;
