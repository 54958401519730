import React, { useState, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { Logout, Menu } from "@mui/icons-material";
import LoginCounter from "../loginCounter/LoginCounter";
import NotificationsPanel from "../notificationsPanel/NotificationsPanel";
import MobileSidebar from "../mobileSidebar/MobileSidebar";
import LegacySwitcher from "../legacySwitcher/LegacySwitcher";
import { Avatar, Box } from "@mui/material";

import "./Header.css";

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const AssignedToCell = ({ userName }) => {
  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      <Tooltip key={userName} title={userName}>
        <Avatar style={{ backgroundColor: getColor(userName) }}>
          {userName.charAt(0)}
        </Avatar>
      </Tooltip>
    </Box>
  );
};

const Header = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [legacy, setLegacy] = useState(
    localStorage.getItem("legacy") === "true"
  );
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const updateLegacyMode = () => {
      setLegacy(localStorage.getItem("legacy") === "true");
    };

    window.addEventListener("storage", updateLegacyMode);

    return () => {
      window.removeEventListener("storage", updateLegacyMode);
    };
  }, []);

  return (
    <div className="header">
      <div className="header__left">
        <Menu
          style={{
            cursor: "pointer",
            color: "var(--primary-color)",
            fontSize: "30px",
            display: window.innerWidth <= 768 ? "block" : "none",
          }}
          onClick={() => setDrawerOpen(!drawerOpen)}
        />
        {legacy && (
          <div
            className="header__left__legacy"
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2>Legacy Mode</h2>
          </div>
        )}
        <MobileSidebar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </div>
      <div className="header__center"></div>
      <div className="header__right">
        {user.permissions.canViewLegacy && (
          <div className="header-legacy-switcher">
            <h3>Legacy</h3>
            <LegacySwitcher />
          </div>
        )}
        <NotificationsPanel playAudio={props.playAudio} />

        {localStorage.getItem("legacy") === "false" && (
          <div className="header__right__loginCounter">
            <LoginCounter />
          </div>
        )}

        <div

          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            gap: "10px",
          }}
          onClick={() => {
            window.location.href = "/profile";
          }}
        >
          <AssignedToCell userName={user.name} />
        </div>
        <Tooltip title="Logout" placement="bottom">
          <Logout
            style={{
              cursor: "pointer",
              color: "var(--primary-color)",
              fontSize: "25px",
              marginLeft: "10px",
            }}
            onClick={() => {
              localStorage.removeItem("user");
              localStorage.removeItem("logout");
              localStorage.removeItem("legacy");

              window.location.href = "/";
            }}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Header;
