import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import {
  Auth,
  Dashboard,
  Properties,
  Reservations,
  TicketSettings,
  Tickets,
  Inbox,
  Groups,
  Users,
  ArrivalSettings,
  Arrivals,
  Profile,
  ArrivalsPagination,
} from "./pages";
import NotificationSound from "./sounds/notification-sound.mp3";

import "./App.css";

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#6A3104", // Replace with your primary color
    },
    secondary: {
      main: "#ac510c", // Replace with your secondary color
    },
    tertiary: {
      main: "#db864e", // Replace with your tertiary color
    },
  },
});
function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  return (
    <div className="App">
      <UserProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/tickets/assigned-to-me"
              element={
                <Tickets
                  key="assigned-to-me"
                  subtitle="Assigned to me"
                  assignedTo={JSON.parse(localStorage.getItem("user"))}
                  searchStatus={[
                    {
                      value: {
                        color: "#09fbf7",
                        title: "In Progress",
                        __v: 0,
                        _id: "660be69afebd962dd37c663d",
                      },
                      label: "In Progress",
                    },
                    {
                      value: {
                        color: "#11ff00",
                        title: "Open",
                        __v: 0,
                        _id: "65fdd8c6ab895f5bb3aa645b",
                      },
                      label: "Open",
                    },
                  ]}
                />
              }
            />
            <Route
              path="/tickets/archived"
              element={
                <Tickets
                  subtitle="Archived Tickets"
                  archived={true}
                  key="archived"
                />
              }
            />
            <Route
              path="/tickets/completed"
              element={
                <Tickets
                  subtitle="Completed Tickets"
                  searchStatus={[
                    {
                      value: {
                        color: "#1909fb",
                        title: "Completed",
                        _id: "65fdd8ccab895f5bb3aa645e",
                      },
                      label: "Completed",
                    },
                  ]}
                />
              }
            />
            <Route
              path="/tickets/on-progress"
              element={
                <Tickets
                  subtitle="In Progress Tickets"
                  key="on-progress"
                  searchStatus={[
                    {
                      value: {
                        color: "#09fbf7",
                        title: "In Progress",
                        __v: 0,
                        _id: "660be69afebd962dd37c663d",
                      },
                      label: "In Progress",
                    },
                  ]}
                />
              }
            />

            <Route
              path="/tickets/open"
              element={
                <Tickets
                  subtitle="Open Tickets"
                  key="open"
                  searchStatus={[
                    {
                      value: {
                        color: "#11ff00",
                        title: "Open",
                        __v: 0,
                        _id: "65fdd8c6ab895f5bb3aa645b",
                      },
                      label: "Open",
                    },
                  ]}
                />
              }
            />
            <Route
              path="/tickets/completed"
              element={
                <Tickets
                  subtitle="Completed Tickets"
                  key="completed"
                  searchStatus={[
                    {
                      value: {
                        color: "#1909fb",
                        title: "Completed",
                        _id: "65fdd8ccab895f5bb3aa645e",
                      },
                      label: "Completed",
                    },
                  ]}
                />
              }
            />
            <Route
              path="/tickets/all"
              element={<Tickets subtitle="All Tickets" key="all" />}
            />
            <Route
              path="/tickets/created-by-me"
              element={
                <Tickets
                  subtitle="Tickets Created by Me"
                  key="byme"
                  createdBy={true}
                />
              }
            />

            <Route path="/tickets/settings" element={<TicketSettings />} />

            <Route path="/reservations" element={<Reservations />} />
            <Route path="/reservations/all" element={<Reservations />} />

            <Route path="/inbox" element={<Inbox />} />
            <Route path="/inbox/all" element={<Inbox />} />
            <Route path="/inbox/:chatroomId" element={<Inbox />} />

            <Route path="/groups" element={<Groups />} />
            <Route path="/users" element={<Users />} />

            <Route path="/properties" element={<Properties />} />

            <Route path="/arrivals/settings" element={<ArrivalSettings />} />
            <Route
              path="/arrivals/assign"
              key={"assign"}
              element={<Arrivals subtitle="Assign Arrivals" />}
            />
            <Route
              path="/arrivals/upcoming"
              key={"upcoming"}
              element={
                <Arrivals subtitle="Upcoming Arrivals" canAssign={false} />
              }
            />

            <Route
              path="/arrivals/all"
              key={"all"}
              element={<ArrivalsPagination />}
            />

            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
