import React, { useState, useEffect } from "react";
import {
  TextField,
  Alert,
  Checkbox,
  FormControl,
  FormLabel,
  FormControlLabel,
  MenuItem,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import axios from "axios";
import { Update } from "@mui/icons-material";
import apiUrl from "../api";

const UpdateArrivalOption = () => {
  const [arrivalName, setArrivalName] = useState("");
  const [arrivalGroup, setArrivalGroup] = useState("");
  const [isGroup, setIsGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchGroups();
  }, []);

  useEffect(() => {
    fetchArrivalOptions();
    setIsLoading(false);
  }, [groups]);

  const fetchGroups = async () => {
    try {
      const response = await axios.get(`${apiUrl}/group/getGroupsNames`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchArrivalOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/arrival-options/all`,

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const fetchedOptions = response.data.arrivalOptions.map((option) => ({
        value: option,
        label: option.is_group ? option.group.name : option.name,
      }));

      setOptions(fetchedOptions);
    } catch (error) {
      console.error("Failed to fetch arrival options: ", error);
    }
  };

  const handleArrivalSelect = (option) => {
    setSelectedOption(option);
    if (option.value.is_group) {
      setIsGroup(true);
      setArrivalName("");
      setArrivalGroup({
        value: option.value.group,
        label: option.label,
      });
    } else {
      setIsGroup(false);
      setArrivalName(option.label);
      setArrivalGroup("");
    }
  };

  const handleUpdateArrival = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (arrivalName === "" && !isGroup) {
      return setError("Arrival name is required.");
    }

    if (arrivalGroup === "" && isGroup) {
      return setError("Arrival group is required.");
    }

    try {
      await axios.put(
        `${apiUrl}/arrival-options/update/${selectedOption.value._id}`,
        {
          name: !isGroup ? arrivalName : "",
          group: isGroup ? arrivalGroup.value : null,
          is_group: isGroup,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setOptions(
        options.map((option) =>
          option.value === selectedOption.value
            ? {
                ...option,
                label: isGroup
                  ? groups.find((group) => group.id === arrivalGroup).name
                  : arrivalName,
                isGroup: isGroup,
                group: arrivalGroup,
                name: arrivalName,
              }
            : option
        )
      );

      setSuccess("Arrival updated successfully.");
    } catch (error) {
      setError("Failed to update arrival. Please try again.");
    }
  };

  return (
    <div>
      <h1>Update Arrival Option</h1>
      {isLoading && <p>Loading...</p>}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <Select
        options={options}
        value={selectedOption}
        onChange={handleArrivalSelect}
      />
      {selectedOption && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <form
            onSubmit={handleUpdateArrival}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <TextField
              variant="outlined"
              label="Arrival Name"
              placeholder="Category Name"
              value={arrivalName}
              onChange={(e) => setArrivalName(e.target.value)}
              margin="normal"
              disabled={isGroup}
              InputProps={{
                inputProps: { style: { height: "56px", padding: "5px" } }, // Adjust the style as needed
              }}
            />
            <FormControl>
              <FormLabel>Arrival Group</FormLabel>
              <Select
                options={groups.map((group) => ({
                  value: group._id,
                  label: group.name,
                }))}
                value={arrivalGroup}
                onChange={(selectedOption) => {
                  setArrivalGroup({
                    value: selectedOption.value,
                    label: selectedOption.label,
                  });
                }}
                isDisabled={!isGroup}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGroup}
                  onChange={(e) => setIsGroup(e.target.checked)}
                />
              }
              label="Is Group"
            />
            <button
              className="add-ticket-button__button"
              sx={{
                backgroundColor: "var(--primary-color)",
                color: "var(--primary-text-color)",
                fontSize: "15px",
                fontWeight: "600",
                width: "auto",
                padding: "10px 20px",
              }}
              onClick={() => {}}
            >
              <Update />
              <span>Update</span>
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateArrivalOption;
