import React from "react";
import { useUser } from "../../contexts/UserContext";

import {
  AddArrivalOption,
  ArrivalTablePagination,
  UpdateArrivalOption,
} from "../../components";
import { PageStructure } from "../../pages";

const ArrivalsPagination = (props) => {
  return (
    <PageStructure>
      <h1>Arrivals</h1>
      <h2>{props.subtitle}</h2>
      <hr />

      <ArrivalTablePagination />
    </PageStructure>
  );
};

export default ArrivalsPagination;
