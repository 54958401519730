import React, { useState, useEffect, useCallback } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";
import * as XLSX from "xlsx";
import "./AddReservationButton.css";
import apiUrl from "../api";

const AddReservationButton = (props) => {
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const [duplicateProperties, setDuplicateProperties] = useState([]);
  const [newProperties, setNewProperties] = useState([]);
  const [newPropertiesReservations, setNewPropertiesReservations] = useState(
    []
  );
  const [duplicatePropertiesReservations, setDuplicatePropertiesReservations] =
    useState([]);

  const [showPopUp, setShowPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const BATCH_SIZE = 100;

  const mapReservation = (reservation) => ({
    reservationId: reservation["Reservation ID"],
    property: reservation["Property"],
    checkIn: reservation["Checkin"],
    checkOut: reservation["Checkout"],
    nights: reservation["Nights"],
    adults: reservation["Adults"],
    kids: reservation["Kids"] || 0,
    cribs: reservation["Cribs"] || 0,
    highChairs: reservation["Highchairs"] || 0,
    idPassport: reservation["ID/Passport"] || "",
    guestTotal: reservation["Guest Total"] || 0,
    channel: reservation["Channel"] || "",
    channelAmount: reservation["Channel Amount"] || 0,
    firstName: reservation["First Name"],
    lastName: reservation["Last Name"],
    email: reservation["Email"] || "",
    phone: reservation["Phone"] || "",
    staffNotes: reservation["Staff Notes"] || "",
    customerNotes: reservation["Customer Notes"] || "",
    arrivalTime: reservation["Arrival Time"] || "",
    arrivalLocation: reservation["Arrival Location"] || "",
    arrivalFlight: reservation["Arrival Flight"] || "",
    departureTime: reservation["Departure Time"] || "",
    departureLocation: reservation["Departure Location"] || "",
    departureFlight: reservation["Departure Flight"] || "",
    status: reservation["Status"] || "",
    loggia_id: "",
  });

  const checkProperties = async (properties, callback = () => {}) => {
    try {
      const response = await axios.post(
        `${apiUrl}/property/checkProperties`,
        { properties },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );

      const duplicateProperties = response.data.duplicateProperties || [];
      const newProperties = response.data.notFoundProperties || [];

      callback(duplicateProperties, newProperties);

      return {
        duplicateProperties,
        newProperties,
      };
    } catch (error) {
      console.error("Error checking properties:", error);
      callback([], []); // Call the callback with empty arrays in case of error
      return { duplicateProperties: [], newProperties: [] };
    }
  };

  const filterUniqueReservations = (reservations) => {
    return reservations.filter(
      (reservation, index, self) =>
        index ===
        self.findIndex((r) => r.reservationId === reservation.reservationId)
    );
  };

  const uploadReservation = async (
    reservation,
    duplicateProperties,
    newProperties
  ) => {
    try {
      await axios.post(
        `${apiUrl}/reservation/createReservation`,
        {
          dontCreate:
            duplicateProperties.includes(
              reservation.property
                .trim()
                .replace(/\([^()]*\)/g, "")
                .trim()
            ) ||
            newProperties.includes(
              reservation.property
                .trim()
                .replace(/\([^()]*\)/g, "")
                .trim()
            ),
          reservationId: reservation.reservationId,
          property: reservation.property,
          checkIn: reservation.checkIn
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          checkOut: reservation.checkOut
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          nights: reservation.nights,
          adults: reservation.adults,
          kids: reservation.kids,
          cribs: reservation.cribs,
          highChairs: reservation.highChairs,
          idPassport: reservation.idPassport,
          guestTotal: reservation.guestTotal,
          channel: reservation.channel,
          channelAmount: reservation.channelAmount,
          firstName: reservation.firstName,
          lastName: reservation.lastName,
          email: reservation.email,
          phone: reservation.phone,
          staffNotes: reservation.staffNotes,
          customerNotes: reservation.customerNotes,
          arrivalTime: reservation.arrivalTime,
          arrivalLocation: reservation.arrivalLocation,
          arrivalFlight: reservation.arrivalFlight,
          departureTime: reservation.departureTime,
          departureLocation: reservation.departureLocation,
          departureFlight: reservation.departureFlight,
          status: reservation.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const uploadReservationsBatch = async (
    reservations,
    duplicateProperties,
    newProperties
  ) => {
    try {
      const batchSize = BATCH_SIZE;
      for (let i = 0; i < reservations.length; i += batchSize) {
        const batch = reservations.slice(i, i + batchSize);
        const formattedBatch = batch.map((reservation) => ({
          dontCreate:
            duplicateProperties.includes(
              reservation.property
                .trim()
                .replace(/\([^()]*\)/g, "")
                .trim()
            ) ||
            newProperties.includes(
              reservation.property
                .trim()
                .replace(/\([^()]*\)/g, "")
                .trim()
            ),
          reservationId: reservation.reservationId,
          property: reservation.property,
          checkIn: reservation.checkIn
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          checkOut: reservation.checkOut
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          nights: reservation.nights,
          adults: reservation.adults,
          kids: reservation.kids,
          cribs: reservation.cribs,
          highChairs: reservation.highChairs,
          idPassport: reservation.idPassport,
          guestTotal: reservation.guestTotal,
          channel: reservation.channel,
          channelAmount: reservation.channelAmount,
          firstName: reservation.firstName,
          lastName: reservation.lastName,
          email: reservation.email,
          phone: reservation.phone,
          staffNotes: reservation.staffNotes,
          customerNotes: reservation.customerNotes,
          arrivalTime: reservation.arrivalTime,
          arrivalLocation: reservation.arrivalLocation,
          arrivalFlight: reservation.arrivalFlight,
          departureTime: reservation.departureTime,
          departureLocation: reservation.departureLocation,
          departureFlight: reservation.departureFlight,
          status: reservation.status,
        }));

        await axios.put(
          `${apiUrl}/reservation/createReservationsBatch`, // Update the backend endpoint
          { reservations: formattedBatch },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        // Check if the reservation is a duplicate or new property
        batch.forEach((reservation) => {
          const property = reservation.property
            .trim()
            .replace(/\([^()]*\)/g, "")
            .trim();

          if (duplicateProperties.includes(property)) {
            console.log("Duplicate property:", reservation);
            props.setDuplicatePropertyReservations &&
              props.setDuplicatePropertyReservations(
                (prev) => filterUniqueReservations([...prev, reservation])
              );
          } else if (newProperties.includes(property)) {
            props.setNewPropertyReservations &&
              props.setNewPropertyReservations(
                (prev) => filterUniqueReservations([...prev, reservation])
              );
          }
        });
      }
    } catch (error) {
      console.error(error);
      // Handle errors appropriately (e.g., show an error message to the user)
    }
  };
  // !! This is the function for single reservation upload, OLD
  // const handleFiles = async (files) => {
  //   let totalReservations = 0;
  //   let processedReservations = 0;

  //   props.setIsLoading && props.setIsLoading(true);
  //   setIsLoading(true);

  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     const data = await file.arrayBuffer();
  //     const workbook = XLSX.read(data, { type: "buffer" });
  //     const worksheetName = workbook.SheetNames[0];
  //     const worksheet = workbook.Sheets[worksheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(worksheet);

  //     totalReservations += jsonData.length;

  //     const uniqueProperties = [
  //       ...new Set(jsonData.map((reservation) => reservation["Property"])),
  //     ];

  //     await checkProperties(
  //       uniqueProperties,
  //       // eslint-disable-next-line no-loop-func
  //       async (duplicateProps, newProps) => {
  //         let tempDuplicateReservations = [];
  //         let tempNewReservations = [];

  //         for (let j = 0; j < jsonData.length; j += BATCH_SIZE) {
  //           const batch = jsonData.slice(j, j + BATCH_SIZE);
  //           const reservations = batch.map((reservation) =>
  //             mapReservation(reservation)
  //           );

  //           await Promise.all(
  //             // eslint-disable-next-line no-loop-func

  //           );

  // batch.forEach((reservation) => {
  //   const property = reservation["Property"]
  //     .replace(/\([^()]*\)/g, "")
  //     .trim();
  //   if (duplicateProps.includes(property)) {
  //     tempDuplicateReservations.push(mapReservation(reservation));
  //     props.setDuplicatePropertyReservations &&
  //       props.setDuplicatePropertyReservations((prev) => [
  //         ...prev,
  //         mapReservation(reservation),
  //       ]);
  //   } else if (newProps.includes(property)) {
  //     tempNewReservations.push(mapReservation(reservation));
  //     props.setNewPropertyReservations &&
  //       props.setNewPropertyReservations((prev) => [
  //         ...prev,
  //         mapReservation(reservation),
  //       ]);
  //   }
  // });
  //         }
  //       }
  //     );
  //   }

  //   // Stop loading when all reservations are processed
  //   const intervalId = setInterval(() => {
  //     if (processedReservations >= totalReservations) {
  //       props.setIsLoading && props.setIsLoading(false);
  //       setIsLoading(false);
  //       clearInterval(intervalId);
  //     }
  //   }, 100); // Check every 100ms
  // };

  // !! This is the function for batch reservation upload, NEW

  const handleFiles = async (files) => {
    let totalReservations = 0;
    let processedReservations = 0;
    const tempNewReservations = [];
    const tempDuplicateReservations = [];
    props.setIsLoading && props.setIsLoading(true);
    setIsLoading(true);

    // Calculate total number of reservations across all files
    for (const file of files) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "buffer" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      totalReservations += jsonData.length;
    }

    // Now process each file one by one
    for (const file of files) {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: "buffer" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const uniqueProperties = [
        ...new Set(jsonData.map((reservation) => reservation["Property"])),
      ];

      const { duplicateProperties, newProperties } = await checkProperties(
        uniqueProperties
      );

      for (let i = 0; i < jsonData.length; i += BATCH_SIZE) {
        const batch = jsonData.slice(i, i + BATCH_SIZE);
        const reservations = batch.map(mapReservation);

        await uploadReservationsBatch(
          reservations,
          duplicateProperties,
          newProperties
        );

        // Update the total processed reservations across all files
        processedReservations += batch.length;

        // Calculate the overall progress percentage based on all files
        const progressPercentage =
          (processedReservations / totalReservations) * 100;

        props.setProgress && props.setProgress(progressPercentage);
      }
    }

    // Set the new and duplicate reservations at the end
    setNewPropertiesReservations(tempNewReservations);
    setDuplicatePropertiesReservations(tempDuplicateReservations);

    // Stop loading when all reservations are processed
    props.setIsLoading && props.setIsLoading(false);
    setIsLoading(false);
  };

  const handleClick = () => {
    props.setIsLoading && props.setIsLoading(true);
    props.setProgress && props.setProgress(0);
    setIsLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    input.multiple = true; // Allow multiple file selection
    input.onchange = (e) => handleFiles(e.target.files);
    input.click();
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      newPropertiesReservations.length > 0 ||
      duplicatePropertiesReservations.length > 0
    ) {
      setShowPopUp(true);
      props.setNewPropertyReservations &&
        props.setNewPropertyReservations(newPropertiesReservations);
      props.setDuplicatePropertyReservations &&
        props.setDuplicatePropertyReservations(duplicatePropertiesReservations);
    }
  }, [newPropertiesReservations, duplicatePropertiesReservations]);

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: user.permissions.canAddReservation ? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
            marginLeft: props.marginLeft || "0px",
            marginRight: props.marginRight || "0px",
          }}
          onClick={handleClick}
        >
          <Upload />
          <span>Import Reservations</span>
        </button>
      </div>
    </>
  );
};

export default AddReservationButton;
