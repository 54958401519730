import React, { useState } from "react";
import { TextField, Alert } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import apiUrl from "../api";


import axios from "axios";

const AddStatusOption = () => {
  const [statusName, setStatusName] = useState("");
  const [statusColor, setStatusColor] = useState("#FA0808");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const handleAddStatus = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (statusName === "") {
      return setError("Status name is required.");
    }

    // Add your API call here to add the status
    try {
      const response = await axios.post(
      
        `${apiUrl}/ticket-options/createStatusOption`,
        {
          title: statusName,
          color: statusColor,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Status added successfully.");
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Status already exists.");
      } else {
        setError("Failed to add status. Please try again.");
      }
    }

    setStatusName("");
    setStatusColor("#FA0808"); // Reset color to default after adding the status
  };

  return (
    <div className="add-status-option-wrapper">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <h4
        style={{
          marginBottom: "0px",
          fontWeight: "600",
        }}
      >
        Add Status
      </h4>
      <form
        onSubmit={handleAddStatus}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          width: "100%",
          alignItems: "center",
        }}
      >
        <TextField
          variant="outlined"
          label="Status Name"
          placeholder="Status Name"
          value={statusName}
          onChange={(e) => setStatusName(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            inputProps: { style: { height: "56px", padding: "5px" } }, // Adjust the style as needed
          }}
        />
        <TextField
          variant="outlined"
          type="color"
          label="Label Color"
          value={statusColor}
          onChange={(e) => setStatusColor(e.target.value)}
          margin="normal"
          sx={{
            width: "20%",
          }}
          // Custom styling may be required for color input to look consistent
          InputProps={{
            inputProps: { style: { height: "56px", padding: "5px" } }, // Adjust the style as needed
          }}
        />
        <button
          className="add-ticket-button__button"
          sx={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: "auto",
            padding: "10px 20px",
          }}
          onClick={() => {}}
        >
          <AddIcon />
          <span>Add</span>
        </button>
      </form>
    </div>
  );
};

export default AddStatusOption;
