import React, { useState, useEffect } from "react";

import axios from "axios";
import {
  TextField,
  Alert,
  DialogActions,
  Button} from "@mui/material";
import Select from "react-select";
import apiUrl from "../api";




const AddGroupForm = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [chatroomName, setChatroomName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));



  useEffect(() => {
    const fetchUsers = async () => {
      try {

        const response = await axios.get( `${apiUrl}/auth/getUsers`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
  
        setUsers(response.data.users);
      } catch (error) {
        console.error("Failed to fetch users: ", error);
      }
    };
    fetchUsers();
  }, [user.token]);

  const handleCreateChatroom = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    if (chatroomName === "") {
      return setError("Group name is required.");
    }

    

    // if (selectedUsers.length < 1) {
    //   return setError("Please select at least one user.");
    // }

    setIsLoading(true);

    try {
      const response = await axios.post(

        `${apiUrl}/group/createGroup`,
        {
          name: chatroomName,
          users:  [ ...selectedUsers.map((user) => user.value)],
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Group created successfully.");
    } catch (error) {
      console.error("Failed to create chatroom: ", error);
      setError("Failed to create group. Please try again.");
    }

    setIsLoading(false);
  };

  return (
    <div className="create-chatroom-form">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
  
      <form onSubmit={handleCreateChatroom}>
        <TextField
          label="Group Name"
          variant="outlined"
          value={chatroomName}
          onChange={(e) => setChatroomName(e.target.value)}
          fullWidth
          margin="normal"
          
        />
  
        <Select
          options={users.map((user) => ({
            value: user._id,
            label: user.username,
          }))}
          isMulti
          placeholder="Select Users"
          onChange={(selectedUsers) => setSelectedUsers(selectedUsers)}
        />
  
        <DialogActions>
          <Button onClick={handleCreateChatroom}  disabled={isLoading}
          style={{
     
            backgroundColor:  "var(--primary-color)",
            color:  "var(--primary-text-color)",
            fontSize:  "15px",
            fontWeight: "500",
          }}

          >
            {isLoading ? 'Creating...' : 'Create Group'}
          </Button>
        </DialogActions>
      </form>
    </div>
  );
  

};

export default AddGroupForm;
