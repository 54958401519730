import React from "react";
import { useUser } from "../../contexts/UserContext";

import "./ArrivalSettings.css";
import { AddArrivalOption, UpdateArrivalOption } from "../../components";
import { PageStructure } from "../../pages";

const ArrivalSettings = () => {
  return (
    <PageStructure>
      <h1>Arrivals Settings</h1>

      <hr />
      <AddArrivalOption />
      <UpdateArrivalOption />
    </PageStructure>
  );
};

export default ArrivalSettings;
