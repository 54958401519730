import React, { useState, useEffect } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";

import * as XLSX from "xlsx";

import "./ImportPropertyLoggiaButton.css";
import apiUrl from "../api";


const ImportPropertyLoggiaButton = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const BATCH_SIZE = 100;
  const handleFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    setData(jsonData);


   
    props.setIsLoading && props.setIsLoading(true);
    // Batch upload logic
    for (let i = 0; i < jsonData.length; i += BATCH_SIZE) {
      const batch = jsonData.slice(i, i + BATCH_SIZE);
      await Promise.all(
        batch.map((property) =>
         uploadProperty (mapProperty(property))
        )
      );
    }

   

    props.setIsLoading && props.setIsLoading(false);
  };

  const mapProperty = (property) => ({
    title: property["Title"],
    loggia_id: property["Loggia Property ID"],

  });

  const uploadProperty = async (property) => {
    try {
      const response = await axios.post(
        `${apiUrl}/property/createPropertyLoggiaId`,
        
        {
          name: property.title,
          loggia_id: property.loggia_id.toString(),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

    } catch (error) {

    }
  };


  const handleClick = () => {
    props.setIsLoading && props.setIsLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    input.onchange = handleFile;
    input.click();
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: user.permissions.canAddReservation ? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
            marginLeft: props.marginLeft || "0px",
            marginRight: props.marginRight || "0px",
          }}
          onClick={handleClick}
        >
          <Upload />
          <span>Import Loggia</span>
        </button>
      </div>
    </>
  );
};
export default ImportPropertyLoggiaButton;








