import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import apiUrl from "../api";

import { ContentState, convertFromHTML } from "draft-js";

import DraftEditor from "../Editor/DraftEditor";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const CreateArrivalForm = (props) => {
  const [ticketData, setTicketData] = useState({
    notes: EditorState.createEmpty(), // Initialize the editor state
    type: "",
    group: [],
    assignedTo: "",
    arrivalOption: "",
    property: "",
    reservationId: "",
    customerFirstName: "",
    customerLastName: "",
    date: "",
    time: "",
    checkInDate: "",
    checkOutDate: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [users, setUsers] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [reservationIds, setReservationIds] = useState([]);
  const [arrivalOptions, setArrivalOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);

  const closeWindow = () => {
    props.CreateArrivalForm(false);
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const convertHtmlToEditorState = (html) => {
    const contentBlock = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
      contentBlock.entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/getUsers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
      setUsersOptions(
        response.data.users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))
      );
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };
  const fetchReservationIds = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/reservation/getReservationsIdsWithNotes`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setReservationIds(response.data.reservations);

      if (props.reservation) {
        const reservation = response.data.reservations.find(
          (reservation) =>
            reservation.reservationId === props.reservation ||
            reservation._id === props.reservation
        );
        if (reservation) {
          setTicketData({
            ...ticketData,
            reservationId: {
              value: reservation,
              label: reservation.reservationId,
            },

            customerFirstName: reservation.firstName,
            customerLastName: reservation.lastName,
            checkInDate: formatDateData(reservation.checkIn),
            checkOutDate: formatDateData(reservation.checkOut),
            property: {
              value: properties.find(
                (property) => 
                  localStorage.getItem("legacy") === "true"? property.name === reservation.property : property._id === reservation.property
                
              ),
              label: reservation.property,
            },
          });
        }
      }
    } catch (error) {
      console.error("Failed to fetch reservation IDs: ", error);
    }
  };

  const fetchReservation = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/reservation/getReservation/${props.reservation}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const reservation = response.data.reservation;
      if (reservation) {
        setTicketData({
          ...ticketData,
          reservationId: {
            value: reservation,
            label: reservation.reservationId,
          },
          customerFirstName: reservation.firstName,
          customerLastName: reservation.lastName,
          checkInDate: formatDateData(reservation.checkIn),
          checkOutDate: formatDateData(reservation.checkOut),
          property: {
            value: properties.find(
              (property) => 
                localStorage.getItem("legacy") === "true"? property.name === reservation.property :
                property._id === reservation.property
            ),
            label: reservation.property,
          },
        });
      }
    } catch (error) { 
      console.error("Failed to fetch reservation: ", error);
    }
  };
  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/property/getPropertiesNames`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleDescriptionChange = (editorState) => {
    setTicketData({ ...ticketData, notes: editorState });
  };

  const handleArrivalTimeUpdate = (arrivalTime) => {
    // Allow only numbers and add colon after the second digit
    let formattedTime = arrivalTime.replace(/[^0-9]/g, "");

    if (formattedTime.length >= 3) {
      formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(
        2,
        4
      )}`;
    }

    if (formattedTime.length > 5) {
      formattedTime = formattedTime.slice(0, 5);
    }

    // Check if the formatted time is a valid time string
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    const isValidTime = timeRegex.test(formattedTime);

    setTicketData({
      ...ticketData,
      time: formattedTime,
    });
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newFiles = Array.from(files);
    const fileNames = newFiles.map((file) => file.name);
    const allFiles = [...ticketData.attachments, ...newFiles];
    setTicketData({ ...ticketData, attachments: allFiles });
    setSelectedFileNames([...selectedFileNames, ...fileNames]);
  };

  const removeFile = (fileNameToRemove) => {
    const updatedFiles = ticketData.attachments.filter(
      (file) => file.name !== fileNameToRemove
    );
    setTicketData({ ...ticketData, attachments: updatedFiles });
    const updatedFileNames = selectedFileNames.filter(
      (fileName) => fileName !== fileNameToRemove
    );
    setSelectedFileNames(updatedFileNames);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (ticketData.title === "") {
      setIsLoading(false);
      setErrorMessage("Title is required.");
      return;
    }

    if (ticketData.notes.getCurrentContent().getPlainText() === "") {
      setIsLoading(false);
      setErrorMessage("Notes are required.");
      return;
    }

    if (ticketData.type === "") {
      setIsLoading(false);
      setErrorMessage("Type is required.");
      return;
    }

    if (ticketData.date === "") {
      setIsLoading(false);
      setErrorMessage("Date is required.");
      return;
    }

    if (ticketData.arrivalOption === "") {
      setIsLoading(false);
      setErrorMessage("Managed By is required.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/arrival/create`,
        {
          arrivalTime: ticketData.time,
          property: ticketData.property?.value?._id,
          reservation: ticketData.reservationId?.value?._id,
          assignedTo: ticketData.assignedTo
            ? ticketData.assignedTo.value._id
            : null,
          date: new Date(new Date(
            ticketData.date
          ).setHours(0, 0, 0, 0)
        ),
          notes: draftToHtml(
            convertToRaw(ticketData.notes.getCurrentContent())
          ),
          arrivalOption: ticketData.arrivalOption?.value.group._id,
          type: ticketData.type?.value,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );


      setIsLoading(false);
      setSuccessMessage("Ticket created successfully!");
      setTimeout(() => {
         window.location.reload();
        setSuccessMessage("");
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        error.response?.data?.message || "Failed to create ticket."
      );
      console.log(error);
    }
  };
  const handleSubmitMore = async (event) => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (ticketData.title === "") {
      setIsLoading(false);
      setErrorMessage("Title is required.");
      return;
    }

    if (ticketData.notes.getCurrentContent().getPlainText() === "") {
      setIsLoading(false);
      setErrorMessage("Notes are required.");
      return;
    }

    if (ticketData.type === "") {
      setIsLoading(false);
      setErrorMessage("Type is required.");
      return;
    }

    if (ticketData.date === "") {
      setIsLoading(false);
      setErrorMessage("Date is required.");
      return;
    }

    if (ticketData.arrivalOption === "") {
      setIsLoading(false);
      setErrorMessage("Managed By is required.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/arrival/create`,
        {
          arrivalTime: ticketData.time,
          property: ticketData.property?.value?._id,
          reservation: ticketData.reservationId?.value?._id,
          assignedTo: ticketData.assignedTo
            ? ticketData.assignedTo.value._id
            : null,
          date: new Date(new Date(
            ticketData.date
          ).setHours(0, 0, 0, 0)
        ),
          notes: draftToHtml(
            convertToRaw(ticketData.notes.getCurrentContent())
          ),
          arrivalOption: ticketData.arrivalOption?.value.group._id,
          type: ticketData.type?.value,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        },
        {
          arrivalTime: ticketData.time,
          property: ticketData.property?.value?._id,
          reservation: ticketData.reservationId?.value?._id,
          assignedTo: ticketData.assignedTo
            ? ticketData.assignedTo.value._id
            : null,
          date: new Date(new Date(
            ticketData.date
          ).setHours(0, 0, 0, 0)
        ),
          notes: draftToHtml(
            convertToRaw(ticketData.notes.getCurrentContent())
          ),
          arrivalOption: ticketData.arrivalOption?.value.group._id,
          type: ticketData.type?.value,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setIsLoading(false);
      setSuccessMessage("Ticket created successfully!");
      setTimeout(() => {
        setTicketData({
          ...ticketData,
          notes: EditorState.createEmpty(),
          type: "",
          group: [],
          assignedTo: [],
          arrivalOption: "",
          property: "",
          reservationId: "",
          customerFirstName: "",
          customerLastName: "",
          date: "",
          time: "",
          checkInDate: "",
          checkOutDate: "",
        });
        setSuccessMessage("");
      }, 1500);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(
        error.response?.data?.message || "Failed to create ticket."
      );
    }
  };

  useEffect(() => {
    fetchUsers();

    const fetchArrivalOptions = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/arrival-options/all`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const fetchedOptions = response.data.arrivalOptions.map((option) => ({
          value: option,
          label: option.is_group ? option.group.name : option.name,
        }));

        setArrivalOptions(fetchedOptions);
      } catch (error) {
        console.error("Failed to fetch arrival options: ", error);
      }
    };
    fetchArrivalOptions();
    if (props.reservation) {
      fetchReservation();
    } else {
      fetchReservationIds();
    }

    fetchProperties();
  }, []);

  return (
    <div className="ticket-form-wrapper">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <form className="ticket-form" onSubmit={handleSubmit}>
            {/* Title */}
            <div className="form-group">
              <div className="form-field">
                <Autocomplete
                  disablePortal
                  id="reservationId"
                  name="reservationId"
                  filterOptions={createFilterOptions({
                    limit: 25,
                  })}
                  disabled={props.reservation ? true : false}
                  options={
                    reservationIds &&
                    reservationIds.map((reservation) => ({
                      value: reservation,
                      label: reservation.reservationId,
                      tooltip: `${formatDate(
                        reservation.checkIn
                      )} - ${formatDate(reservation.checkOut)} | ${
                        reservation.property
                      } | ${reservation.firstName} ${reservation.lastName}`,
                      tooltipPlacement: "right",
                    }))
                  }
                  value={ticketData.reservationId}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      setTicketData({
                        ...ticketData,
                        reservationId: "",
                        customerFirstName: "",
                        customerLastName: "",
                        checkInDate: "",
                        checkOutDate: "",
                        property: "",
                      });
                      return;
                    }
                    setTicketData({
                      ...ticketData,
                      reservationId: {
                        value: newValue.value,
                        label: newValue.label,
                      },
                      time:
                        ticketData.type?.value === "Check-In"
                          ? newValue.value.arrivalTime
                          : newValue.value.departureTime,
                      customerFirstName: newValue.value.firstName,
                      customerLastName: newValue.value.lastName,
                      checkInDate: formatDateData(newValue.value.checkIn),
                      checkOutDate: formatDateData(newValue.value.checkOut),
                      property: {
                        value: properties.find(
                          (property) =>
                            localStorage.getItem("legacy") === "true"? property.name === newValue.value.property :
                            property._id === newValue.value.property
                        ),
                        label: newValue.value.property,
                      },
                      notes: convertHtmlToEditorState(
                        `
                        ${
                          newValue.value.staffNotes
                            ? `<h3>Staff Notes</h3><br/><p>${newValue.value.staffNotes}</p><br/>`
                            : ""
                        }
                        ${
                          newValue.value.customerNotes
                            ? `<h3>Customer Notes</h3><br/><p>${newValue.value.customerNotes}</p><br/>`
                            : ""
                        }
                        
                      `
                      ),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Reservation ID" />
                  )}
                />
              </div>
            </div>

            {/* Notes */}
            <div className="form-group">
              <div className="form-field">
                <label htmlFor="description" className="form-field-label">
                  Notes:
                </label>
                <DraftEditor
                  editorState={ticketData.notes}
                  setEditorState={handleDescriptionChange}
                />
              </div>
            </div>

            {/*  Type */}
            <div className="form-group">
              <div className="form-field">
                <label htmlFor="type" className="form-field-label">
                  Type:
                </label>

                <Select
                  id="type"
                  name="type"
                  value={ticketData.type}
                  onChange={(selectedOption) => {
                    setTicketData({
                      ...ticketData,
                      type: selectedOption,

                      time:
                        selectedOption.value === "Check-In"
                          ? ticketData.reservationId
                            ? ticketData.reservationId?.value.arrivalTime
                            : ""
                          : ticketData.reservationId
                          ? ticketData.reservationId?.value.departureTime
                          : "",
                      date:
                        selectedOption.value === "Check-In"
                          ? ticketData.reservationId
                            ? formatDateData(
                                ticketData.reservationId?.value.checkIn
                              )
                            : ""
                          : ticketData.reservationId
                          ? formatDateData(
                              ticketData.reservationId?.value.checkOut
                            )
                          : "",
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "var(--secondary-color)",
                      primary25: "var(--tertiary-color)",
                    },
                  })}
                  options={[
                    {
                      value: "Check-In",
                      label: "Check-In",
                    },
                    {
                      value: "Check-Out",
                      label: "Check-Out",
                    },
                  ]}
                  isClearable
                />
              </div>
              <div className="form-field">
                <label htmlFor="assignTo" className="form-field-label">
                  Assign To:
                </label>

                <Select
                  id="assignTo"
                  name="assignTo"
                  value={ticketData.assignedTo}
                  onChange={(selectedOption) => {
                    setUsersOptions(
                      users
                        .filter(
                          (user) =>
                            !selectedOption ||
                            selectedOption.value._id !== user._id
                        )
                        .map((user) => ({
                          value: user,
                          label: user.username,
                          tooltip: user.name,
                        }))
                    );
                    setTicketData({
                      ...ticketData,
                      assignedTo: selectedOption,
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "var(--secondary-color)",
                      primary25: "var(--tertiary-color)",
                    },
                  })}
                  options={usersOptions}
                  isClearable
                  components={{ Option: CustomOption }}
                />
              </div>
            </div>

            {/*  Date || Time */}
            <div className="form-group">
              <div className="form-field">
                <label htmlFor="date" className="form-field-label">
                  Date:
                </label>
                <input
                  id="date"
                  name="date"
                  type="date"
                  className="form-field-input"
                  value={ticketData.date}
                  onChange={handleInputChange}
                />
              </div>

              <div className="form-field">
                <label htmlFor="time" className="form-field-label">
                  Time
                </label>
                <input
                  id="time"
                  name="time"
                  type="string"
                  className="form-field-input"
                  value={ticketData.time}
                  onChange={(event) =>
                    handleArrivalTimeUpdate(event.target.value)
                  }
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-field">
                <label htmlFor="property" className="form-field-label">
                  Property:
                </label>
                <Select
                  id="property"
                  name="property"
                  value={ticketData.property}
                  onChange={(selectedOption) => {
                    setTicketData({ ...ticketData, property: selectedOption });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "var(--secondary-color)",
                      primary25: "var(--tertiary-color)",
                    },
                  })}
                  options={
                    properties &&
                    properties.map((property) => ({
                      value: property,
                      label: property.name,
                    }))
                  }
                  isDisabled={ticketData.reservationId ? true : false}
                />
              </div>

              <div className="form-field">
                <label htmlFor="arrivalOption" className="form-field-label">
                  Managed By:
                </label>
                <Select
                  id="arrivalOption"
                  name="arrivalOption"
                  value={ticketData.arrivalOption}
                  onChange={(selectedOption) => {
                    setTicketData({
                      ...ticketData,
                      arrivalOption: selectedOption,
                    });
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "var(--secondary-color)",
                      primary25: "var(--tertiary-color)",
                    },
                  })}
                  options={arrivalOptions}
                />
              </div>
            </div>

            {/*  Customer First Name || Customer Last Name */}
            <div className="form-group">
              <div className="form-field">
                <label htmlFor="customerFirstName" className="form-field-label">
                  Customer First Name:
                </label>
                <input
                  id="customerFirstName"
                  name="customerFirstName"
                  type="text"
                  className="form-field-input"
                  value={ticketData.customerFirstName}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </div>

              <div className="form-field">
                <label htmlFor="customerLastName" className="form-field-label">
                  Customer Last Name:
                </label>
                <input
                  id="customerLastName"
                  name="customerLastName"
                  type="text"
                  className="form-field-input"
                  value={ticketData.customerLastName}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </div>
            </div>

            {/* Check In Date || Check Out Date */}
            <div className="form-group">
              <div className="form-field">
                <label htmlFor="checkInDate" className="form-field-label">
                  Check In Date:
                </label>
                <input
                  id="checkInDate"
                  name="checkInDate"
                  type="date"
                  className="form-field-input"
                  value={ticketData.checkInDate}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </div>

              <div className="form-field">
                <label htmlFor="checkOutDate" className="form-field-label">
                  Check Out Date:
                </label>
                <input
                  id="checkOutDate"
                  name="checkOutDate"
                  type="date"
                  className="form-field-input"
                  value={ticketData.checkOutDate}
                  onChange={handleInputChange}
                  disabled={true}
                />
              </div>
            </div>

            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "10px" }}>
                {errorMessage}
              </Alert>
            )}

            {successMessage && (
              <Alert severity="success" sx={{ marginBottom: "10px" }}>
                {successMessage}
              </Alert>
            )}

            {/* Submit Button */}

            <div className="form-group">
              <div className="form-field">
                <button
                  type="submit"
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: props.color || "var(--primary-color)",
                    color: props.textColor || "var(--primary-text-color)",
                    fontSize: props.fontSize || "15px",
                    fontWeight: props.fontWeight || "600",
                    width: props.width || "100%",
                  }}
                >
                  Create One Arrival
                </button>
              </div>
            </div>
            <div className="form-field"></div>
          </form>
          <button
            onClick={() => {
              handleSubmitMore();
            }}
            className="add-ticket-button__button"
            style={{
              backgroundColor: props.color || "var(--primary-color)",
              color: props.textColor || "var(--primary-text-color)",
              fontSize: props.fontSize || "15px",
              fontWeight: props.fontWeight || "600",
              width: props.width || "100%",
            }}
          >
            Create Arrival and Add Another
          </button>
        </>
      )}
    </div>
  );
};

export default CreateArrivalForm;
