import React, { useState, useEffect, useRef } from "react";
import { Notifications } from "@mui/icons-material";
import { Badge } from "@material-ui/core";
import axios from "axios";
import io from "socket.io-client";
import { PopUpWindow, ViewTicketForm, Loader } from "../";
import apiUrl from "../api";

import "./NotificationsPanel.css";

const socket = io(`${apiUrl}/`);

const formatDateWithTime = (date) => {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const NotificationsPanel = (props) => {
  const [notifications, setNotifications] = useState([]); // Notifications state to store the notifications
  const [unreadCount, setUnreadCount] = useState(0); // Unread notifications count
  const [isHovered, setIsHovered] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]); // State to store the groups [users, admins, moderators, etc.
  const [properties, setProperties] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [lastKnownCount, setLastKnownCount] = useState(0); // State to track the last known notification count
  const [totalItems, setTotalItems] = useState(0); // State to store the total items [users, groups, etc.]
  const [currentPage, setCurrentPage] = useState(1); // State to store the current page number
  const [ticketIsOpen, setTicketIsOpen] = useState(false);
  const [openTicket, setOpenTicket] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));
  const iconRef = useRef();

  // Ref for the notifications panel

  const handleNotificationClick = async (notification) => {
    setIsLoading(true);
    setTicketIsOpen(true);
    setIsPanelOpen(false);
    const ticket = await fetchTicket(notification);
    if (ticket === undefined) {
      return;
    }

    setIsLoading(false);
    setOpenTicket(ticket);
  };

  const fetchTicket = async (ticketId) => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket/getTicket/${ticketId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      return response.data.ticket;
    } catch (error) {
      console.error("Failed to fetch ticket: ", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/getUsers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/group/getGroups`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setGroups(response.data.groups);
    } catch (error) {
      console.error("Failed to fetch groups: ", error);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket-options/getStatusOptions`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setStatusOptions(response.data.statusOptions);
    } catch (error) {
      console.error("Failed to fetch status options: ", error);
    }
  };

  const fetchPriorityOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket-options/getPriorityOptions`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setPriorityOptions(response.data.priorityOptions);
    } catch (error) {
      console.error("Failed to fetch priority options: ", error);
    }
  };

  const fetchCategoryOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/ticket-options/getCategoryOptions`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setCategoryOptions(response.data.categoryOptions);
    } catch (error) {
      console.error("Failed to fetch category options: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/property/getPropertiesNames`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };

  function flashTitle(pageTitle, newTitle) {
    let timeout;
    let pageTitleOriginal = pageTitle;
    let pageTitleNew = newTitle;
    let pageTitleIsOriginal = true;

    function changeTitle() {
      document.title = pageTitleIsOriginal ? pageTitleNew : pageTitleOriginal;
      pageTitleIsOriginal = !pageTitleIsOriginal;
      timeout = setTimeout(changeTitle, 1000);
    }

    changeTitle();

    return () => {
      clearTimeout(timeout);
      document.title = pageTitleOriginal;
    };
  }

  useEffect(() => {
    if (unreadCount === 0) {
      document.title = "Helpdesk";
    } else {
      return flashTitle(document.title, `(${unreadCount}) Helpdesk`);
    }
  }, [unreadCount]);

  useEffect(() => {
    const handleNewMention = (data) => {
      if (data.user._id === user._id && data.by !== user._id) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);
        props.playAudio();
      }
      // setNotifications((prevNotifications) => [...prevNotifications, data.]);
    };

    const handleNewTicketUpdate = (data) => {
      if (
        (data.ticket.createdBy === user._id ||
          data.ticket.assignedTo.includes(user._id)) &&
        data.by !== user._id
      ) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    const handleNewAssignment = (data) => {
      if (
        (data.user._id === user.userId || data.user._id === user?._id) &&
        data.by !== user.userId
      ) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    const handleNewUnassignment = (data) => {
      if (
        (data.user._id === user.userId || data.user._id === user?._id) &&
        data.by !== user.userId
      ) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    const handleNewMessage = (data) => {
      if (data.by !== user._id && data.chatroom.users.includes(user._id)) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    const handleNewChatroom = (data) => {
      if (data.by !== user._id) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    const handleNewGroupAdded = (data) => {
      if (
        data.by !== user._id &&
        data.users.includes(user._id) &&
        !(data.prevUsers.find((u) => u._id === user._id) != null)
      ) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    const handleNewGroupRemoved = (data) => {
      if (data.by !== user._id && data.usersRemoved.includes(user._id)) {
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setUnreadCount((prevCount) => prevCount + 1);
        setTotalItems((prevCount) => prevCount + 1);

        props.playAudio();
      }
    };

    socket.on("assignTicket", handleNewAssignment);
    socket.on("unassignTicket", handleNewUnassignment);
    socket.on("mentionComment", handleNewMention);
    socket.on("messageSentNotification", handleNewMessage);
    socket.on("chatroomCreatedNotification", handleNewChatroom);
    socket.on("groupAddedNotification", handleNewGroupAdded);
    socket.on("groupRemovedNotification", handleNewGroupRemoved);
    socket.on("ticketUpdatedNotification", handleNewTicketUpdate);

    return () => {
      socket.off("mentionComment");
      socket.off("assignTicket");
      socket.off("unassignTicket");
      socket.off("messageSentNotification");
      socket.off("chatroomCreatedNotification");
      socket.off("groupAddedNotification");
      socket.off("groupRemovedNotification");
      socket.off("ticketUpdatedNotification");
    };
  }, []);

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${apiUrl}/auth/getNotifications?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setNotifications([...notifications, ...response.data.notifications]);
      setLastKnownCount(unreadCount);
      setUnreadCount(0);
      setTotalItems(response.data.totalItems);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const fetchNotificationsUnreadCount = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/auth/getNotificationsUnread`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setUnreadCount(response.data.notificationsCount);
    } catch (err) {
      console.log(err);
    }
  };

  const putNotificationsRead = async () => {
    try {
      await axios.put(
        `${apiUrl}/auth/readNotifications`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const panelRef = useRef();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleNotificationsPanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  // Effect to add and cleanup the click listener
  useEffect(() => {
    fetchGroups();
    fetchNotificationsUnreadCount();
    fetchUsers();
    fetchProperties();
    fetchStatusOptions();
    fetchPriorityOptions();
    fetchCategoryOptions();
    setLastKnownCount(notifications.length); // Assuming this runs after notifications are fetched

    const handleClickOutside = (event) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target) &&
        !iconRef.current.contains(event.target)
      ) {
        setIsPanelOpen(false);
      }
    };

    // Add click listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the listener to prevent memory leaks
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs only on mount and unmount

  useEffect(() => {
    if (currentPage === 1) {
      return;
    }
    fetchNotifications();
  }, [currentPage]);
  return (
    <>
      {!isLoading && ticketIsOpen && (
        <PopUpWindow
          onClose={() => {
            setTicketIsOpen(false);
            setOpenTicket({});
          }}
          color={"#484C4F"}
          width={"65%"}
        >
          {!isLoading && (
            <ViewTicketForm
              ticket={openTicket}
              users={users}
              groups={groups}
              properties={properties}
              statuses={statusOptions}
              priorities={priorityOptions}
              categories={categoryOptions}
            />
          )}

          {isLoading && <Loader message="Loading Ticket..." />}
        </PopUpWindow>
      )}
      <div className="notifications-wrapper" style={{ position: "relative" }}>
        <div className="notifications">
          <Badge
            badgeContent={unreadCount}
            color="error"
            key={unreadCount}
            overlap="circular"
          >
            <Notifications
              ref={iconRef}
              style={{
                color: isHovered
                  ? "var(--secondary-color)"
                  : "var(--primary-color)",
                cursor: "pointer",
                fontSize: "30px",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                fetchNotifications();
                toggleNotificationsPanel();
                putNotificationsRead();
              }}
            />
          </Badge>
        </div>
        {isPanelOpen && (
          <div className="notifications-panel" ref={panelRef}>
            <div className="notifications-panel__header">
              <h3>Notifications</h3>
            </div>
            <div className="notifications-panel__content">
              {isLoading ? (
                <p>Loading...</p>
              ) : notifications.length === 0 ? (
                <p>No notifications</p>
              ) : (
                notifications.map((notification, index) => (
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={index >= lastKnownCount}
                    key={notification._id}
                  >
                    <div
                      key={notification._id}
                      className="notification"
                      onClick={() => {
                        if (
                          notification.type === "groupAdded" ||
                          notification.type === "groupRemoved"
                        ) {
                          return;
                        }

                        if (notification.type === "messageSent") {
                          window.location.href = `/inbox/${
                            notification.chatroom._id
                              ? notification.chatroom._id
                              : notification.chatroom
                          }`;
                          return;
                        }
                        if (notification.type === "chatroomCreated") {
                          window.location.href = `/inbox/${
                            notification.chatroom._id
                              ? notification.chatroom._id
                              : notification.chatroom
                          }`;
                          return;
                        }

                        if (notification.ticket === null) {
                          return;
                        }
                        handleNotificationClick(notification.ticket._id);
                      }}
                    >
                      <p style={{ cursor: "pointer", width: "100%" }}>
                        <strong>
                          {
                            users.find((user) => user._id === notification.by)
                              .username
                          }{" "}
                        </strong>
                        {/* { mentioned you in a comment on{" "}} */}
                        {notification.type === "mentionComment"
                          ? "mentioned you in a comment on"
                          : notification.type === "assignTicket"
                          ? "assigned you to"
                          : notification.type === "unassignTicket"
                          ? "unassigned you from"
                          : notification.type === "messageSent"
                          ? "sent you a message"
                          : notification.type === "chatroomCreated"
                          ? "added you to a chatroom"
                          : notification.type === "groupAdded"
                          ? "added you to the group"
                          : notification.type === "groupRemoved"
                          ? "removed you from the group"
                          : notification.type === "updateTicket"
                          ? "updated the ticket you created"
                          : notification.type === "comment"
                          ? "commented on your ticket"
                          : ""}{" "}
                        {notification.ticket ? notification.ticket.title : ""}
                        {notification.name ? notification.name : ""}
                        {/* <strong>{notification.user.username}</strong> mentioned you in a comment on{" "}
                      <strong>{notification.ticket.title}</strong> */}
                      </p>
                      <h5
                        style={{
                          fontSize: "12px",
                          fontWeight: "300",
                          padding: "0px",
                          margin: "0px",
                          alignSelf: "flex-end",
                        }}
                      >
                        {formatDateWithTime(notification.date)}
                      </h5>
                    </div>
                  </Badge>
                ))
              )}
              {!isLoading && notifications.length < totalItems && (
                <div
                  className="load-more"
                  onClick={() => {
                    if (totalItems <= notifications.length) {
                      return;
                    }
                    setCurrentPage((prevPage) => prevPage + 1);
                  }}
                >
                  Load More
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsPanel;
