import React, { useState, useEffect } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";

import * as XLSX from "xlsx";

import "./ImportPropertyButton.css";
import apiUrl from "../api";

const ImportPropertyButton = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );
  const user = JSON.parse(localStorage.getItem("user"));

  const BATCH_SIZE = 100;
  const handleFile = async (e) => {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "buffer" });
    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    setData(jsonData);


   
    props.setIsLoading && props.setIsLoading(true);
    // Batch upload logic
    for (let i = 0; i < jsonData.length; i += BATCH_SIZE) {
      const batch = jsonData.slice(i, i + BATCH_SIZE);
      await Promise.all(
        batch.map((property) =>
         uploadProperty (mapProperty(property))
        )
      );
    }

   

    props.setIsLoading && props.setIsLoading(false);
  };
// cleaning_phone	cleaning_phone2	accounting_email	accounting_name	accounting_phone	accounting_phone2	maintenance_email	maintenance_name	maintenance_phone	maintenance_phone2	manual_link	master_lock	owner_email	owner_home_number	owner_name	owner_phone	owner_phone2	pool_email	pool_name	pool_phone	pool_phone2	property_location	wifi_name	wifi_password	more_info
  const mapProperty = (property) => ({
    title: property["name"],
    owner_name : property["owner_name"],
    owner_email : property["owner_email"],
    owner_phone : property["owner_phone"],
    owner_phone2 : property["owner_phone2"],
    owner_home_number : property["owner_home_number"],
    accounting_name : property["logistic_name"],
    accounting_phone : property["logistic_phone"],
    accounting_email : property["logistic_email"],
    accounting_phone2 : property["logisitic_phone2"],
    cleaning_name : property["cleaning_name"],
    cleaning_phone : property["cleaning_phone"],
    cleaning_email : property["cleaning_email"],
    cleaning_phone2 : property["cleaning_phone2"],
    maintenance_name : property["maintenance_name"],
    maintenance_email : property["maintenance_email"],
    maintenance_phone : property["maintenance_phone"],
    maintenance_phone2 : property["maintenance_phone2"],
    pool_name : property["pool_name"],
    pool_phone : property["pool_phone"],
    pool_email : property["pool_email"],
    pool_phone2 : property["pool_phone2"],
    property_location : property["property_location"],
    master_lock : property["master_lock"],
    wifi_username : property["wifi_name"],
    wifi_password : property["wifi_password"],
    manual_link : property["manual_link"],
    arrivals : property["arrivals"],
  });

  const uploadProperty = async (property) => {
    
    try {
      const response = await axios.put(
        `${apiUrl}/property/updatePropertyByName`,
        {
          name: property.title,
          owner_name : property.owner_name,
          owner_email : property.owner_email,
          owner_phone : property.owner_phone,
          owner_phone2 : property.owner_phone2,
          owner_home_number : property.owner_home_number,
          accounting_name : property.accounting_name,
          accounting_phone : property.accounting_phone,
          accounting_email : property.accounting_email,
          accounting_phone2 : property.accounting_phone2,
          cleaning_name : property.cleaning_name,
          cleaning_phone : property.cleaning_phone,
          cleaning_email : property.cleaning_email,
          cleaning_phone2 : property.cleaning_phone2,
          maintenance_name : property.maintenance_name,
          maintenance_email : property.maintenance_email,
          maintenance_phone : property.maintenance_phone,
          maintenance_phone2 : property.maintenance_phone2,
          pool_name : property.pool_name,
          pool_phone : property.pool_phone,
          pool_email : property.pool_email,
          pool_phone2 : property.pool_phone2,
          property_location : property.property_location,
          master_lock : property.master_lock,
          wifi_username : property.wifi_username,
          wifi_password : property.wifi_password,
          manual_link : property.manual_link,
          arrivals : property.arrivals,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

    } catch (error) {

    }
  };


  const handleClick = () => {
    props.setIsLoading && props.setIsLoading(true);
    const input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    input.onchange = handleFile;
    input.click();
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth <= 768 ? "100%" : "auto";
      setButtonWidth(newWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: user.permissions.canAddReservation ? "flex" : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
            marginLeft: props.marginLeft || "0px",
            marginRight: props.marginRight || "0px",
          }}
          onClick={handleClick}
        >
          <Upload />
          <span>Import Properties</span>
        </button>
      </div>
    </>
  );
};
export default ImportPropertyButton;








