import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
  Chip,
  TableSortLabel,
  Button,
  TextField,
  Alert,
  Avatar,
  Checkbox,
} from "@mui/material";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import Select from "react-select";
import { RemoveRedEye, Edit } from "@mui/icons-material/";
import { debounce } from "lodash"; // Import debounce function from lodash

import {
  Loader,
  PopUpWindow,
  ViewArrivalForm,
  DraftEditor,
  CreateArrivalButton,
  EditArrivalForm,
} from "../../components";
import apiUrl from "../api";

import { CheckCircle, Pending } from "@mui/icons-material/";
import io from "socket.io-client";
import axios from "axios";
import "./arrivalTable.css";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateCalendar(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const findDueDateColor = (dueDate, selectedDay) => {
  const date = new Date(dueDate);
  const today = new Date(selectedDay);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#00F7FF";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const UserChip = ({ user, tasks, onClick, isActive }) => {
  return (
    <div className={`user-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: getColor(user.name),
          width: "30px",
          height: "30px",
          fontSize: "20px",
        }}
      >
        {user.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {user.name} ({tasks})
        </p>
      </div>
    </div>
  );
};

const socket = io(`${apiUrl}/`);

const ArrivalTable = ({ canAssign = true }) => {
  const [arrivals, setArrivals] = useState([]);
  const [arrivalsToDisplay, setArrivalsToDisplay] = useState([]);
  const [users, setUsers] = useState(new Set());
  const [groupsUsed, setGroupsUsed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({
    field: canAssign ? "date" : "done",
    direction: "asc",
  });
  // const [selectedArrivals, setSelectedArrivals] = useState([]);
  const [selectedArrival, setSelectedArrival] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [viewArrival, setViewArrival] = useState(false);
  const [activeUserChipIds, setActiveUserChipId] = useState(["all"]);
  const [selectedFilter, setSelectedFilter] = useState("Both");
  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedDate, setSelectedDate] = useState(new Date());
  const selectedDateRef = useRef(selectedDate); // Create a ref to store selectedDate

  const handleUpdateArrival = async (
    arrivalId,
    arrivalTime,
    assignedTo,
    notes,
    date,
    type
  ) => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${apiUrl}/arrival/update/${arrivalId}`,
        {
          arrivalTime: arrivalTime,
          assignedTo: assignedTo,
          notes: notes,
          date: date,
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      showSuccessAlert();
    } catch (error) {
      console.error("Failed to update arrival: ", error);
    }
  };

  const convertHtmlToEditorState = (html) => {
    const contentBlock = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
      contentBlock.entityMap
    );
    return EditorState.createWithContent(contentState);
  };
  const [newNote, setNewNote] = useState(convertHtmlToEditorState(""));

  const handlePreviousDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 1);
      return newDate;
    });
  };

  const handleNextDay = () => {
    setSelectedDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 1);
      return newDate;
    });
  };

  const handleSort = (field) => {
    if (sort.field === field) {
      setSort({
        field,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSort({ field, direction: "asc" });
    }
  };

  // const handleArrivalTimeUpdate = debounce((arrivalId, arrivalTime, arrival) => {
  //   // Allow only numbers and add colon after the second digit
  //   let formattedTime = arrivalTime.replace(/[^0-9]/g, "");

  //   if (formattedTime.length >= 3) {
  //     formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(
  //       2,
  //       4
  //     )}`;
  //   }

  //   if (formattedTime.length > 5) {
  //     formattedTime = formattedTime.slice(0, 5);
  //   }

  //   // Check if the formatted time is a valid time string
  //   const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  //   const isValidTime = timeRegex.test(formattedTime);

  //   setArrivals((prevArrivals) =>
  //     prevArrivals.map((arrival) =>
  //       arrival._id === arrivalId
  //         ? {
  //             ...arrival,
  //             arrivalTime: arrivalTime === "n" ? "" : formattedTime,
  //           }
  //         : arrival
  //     )
  //   );

  //   setArrivalsToDisplay((prevArrivals) =>
  //     prevArrivals.map((arrival) =>
  //       arrival._id === arrivalId
  //         ? {
  //             ...arrival,
  //             arrivalTime: arrivalTime === "n" ? "" : formattedTime,
  //           }
  //         : arrival
  //     )
  //   );

  // }, 0);

  const handleArrivalTimeUpdate = useMemo(
    () =>
      debounce((arrivalId, arrivalTime, arrival) => {
        // Allow only numbers and add colon after the second digit
        let formattedTime = arrivalTime.replace(/[^0-9]/g, "");

        if (formattedTime.length >= 3) {
          formattedTime = `${formattedTime.slice(0, 2)}:${formattedTime.slice(
            2,
            4
          )}`;
        }

        if (formattedTime.length > 5) {
          formattedTime = formattedTime.slice(0, 5);
        }

        // Check if the formatted time is a valid time string
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        const isValidTime = timeRegex.test(formattedTime);

        setArrivalsToDisplay((prevArrivals) =>
          prevArrivals.map((arrival) =>
            arrival._id === arrivalId
              ? {
                  ...arrival,
                  arrivalTime: arrivalTime === "n" ? "" : formattedTime,
                }
              : arrival
          )
        );
      }, 0),
    [] // Empty dependency array ensures it's only created once
  );
  const handleAssignedToChange = (arrivalId, assignedTo, arrival) => {
    handleUpdateArrival(
      arrivalId,
      arrival.arrivalTime,
      assignedTo,
      arrival.notes,
      arrival.date,
      arrival.type
    );

    if (assignedTo === "none") {
      assignedTo = null;
    }

    setArrivals((prevArrivals) =>
      prevArrivals.map((arrival) =>
        arrival._id === arrivalId ? { ...arrival, assignedTo } : arrival
      )
    );
  };

  const showSuccessAlert = () => {
    setLoading(false);
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 2000); // Hide alert after 3 seconds
  };

  const convertHTMLToText = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent ||
      doc.body.innerText ||
      doc.body.innerHTML.replace(/<[^>]*>/g, "")
      ? doc.body.textContent ||
          doc.body.innerText ||
          doc.body.innerHTML.replace(/<[^>]*>/g, "")
      : "No notes";
  };

  const getNextDay = (date) => {
    const nextDate = new Date(date);
    nextDate.setDate(date.getDate() + 1);
    return nextDate;
  };

  const sortArrivals = (arrivals, sort) => {
    const isValidTime = (time) => /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);

    const compareArrivalTimes = (a, b) => {
      const timeA = a.arrivalTime || "";
      const timeB = b.arrivalTime || "";

      const isTimeAEmpty = timeA === "" || !isValidTime(timeA);
      const isTimeBEmpty = timeB === "" || !isValidTime(timeB);

      if (isTimeAEmpty && isTimeBEmpty) return 0;
      if (isTimeAEmpty) return -1; // Empty times come first
      if (isTimeBEmpty) return 1; // Empty times come first

      // return timeB.localeCompare(timeA); // Descending order for time
      return timeA.localeCompare(timeB); // Ascending order for time
    };

    const compareDates = (a, b) => {
      const comparison = new Date(a.date) - new Date(b.date);
      if (comparison === 0) {
        return compareArrivalTimes(a, b);
      }
      return comparison;
    };

    const compareByField = (a, b, field) => {
      if (field === "date") {
        let comparison = new Date(a.date) - new Date(b.date);
        if (comparison === 0) {
          return compareArrivalTimes(a, b);
        }
        return comparison;
      } else if (field === "assignedTo") {
        if (a.assignedTo === b.assignedTo) {
          return 0;
        } else if (a.assignedTo === null) {
          return -1;
        } else if (b.assignedTo === null) {
          return 1;
        } else {
          return a.assignedTo.localeCompare(b.assignedTo);
        }
      } else if (field === "type") {
        return a.type.localeCompare(b.type);
      } else if (field === "notes") {
        const notesAEmpty = convertHTMLToText(a.notes).trim() === "No notes";
        const notesBEmpty = convertHTMLToText(b.notes).trim() === "No notes";
        return notesAEmpty === notesBEmpty ? 0 : notesAEmpty ? -1 : 1;
      } else if (field === "property") {
        return a.property.name.localeCompare(b.property.name);
      } else if (field === "done") {
        if (a.done === b.done) {
          return compareDates(a, b);
        }
        return a.done ? 1 : -1;
      }
      return 0;
    };

    arrivals.sort((a, b) => {
      let comparison = compareByField(a, b, sort.field);
      if (comparison === 0 && sort.field !== "date") {
        comparison = sort.direction === "asc" ? comparison : -comparison;
        comparison = compareDates(a, b);
        return comparison;
      }
      return sort.direction === "asc" ? comparison : -comparison;
    });

    return arrivals;
  };

  const handleNewNoteChange = (blockType) => {
    setNewNote(blockType);
  };

  const filterArrivalsByType = (arrivals) => {
    if (selectedFilter === "Both") {
      return arrivals;
    } else {
      return arrivals.filter((arrival) => arrival.type === selectedFilter);
    }
  };
  const filterAndSortArrivals = () => {
    setArrivalsToDisplay(
      sortArrivals(
        filterArrivalsByType(
          // Apply type filter before sorting
          arrivals.filter((arrival) => {
            if (activeUserChipIds.includes("all")) {
              return true;
            } else if (activeUserChipIds.includes("unassigned")) {
              return (
                arrival.assignedTo === null ||
                activeUserChipIds.includes(arrival.assignedTo)
              );
            } else if (activeUserChipIds.includes("assigned")) {
              return (
                arrival.assignedTo !== null ||
                activeUserChipIds.includes(arrival.assignedTo)
              );
            }

            return activeUserChipIds.includes(arrival.assignedTo);
          })
        ),
        sort
      )
    );
  };

  useEffect(() => {
    const fetchGroup = async (group) => {
      try {
        const response = await axios.get(`${apiUrl}/group/getGroup/${group}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const newGroup = response.data.group;
        setGroupsUsed((prevGroups) =>
          prevGroups.map((prevGroup) =>
            prevGroup === newGroup._id ? newGroup : prevGroup
          )
        );

        // Use a callback to access the latest 'users' state
        setUsers((prevUsers) => {
          // Filter out users already present in 'prevUsers'
          const newUsers = newGroup.users.filter(
            (newUser) =>
              !Array.from(prevUsers).some(
                (existingUser) => existingUser._id === newUser._id
              )
          );

          return new Set([...prevUsers, ...newUsers]);
        });
      } catch (error) {
        console.error("Failed to fetch group: ", error);
      }
    };

    const fetchGroups = async (groups) => {
      try {
        const groupPromises = groups.map((group) => fetchGroup(group));
        await Promise.all(groupPromises);
      } catch (error) {
        console.error("Failed to fetch groups: ", error);
      }
    };

    const fetchUpcomingArrivals = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/arrival/${
            canAssign ? "upcoming" : "my-arrivals"
          }/${selectedDate}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        setArrivals(response.data.arrivals);
        setArrivalsToDisplay(sortArrivals(response.data.arrivals, sort));
        setGroupsUsed(response.data.groupsUsed);

        if (canAssign) {
          fetchGroups(response.data.groupsUsed);
        }
      } catch (error) {
        console.error("Failed to fetch groups: ", error);
      }
    };
    fetchUpcomingArrivals();
    setActiveUserChipId(["all"]);
    setLoading(false);
  }, [selectedDate, user.token, canAssign]);

  useEffect(() => {
    filterAndSortArrivals();
  }, [activeUserChipIds, sort, selectedFilter, arrivals]);

  useEffect(() => {
    const handleArrivalUpdate = (arrival) => {
      const currentSelectedDate = selectedDateRef.current;
      if (arrivals.find((a) => a._id === arrival._id)) {
        setArrivals((prevArrivals) =>
          prevArrivals.map((a) => (a._id === arrival._id ? arrival : a))
        );
      } else if (
        !loading &&
        arrival.assignedTo === user._id &&
        (formatDate(new Date(arrival.date)) ===
          formatDate(currentSelectedDate) ||
          formatDate(getNextDay(new Date(arrival.date))) ===
            formatDate(getNextDay(currentSelectedDate)))
      ) {
        setArrivals((prevArrivals) => [...prevArrivals, arrival]);
      }
    };

    const handleArrivalDelete = (arrivalId) => {
      setArrivals((prevArrivals) =>
        prevArrivals.filter((arrival) => arrival._id !== arrivalId)
      );
    };

    const handleArrivalFinished = (arrivalId) => {
      setArrivals((prevArrivals) =>
        prevArrivals.map((arrival) =>
          arrival._id === arrivalId ? { ...arrival, done: true } : arrival
        )
      );
    };

    if (!loading) {
      socket.on("arrivalUpdated", (arrival) => {
        handleArrivalUpdate(arrival);
        filterAndSortArrivals();
      });
      socket.on("arrivalDeleted", handleArrivalDelete);
      socket.on("arrivalFinished", handleArrivalFinished);

      return () => {
        socket.off("arrivalUpdated");
        socket.off("arrivalDeleted");
        socket.off("arrivalFinished");
      };
    }
  }, [arrivals]);

  useEffect(() => {
    selectedDateRef.current = selectedDate; // Update the ref whenever selectedDate changes
  }, [selectedDate]);
  return (
    <>
      {!loading && canAssign && localStorage.getItem("legacy") === "false" && (
        <CreateArrivalButton />
      )}
      {/* Succes Alert */}
      {showAlert && (
        <PopUpWindow onClose={() => setShowAlert(false)}>
          <Alert severity="success">
            Arrival has been updated successfully!
          </Alert>
        </PopUpWindow>
      )}

      {/* Edit Arrival */}
      {showPopup && (
        <PopUpWindow onClose={() => setShowPopup(false)}>
          <EditArrivalForm
            selectedArrival={selectedArrival}
            arrivals={arrivals}
            setArrivals={setArrivals}
            showPopup={showPopup}
            formatDate={formatDate}
            handleUpdateArrival={handleUpdateArrival}
          />
        </PopUpWindow>
      )}
      {/* View Arrival */}
      {viewArrival && (
        <PopUpWindow onClose={() => setViewArrival(false)}>
          <ViewArrivalForm
            arrivalId={selectedArrival._id}
            propertyName={selectedArrival.property.name}
            propertyId={selectedArrival.property._id}
            arrivalDate={selectedArrival.date}
            arrivalTime={selectedArrival.arrivalTime}
            arrivalType={selectedArrival.type}
            assignedTo={selectedArrival.assignedTo}
            notes={selectedArrival.notes}
            reservationId={selectedArrival.reservation}
            done={selectedArrival.done}
            closeForm={() => setViewArrival(false)}
            doneArrival={() => {
              if (canAssign) {
                setArrivals(
                  arrivals.filter(
                    (arrival) => arrival._id !== selectedArrival._id
                  )
                );
              } else {
                setArrivals((prevArrivals) =>
                  prevArrivals.map((arrival) =>
                    arrival._id === selectedArrival._id
                      ? { ...arrival, done: true }
                      : arrival
                  )
                );
              }
            }}
          />
        </PopUpWindow>
      )}
      {/* Loader */}
      {loading && <Loader />}

      {/* Filter Row */}
      {!loading && (
        <div className="filter-row">
          {["Both", "Check-In", "Check-Out"].map((filter) => (
            <div
              key={filter}
              className={`filter-option ${
                selectedFilter === filter ? "active" : ""
              }`}
              onClick={() => setSelectedFilter(filter)}
            >
              {filter}
            </div>
          ))}
        </div>
      )}

      {/*Users Row*/}
      {!loading && canAssign && (
        <div className="users-row">
          <UserChip
            user={{ name: "All", _id: "all" }}
            tasks={arrivals.length}
            onClick={() => {
              setActiveUserChipId(["all"]);
            }}
            isActive={activeUserChipIds.includes("all")}
          />
          <UserChip
            user={{ name: "Unassigned", _id: "unassigned" }}
            tasks={
              arrivals.filter((arrival) => arrival.assignedTo === null).length
            }
            onClick={() => {
              setActiveUserChipId((prevActiveUserChipIds) =>
                prevActiveUserChipIds.includes("unassigned")
                  ? prevActiveUserChipIds.filter((id) => id !== "unassigned")
                      .length === 0
                    ? ["all"]
                    : prevActiveUserChipIds.filter((id) => id !== "unassigned")
                  : [
                      ...prevActiveUserChipIds.filter(
                        (id) => id !== "all" && id !== "assigned"
                      ),
                      "unassigned",
                    ]
              );
            }}
            isActive={activeUserChipIds.includes("unassigned")}
          />

          <UserChip
            user={{ name: "Assigned", _id: "assigned" }}
            tasks={
              arrivals.filter((arrival) => arrival.assignedTo !== null).length
            }
            onClick={() => {
              setActiveUserChipId((prevActiveUserChipIds) =>
                prevActiveUserChipIds.includes("assigned")
                  ? prevActiveUserChipIds.filter((id) => id !== "assigned")
                      .length === 0
                    ? ["all"]
                    : prevActiveUserChipIds.filter((id) => id !== "assigned")
                  : [
                      ...prevActiveUserChipIds.filter(
                        (id) => id !== "all" && id !== "unassigned"
                      ),
                      "assigned",
                    ]
              );
            }}
            isActive={activeUserChipIds.includes("assigned")}
          />

          {Array.from(users)
            .sort((a, b) => {
              const tasksA = arrivals.filter(
                (arrival) => arrival.assignedTo === a._id
              ).length;
              const tasksB = arrivals.filter(
                (arrival) => arrival.assignedTo === b._id
              ).length;
              return tasksB - tasksA;
            })
            .map((user) => (
              <UserChip
                key={user._id}
                user={user}
                tasks={
                  arrivals.filter((arrival) => arrival.assignedTo === user._id)
                    .length
                }
                onClick={() => {
                  setActiveUserChipId(
                    activeUserChipIds.includes(user._id)
                      ? activeUserChipIds.filter((id) => id !== user._id)
                          .length === 0
                        ? ["all"]
                        : activeUserChipIds.filter((id) => id !== user._id)
                      : [
                          ...activeUserChipIds.filter((id) => id !== "all"),
                          user._id,
                        ]
                  );
                }}
                isActive={activeUserChipIds.includes(user._id)}
              />
            ))}
        </div>
      )}
      {/* Table */}
      {!loading && (
        <>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "row", sm: "row", md: "row" }}
            >
              <div className="add-ticket-button">
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--primary-text-color)",
                    fontSize: "15px",
                    fontWeight: "600",
                    width: "100%",
                  }}
                  onClick={() => {
                    handlePreviousDay();
                  }}
                >
                  <span>Previous Day</span>
                </button>
              </div>

              <div className="add-ticket-button">
                <button
                  className="add-ticket-button__button"
                  style={{
                    backgroundColor: "var(--primary-color)",
                    color: "var(--primary-text-color)",
                    fontSize: "15px",
                    fontWeight: "600",
                    width: "100%",
                  }}
                  onClick={() => {
                    handleNextDay();
                  }}
                >
                  <span>Next Day</span>
                </button>
              </div>
            </Box>
            <Typography
              variant="h6"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                type="date"
                value={formatDateCalendar(selectedDate)}
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                variant="outlined"
                margin="dense"
                style={{
                  padding: " 0 2px",
                }}
              />{" "}
              - {formatDate(getNextDay(selectedDate))}{" "}
            </Typography>
          </Box>
          {arrivals.length !== 0 && (
            <TableContainer
              component={Paper}
              sx={{
                maxheight: 540,
                overflow: "auto",
                overflowX: "scroll",
                width: "100%",
                paddingRight: "20px",
              }}
            >
              <Table
                aria-label="simple table"
                stickyHeader
                sx={{
                  padding: "10px",
                }}
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                    <Checkbox
                      checked={selectedArrivals.length === arrivals.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedArrivals(
                            arrivals.map((arrival) => arrival._id)
                          );
                        } else {
                          setSelectedArrivals([]);
                        }
                      }}
                    />
                  </TableCell> */}
                    <TableCell></TableCell>
                    {/* {!canAssign && ( */}
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "done"}
                        direction={
                          sort.field === "done" ? sort.direction : "asc"
                        }
                        onClick={() => handleSort("done")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {/* )} */}
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "property"}
                        direction={
                          sort.field === "property" ? sort.direction : "asc"
                        }
                        onClick={() => handleSort("property")}
                      >
                        Property
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "date"}
                        direction={
                          sort.field === "date" ? sort.direction : "asc"
                        }
                        onClick={() => handleSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "notes"}
                        direction={
                          sort.field === "notes" ? sort.direction : "asc"
                        }
                        onClick={() => handleSort("notes")}
                      >
                        Notes
                      </TableSortLabel>
                    </TableCell>
                    {canAssign && (
                      <TableCell
                        align="center"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <TableSortLabel
                          active={sort.field === "assignedTo"}
                          direction={
                            sort.field === "assignedTo" ? sort.direction : "asc"
                          }
                          onClick={() => handleSort("assignedTo")}
                        >
                          Assigned To
                        </TableSortLabel>
                      </TableCell>
                    )}

                    {canAssign && <TableCell>Group</TableCell>}
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "type"}
                        direction={
                          sort.field === "type" ? sort.direction : "asc"
                        }
                        onClick={() => handleSort("type")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>

                    {/* <TableCell></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrivalsToDisplay.map((arrival, index) => (
                    <TableRow key={index}>
                      {/* <TableCell>
                      <Checkbox
                        checked={selectedArrivals.includes(arrival._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedArrivals([
                              ...selectedArrivals,
                              arrival._id,
                            ]);
                          } else {
                            setSelectedArrivals(
                              selectedArrivals.filter(
                                (id) => id !== arrival._id
                              )
                            );
                          }
                        }}
                      />
                    </TableCell> */}

                      <TableCell sx={{ padding: "0px" }}>
                        <Tooltip title="View" placement="right">
                          <Button
                            onClick={() => {
                              setSelectedArrival(arrival);
                              setViewArrival(true);
                            }}
                          >
                            <RemoveRedEye
                              sx={{ color: "var(--primary-color)" }}
                            />
                          </Button>
                        </Tooltip>

                        {canAssign && (
                          <Tooltip title="Edit" placement="right">
                            <Button
                              onClick={() => {
                                setSelectedArrival(arrival);
                                setShowPopup(true);
                                setNewNote(
                                  convertHtmlToEditorState(arrival.notes)
                                );
                              }}
                            >
                              <Edit sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                      {/* {!canAssign && ( */}
                      <TableCell>
                        <Chip
                          icon={
                            arrival.done ? (
                              <CheckCircle style={{ color: "#fff" }} />
                            ) : (
                              <Pending style={{ color: "#fff" }} />
                            )
                          }
                          label={arrival.done ? "Done" : "Pending"}
                          style={{
                            backgroundColor: arrival.done
                              ? "#65FD5A"
                              : "#53B2FF",
                            color: findContrastColor(
                              arrival.done ? "#11ff00" : "#FB0909"
                            ),
                          }}
                        />
                      </TableCell>
                      {/* )} */}
                      <TableCell>{arrival.property.name}</TableCell>
                      <TableCell>
                        <Chip
                          label={formatDate(arrival.date)}
                          style={{
                            backgroundColor: findDueDateColor(
                              arrival.date,
                              selectedDate
                            ),
                            color: findContrastColor(
                              findDueDateColor(arrival.date, selectedDate)
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="Accepted values hh:mm then press 'Enter' | Press 'n' to clear the time"
                          placement="top"
                        >
                          <input
                            key={arrival._id}
                            type="String"
                            readOnly={!canAssign}
                            placeholder={"None"}
                            value={arrival.arrivalTime}
                            style={{
                              border: "1px solid #9A9A9A",
                              width: "100%",
                              textAlign: "center",
                              backgroundColor: "transparent",
                            }}
                            onChange={(e) => {
                              handleArrivalTimeUpdate(
                                arrival._id,
                                e.target.value,
                                arrival
                              ); // Call the memoized function
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                const isValidTime =
                                  /^([01]\d|2[0-3]):([0-5]\d)$/.test(
                                    e.target.value
                                  );
                                if (isValidTime) {
                                  handleUpdateArrival(
                                    arrival._id,
                                    arrival.arrivalTime,
                                    arrival.assignedTo,
                                    arrival.notes,
                                    arrival.date,
                                    arrival.type
                                  );

                                  filterAndSortArrivals();
                                }
                              } else if (e.key.toLowerCase() === "n") {
                                handleUpdateArrival(
                                  arrival._id,
                                  "n",
                                  arrival.assignedTo,
                                  arrival.notes,
                                  arrival.date,
                                  arrival.type
                                );

                                filterAndSortArrivals();
                              }
                            }}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip
                          title={convertHTMLToText(arrival.notes)}
                          PopperProps={{
                            style: { zIndex: 10001 },
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -10],
                                },
                              },
                            ],
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                fontSize: "13px",
                              },
                            },
                          }}
                        >
                          <span>
                            {convertHTMLToText(arrival.notes).substring(0, 50) +
                              (convertHTMLToText(arrival.notes).length > 50
                                ? "..."
                                : "")}
                          </span>
                        </Tooltip>
                      </TableCell>

                      {canAssign && (
                        <TableCell>
                          {/* <AssignedToCell user={arrival.assignedTo} /> */}
                          <Select
                            options={Array.from(users)
                              .map((user) => ({
                                value: user,
                                label: user.name,
                              }))
                              .concat({
                                value: {
                                  _id: "none",
                                  name: "None",
                                },
                                label: "None",
                              })}
                            value={{
                              value: arrival.assignedTo,
                              label:
                                arrival.assignedTo === null
                                  ? "None"
                                  : Array.from(users).find(
                                      (user) => user._id === arrival.assignedTo
                                    )?.name,
                            }}
                            onChange={(selectedUser) => {
                              handleAssignedToChange(
                                arrival._id,
                                selectedUser.value?._id,
                                arrival
                              );
                            }}
                          />
                        </TableCell>
                      )}

                      {canAssign && (
                        <TableCell>
                          <Chip
                            label={
                              groupsUsed.find(
                                (group) => group._id === arrival.managedBy
                              )?.name
                            }
                          />
                        </TableCell>
                      )}

                      <TableCell>
                        {capitalizeFirstLetter(arrival.type)}
                      </TableCell>

                      {/* <TableCell>
                      <Tooltip title="Delete" placement="right">
                        <Button onClick={() => {}}>
                          <Delete sx={{ color: "var(--primary-color)" }} />
                        </Button>
                      </Tooltip>
                    </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {arrivals.length === 0 && (
            <Alert severity="info">No arrivals found for this day</Alert>
          )}
        </>
      )}
    </>
  );
};

export default ArrivalTable;
