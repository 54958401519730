import React, { useState, useEffect } from "react";

import { Avatar, Box, Tooltip } from "@mui/material";
import {Profile as ProfileComponent} from "../../components";
import { PageStructure } from "../../pages";

import "./Profile.css";
import { CheckBox } from "@mui/icons-material";
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const Profile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  
  return (
    <PageStructure>
      <h2>Profile</h2>
      <ProfileComponent user={user} />
    </PageStructure>
  );
};

export default Profile;
