import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import apiUrl from "../api";

import axios from "axios";
import Loader from "../loader/Loader";

const SignUpForm = ({ setIsLogin }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // For password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // For confirm password visibility

  const setUser = useUser().setUser;

  const validate = () => {
    let isValid = true;
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      username: "",
      password: "",
      confirmPassword: "",
    };

    // Checking for empty fields
    if (!firstName) {
      newErrors.firstName = "First name cannot be empty.";
      isValid = false;
    }
    if (!lastName) {
      newErrors.lastName = "Last name cannot be empty.";
      isValid = false;
    }
    if (!email) {
      newErrors.email = "Email cannot be empty.";
      isValid = false;
    } else if (!email.includes("@")) {
      newErrors.email = "Invalid email format.";
      isValid = false;
    }
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number cannot be empty.";
      isValid = false;
    } else if (!phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = "Invalid phone number format.";
      isValid = false;
    }
    if (!username) {
      newErrors.username = "Username cannot be empty.";
      isValid = false;
    }
    if (!password) {
      newErrors.password = "Password cannot be empty.";
      isValid = false;
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = "Confirm password cannot be empty.";
      isValid = false;
    } else if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
      isValid = false;
    }
    // Add more validation checks as needed

    setErrors(newErrors);
    return isValid;
  };

  const signUp = async () => {
    const data = {
      name: firstName + " " + lastName,
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      username: username,
    };
    setIsLoading(true);
    try {
      
      const response = await axios.post(`${apiUrl}/auth/signup`, {
        name: data.name,
        email: data.email,
        password: data.password,
        phone: data.phoneNumber,
        username: data.username,
      });
      console.log(response.data);
      setIsLoading(false);
      setUser(response.data.user);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem(
        "logout",
        new Date().getTime() + 1000 * 60 * 60 * 24
      );
      navigate("/dashboard");
    } catch (error) {
      const errorMessage = error.response.data.data
        ? error.response.data.data.map((error) => error.msg + " ")
        : "";
      setErrorMessage(
        errorMessage
          ? errorMessage
          : "Something went wrong. Please try again later."
      );
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (event) => {
    setErrorMessage("");
    event.preventDefault();
    if (!validate()) {
      console.log("Validation failed.");
      return;
    }

    signUp();
  }; 

  return (
    <Container maxWidth="sm">
      {isLoading && <Loader message={"Loading please wait"} />}

      {!isLoading && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Sign Up
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setErrors({ ...errors, firstName: "" });
              }}
              color="secondary"
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setErrors({ ...errors, lastName: "" });
              }}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setErrors({ ...errors, username: "" });
              }}
              error={!!errors.username}
              helperText={errors.username}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors({ ...errors, email: "" });
              }}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              type="tel"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setErrors({ ...errors, phoneNumber: "" });
              }}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Password"
              variant="outlined"
              fullWidth
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors({ ...errors, password: "" });
              }}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrors({ ...errors, confirmPassword: "" });
              }}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {errorMessage !== "" && (
            <Grid item xs={12}>
              <p
                style={{
                  color: "red",
                  padding: "10px",
                  backgroundColor: "#FFC1C1E0",
                }}
              >
                {errorMessage}
              </p>
            </Grid>
          )}

          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              style={{
                backgroundColor: "var(--primary-color)",
              }}
              onClick={handleSubmit}
            >
              Signup
            </Button>
          </Grid>
          <Grid item xs={8}>
            <p
              onClick={() => setIsLogin(true)}
              style={{ cursor: "pointer", color: "var(--primary-color)" }}
            >
              Already have an account?
            </p>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default SignUpForm;
