import React, { useState } from "react";

import { FormControlLabel, FormGroup, Switch } from "@mui/material";

import "./LegacySwitcher.css";


const CustomSwicther = (props) => {
    const [checked, setChecked] = React.useState(
        props.checked === "true" ? true : false
    );
    const checkFunc = props.checkFunc;

    const handleChange = () => {
        setChecked((prev) => !prev);
        checkFunc();
    };

    return (
        <label>

         
        <label className="switch">
            <input type="checkbox" checked={checked} onChange={handleChange}  />
            <span className="slider round"
                style={{
                    backgroundColor: checked ? "#6A3104" : "#ccc",
                    borderRadius: "34px",
                }}
            ></span>
        </label>
        </label>

    );
}

const LegacySwitcher = () => {




    const [legacy, setLegacy] = useState(
        localStorage.getItem("legacy") === "true" ? true : false
    );
    
    const handleChange = () => {
        setLegacy(!legacy);
        localStorage.setItem("legacy", !legacy);
        window.location.reload();
    };
    
    return (
        <div className="legacy-switcher">
            <CustomSwicther
                checked={legacy.toString()}
                checkFunc={handleChange}
            />

        </div>
    );
    }

export default LegacySwitcher;