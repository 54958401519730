import React, { useState, useEffect } from "react";
import {
  TextField,
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Select from "react-select";
import apiUrl from "../api";

import axios from "axios";

const AddArrivalOption = () => {
  const [arrivalName, setArrivalName] = useState("");
  const [arrivalGroup, setArrivalGroup] = useState("");
  const [isGroup, setIsGroup] = useState(false);
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/group/getGroupsNames`,
         
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        setGroups(response.data.groups);
      } catch (error) {
        console.error("Failed to fetch groups: ", error);
      }
    };

    const fetchUpcomingArrivals = async () => {
      try {
        const response = await axios.get(

          `${apiUrl}/arrival/upcoming`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        // setGroups(response.data.groups);
      } catch (error) {
        console.error("Failed to fetch groups: ", error);
      }
    };
    fetchGroups();
    fetchUpcomingArrivals();
    setIsLoading(false);
  }, []);



  const handleAddArrival = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (arrivalName === "" && !isGroup) {
      return setError("Arrival name is required.");
    }

    if (arrivalGroup === "" && isGroup) {
      return setError("Arrival group is required.");
    }

    try {
      const response = await axios.post(
   
        `${apiUrl}/arrival-options/create`,
        {
          name: isGroup ? "" : arrivalName,
          group: !isGroup ? null : arrivalGroup.value,
          is_group: isGroup,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setSuccess("Arrival added successfully.");

      setArrivalName("");
      setArrivalGroup("");
      setIsGroup(false);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("Arrival already exists.");
      } else {
        setError("Failed to add arrival. Please try again.");
      }
    }
  };

  return (
    <div className="add-category-option-wrapper">
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
      <h4
        style={{
          marginBottom: "0px",
          fontWeight: "600",
        }}
      >
        Add Arrival Option
      </h4>
      {!isLoading && (
        <form
          onSubmit={handleAddArrival}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <TextField
            label="Arrival Name"
            variant="outlined"
            disabled={isGroup}
            value={arrivalName}
            onChange={(e) => setArrivalName(e.target.value)}
          />

          <Select
            styles={{
              control: (styles) => ({
                ...styles,
                width: "200px",
              }),
            }}
            options={groups.map((group) => ({
              value: group._id,
              label: group.name,
            }))}
            onChange={(e) => {
              setArrivalGroup({
                value: e.value,
                label: e.label,
              });
            }}
            value={arrivalGroup}
            isDisabled={!isGroup}
            placeholder="Select Group"
          />

          <FormControl>
            <FormControlLabel
              labelPlacement="top"
              control={
                <Checkbox
                  checked={isGroup}
                  onChange={(e) => setIsGroup(e.target.checked)}
                />
              }
              label="Is Group"
            />
          </FormControl>

          <button
            className="add-ticket-button__button"
            sx={{
              backgroundColor: "var(--primary-color)",
              color: "var(--primary-text-color)",
              fontSize: "15px",
              fontWeight: "600",
              width: "auto",
              padding: "10px 20px",
            }}
            onClick={() => {}}
          >
            <AddIcon />
            <span>Add</span>
          </button>
        </form>
      )}
    </div>
  );
};

export default AddArrivalOption;
