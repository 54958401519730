import React, { useState } from "react";
import axios from "axios";
import "./Reservations.css";

// Importing necessary components and icons
import {
  AddReservationButton,
  Sidebar,
  TaskManager,
  ReservationTable,
  Loader,
  PopUpWindow,
  CircularProgressWithLabel,
} from "../../components";

import { Add, Task } from "@mui/icons-material";
import { PageStructure } from "../../pages";
import apiUrl from "../../components/api";

const convertSecondsToMinutes = (seconds) => {
  return seconds / 60;
};

const Reservations = () => {
  // State management
  const [isLoading, setIsLoading] = useState(false);
  const [duplicatePropertyReservations, setDuplicatePropertyReservations] =
    useState([]);
  const [newPropertyReservations, setNewPropertyReservations] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(0);

  // Retrieve user data from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  // Function to upload property details to the server
  const uploadProperty = async (property) => {
    try {
      await axios.post(
        `${apiUrl}/property/createPropertyLoggiaId`,
        { name: property.property, loggiaId: property.loggia_id },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
    } catch (error) {
      console.error("Error uploading property:", error);
    }
  };

  // Function to upload a single reservation to the server
  const uploadSingleReservation = async (reservation, loggia_id) => {
    try {
      await axios.post(
        `${apiUrl}/reservation/createReservationWithLoggiaId`,
        {
          loggiaId: loggia_id,
          reservationId: reservation.reservationId,
          property: reservation.property,
          checkIn: reservation.checkIn
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          checkOut: reservation.checkOut
            .split("-")
            .map((part, index, array) =>
              index === 0 ? array[1] : index === 1 ? array[0] : part
            )
            .join("-"),
          nights: reservation.nights,
          adults: reservation.adults,
          kids: reservation.kids,
          cribs: reservation.cribs,
          highChairs: reservation.highChairs,
          idPassport: reservation.idPassport,
          guestTotal: reservation.guestTotal,
          channel: reservation.channel,
          channelAmount: reservation.channelAmount,
          firstName: reservation.firstName,
          lastName: reservation.lastName,
          email: reservation.email,
          phone: reservation.phone,
          staffNotes: reservation.staffNotes,
          customerNotes: reservation.customerNotes,
          arrivalTime: reservation.arrivalTime,
          arrivalLocation: reservation.arrivalLocation,
          arrivalFlight: reservation.arrivalFlight,
          departureTime: reservation.departureTime,
          departureLocation: reservation.departureLocation,
          departureFlight: reservation.departureFlight,
          status: reservation.status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error uploading reservation:", error);
    }
  };

  // Function to handle changes in loggia ID fields
  const handleChange = (index, type, value) => {
    if (type === "new") {
      setNewPropertyReservations((prev) => {
        const updatedReservations = [...prev];
        updatedReservations[index].loggia_id = value;
        return updatedReservations;
      });
    } else {
      setDuplicatePropertyReservations((prev) => {
        const updatedReservations = [...prev];
        updatedReservations[index].loggia_id = value;
        return updatedReservations;
      });
    }
  };

  // Function to handle the creation of reservations
  const handleClickCreate = async () => {
    const filteredDuplicateReservations = duplicatePropertyReservations.filter(
      (reservation) => reservation.loggia_id !== ""
    );
    const filteredNewReservations = newPropertyReservations.filter(
      (reservation) => reservation.loggia_id !== ""
    );

    // Upload duplicate reservations
    if (filteredDuplicateReservations.length > 0) {
      await Promise.all(
        filteredDuplicateReservations.map(async (reservation) => {
          await uploadProperty(reservation);
          await uploadSingleReservation(reservation, reservation.loggia_id);
          setDuplicatePropertyReservations((prev) =>
            prev.filter(
              (res) => res.reservationId !== reservation.reservationId
            )
          );
        })
      );
    }

    // Upload new reservations
    if (filteredNewReservations.length > 0) {
      await Promise.all(
        filteredNewReservations.map(async (reservation) => {
          await uploadProperty(reservation);
          await uploadSingleReservation(reservation, reservation.loggia_id);
          setNewPropertyReservations((prev) =>
            prev.filter(
              (res) => res.reservationId !== reservation.reservationId
            )
          );
        })
      );
    }
  };

  return (
    <PageStructure>
      <h1>Reservations</h1>
      <h2>Manage Your Reservations</h2>

      {/* Loader while data is loading */}
      {isLoading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            alignSelf: "center",
          }}
        >
          <CircularProgressWithLabel value={progressPercentage} />
        </div>
      )}

      {/* Display pop-up window if there are reservations to show and not loading */}
      {(duplicatePropertyReservations.length > 0 ||
        newPropertyReservations.length > 0) &&
        !isLoading && (
          <PopUpWindow
            title="Import Summary"
            onClose={() => {
              setDuplicatePropertyReservations([]);
              setNewPropertyReservations([]);
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
              }}
            >
              {/* Display new reservations */}
              <h3>New Reservations</h3>
              {(newPropertyReservations).map(
                (reservation, index) => (
                  <div key={index} className="form-group">
                    <div className="form-field">
                      <label
                        className="form-field-label"
                        htmlFor="reservationId"
                      >
                        Reservation ID:
                      </label>
                      <input
                        className="form-field-input"
                        type="text"
                        id="reservationId"
                        name="reservationId"
                        value={reservation.reservationId}
                        readOnly
                      />
                    </div>
                    <div className="form-field">
                      <label
                        className="form-field-label"
                        htmlFor="propertyName"
                      >
                        Property Name:
                      </label>
                      <input
                        className="form-field-input"
                        type="text"
                        id="propertyName"
                        name="propertyName"
                        value={reservation.property}
                        readOnly
                      />
                    </div>
                    <div className="form-field">
                      <label className="form-field-label" htmlFor="loggiaID">
                        Loggia Property Id:
                      </label>
                      <input
                        className="form-field-input"
                        type="text"
                        id="loggiaID"
                        name="loggiaID"
                        value={reservation.loggia_id}
                        onChange={(e) =>
                          handleChange(index, "new", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )
              )}

              {/* Display duplicate reservations */}
              <h3>Duplicate Reservations</h3>
              {(duplicatePropertyReservations).map(
                (reservation, index) => (
                  <div key={index} className="form-group">
                    <div className="form-field">
                      <label
                        className="form-field-label"
                        htmlFor="reservationId"
                      >
                        Reservation ID:
                      </label>
                      <input
                        className="form-field-input"
                        type="text"
                        id="reservationId"
                        name="reservationId"
                        value={reservation.reservationId}
                        readOnly
                      />
                    </div>
                    <div className="form-field">
                      <label
                        className="form-field-label"
                        htmlFor="propertyName"
                      >
                        Property Name:
                      </label>
                      <input
                        className="form-field-input"
                        type="text"
                        id="propertyName"
                        name="propertyName"
                        value={reservation.property}
                        readOnly
                      />
                    </div>
                    <div className="form-field">
                      <label className="form-field-label" htmlFor="loggiaID">
                        Loggia Property Id:
                      </label>
                      <input
                        className="form-field-input"
                        type="text"
                        id="loggiaID"
                        name="loggiaID"
                        value={reservation.loggia_id}
                        onChange={(e) =>
                          handleChange(index, "duplicate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                )
              )}

              {/* Button to trigger reservation creation */}
              <div className="form-group">
                <div className="form-field">
                  <button
                    onClick={handleClickCreate}
                    className="add-ticket-button__button"
                    style={{
                      backgroundColor: "var(--primary-color)",
                      color: "var(--primary-text-color)",
                      fontSize: "15px",
                      fontWeight: "600",
                      width: "100%",
                    }}
                  >
                    Create Reservations
                  </button>
                </div>
              </div>
            </div>
          </PopUpWindow>
        )}

      {/* Main content */}
      <div>
        {!isLoading && (
          <>
            {/* Button to add a new reservation */}
            {localStorage.getItem("legacy") === "false" && (
              <AddReservationButton
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setDuplicatePropertyReservations={
                  setDuplicatePropertyReservations
                }
                setNewPropertyReservations={setNewPropertyReservations}
                setProgress={setProgressPercentage}
              />
            )}

            {/* Table displaying current reservations */}
            <ReservationTable />
          </>
        )}
      </div>
    </PageStructure>
  );
};

export default Reservations;
