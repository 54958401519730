import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import App from './App';

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#6A3104", // Replace with your primary color
    },
    secondary: {
      main: "#ac510c", // Replace with your secondary color
    },
    tertiary: {
      main: "#db864e", // Replace with your tertiary color
    },
  },
});

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement); // Create root using createRoot

root.render(

    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
