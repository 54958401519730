import React, { useState, useEffect, useRef } from "react";

import axios from "axios";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  MenuItem,
  Chip,
  Avatar,
  Box,
} from "@mui/material";
import { Loader } from "../";
import "./Chatrooms.css";
import io from "socket.io-client";
import apiUrl from "../api";

// Function to generate a color based on the username
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const AssignedToCell = ({ userIds, allUsers }) => {
  // Placeholder for fetching user names based on IDs
  // In a real application, replace this with actual logic to fetch user details
  const users = userIds.map(
    (userId) =>
      allUsers.find((user) => user._id === userId) || { name: "Unknown" }
  );

  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      {users.map((user) => (
        <Tooltip key={user.id} title={user.name}>
          <Avatar style={{ backgroundColor: getColor(user.name) }}>
            {user.name[0]}
          </Avatar>
        </Tooltip>
      ))}
    </Box>
  );
};

const Chatrooms = (props) => {
  const [chatrooms, setChatrooms] = useState([]);
  const [totalChatrooms, setTotalChatrooms] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [users, setUsers] = useState([]);

  const getChatroomIdFromUrl = () => {
    const url = window.location.href;
    const chatroomId = url.substring(url.lastIndexOf("/") + 1);
    return chatroomId;
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchChatrooms = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${apiUrl}/chatroom/getChatrooms?search=${search}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setChatrooms((chatrooms) => [...chatrooms, ...response.data.chatrooms]);
      setTotalChatrooms(response.data.totalItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(error.response.data.message);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/getUsers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(
        response.data.users.filter(
          (u) => u._id !== user._id && u._id !== user.userId
        )
      );
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchChatrooms();
  }, [page]);

  useEffect(() => {
    const socket = io(`${apiUrl}/`);
    socket.on("chatroomCreated", (data) => {
      if (data.chatroom.users.find((u) => u._id === user._id)) {
        if (!data.prevUsers || data.prevUsers.includes(user._id)) {
          setChatrooms((chatrooms) => [...chatrooms, data.chatroom]);
        }
      }
    });

    socket.on("chatroomDeleted", (data) => {
      if (data.usersRemoved && data.usersRemoved.includes(user._id)) {
        setChatrooms((chatrooms) =>
          chatrooms.filter((chatroom) => chatroom._id !== data.chatroom._id)
        );
      }
    });

    return () => {
      socket.off("chatroomCreated");
      socket.off("chatroomDeleted");
    };
  }, []);

  return (
    <div className="chatrooms">
      <h2>Chatrooms</h2>
      {/* Search */}

      <div className="chatrooms__search">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setPage(1);
            setChatrooms([]);
            fetchChatrooms();
          }
          }
        >
        <TextField
          label="Search"
          variant="outlined"
          value={search}

          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            setPage(1);
            setChatrooms([]);
            fetchChatrooms();
          }}

          style={{
     
            backgroundColor:  "var(--primary-color)",
            color:  "var(--primary-text-color)",
            padding: "10px",
            fontSize:  "15px",
            fontWeight: "500",
            borderRadius: "10px",
          }}

        >
          Search
        </Button> 

          {/* <Button
            variant="contained"
            onClick={() => {
              setChatrooms([]);
              fetchChatrooms();
            }}
          >
            Search
          </Button> */}

        </form>

      </div>

      <div className="chatrooms__list">
        {isLoading && <Loader />}
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        {chatrooms.map((chatroom) => (
          <div
            key={chatroom._id}
            className="chatrooms__listItem"
            style={{
              backgroundColor:
                getChatroomIdFromUrl() === chatroom._id
                  ? "var(--primary-color)"
                  : "var(--secondary-color)",
            }}
            onClick={() => {
              window.location.href = `/inbox/${chatroom._id}`;
            }}
          >
            <Tooltip
              key={chatroom._id}
              title={
                chatroom.name !== ""
                  ? chatroom.name
                  : users
                      .filter(
                        (u) =>
                          u._id !== user._id &&
                          u._id !== user.userId &&
                          chatroom.users.find((cu) => cu._id === u._id)
                      )
                      .map((u) => u.name)
                      .join(", ")
              }
            >
              <Avatar
                style={{
                  backgroundColor:
                    chatroom.name !== ""
                      ? getColor(chatroom.name)
                      : getColor(
                          chatroom.users.find((u) => u._id !== user._id).name
                        ),
                }}
              >
                {chatroom.name !== ""
                  ? chatroom.name.charAt(0)
                  : users
                      .filter(
                        (u) =>
                          u._id !== user._id &&
                          u._id !== user.userId &&
                          chatroom.users.find((cu) => cu._id === u._id)
                      )
                      .map((u) => u.name)
                      .join(", ")
                      .charAt(0)}
              </Avatar>
            </Tooltip>
            <h4
              style={{
                color: "white",
              }}
            >
              {chatroom.name !== ""
                ? chatroom.name
                : users
                    .filter(
                      (u) =>
                        u._id !== user._id &&
                        u._id !== user.userId &&
                        chatroom.users.find((cu) => cu._id === u._id)
                    )
                    .map((u) => u.name)
                    .join(", ")}
            </h4>
            <div></div>
          </div>
        ))}

        {totalChatrooms > chatrooms.length && (
          <div
            className="load-more"
            onClick={() => {
              if (totalChatrooms > chatrooms.length) {
                setPage(page + 1);
              }
            }}
          >
            Load More
          </div>
        )}
      </div>
    </div>
  );
};

export default Chatrooms;
