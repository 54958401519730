import React, { useState } from "react";

import { Add } from "@mui/icons-material";
import { AddPropertyForm, PopUpWindow } from "..";



const AddPropertyButton = (props) => {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [buttonWidth] = useState(
    window.innerWidth <= 768 ? "100%" : "auto"
  );





  const handleClick = () => {
    setPopUpOpen(true);
  };


  return (
    <>
      <div
        className="add-ticket-button"
        style={{
          display: JSON.parse(localStorage.getItem("user")).permissions
            .canAddProperty
            ? "flex"
            : "none",
        }}
      >
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: props.color || "var(--primary-color)",
            color: props.textColor || "var(--primary-text-color)",
            fontSize: props.fontSize || "15px",
            fontWeight: props.fontWeight || "600",
            width: props.width || buttonWidth,
          }}
          onClick={handleClick}
        >
          <Add />
          <span>Add Property</span>
        </button>
      </div>
      {popUpOpen && (
        <PopUpWindow
          onClose={() => {
            setPopUpOpen(false);

          }}
        >
          <AddPropertyForm setIsOpen={setPopUpOpen} isEdit={false} />
        </PopUpWindow>
      )}
    </>
  );
};

export default AddPropertyButton;
