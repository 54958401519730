import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  Inbox,
  CalendarMonth,
  Feed,
  Dashboard,
  Receipt,
  Group,
  MapsHomeWork,
  Person,
  FlightLand,
} from "@mui/icons-material";
import Collapse from "@material-ui/core/Collapse";
import logo from "../../images/rh_logo.png";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { text } from "@fortawesome/fontawesome-svg-core";

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.5s",
    }),
  },
  drawerPaper: {
    backgroundColor: "var(--primary-color)", // Ensure this CSS variable is defined
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.5s",
    }),
  },
  drawerPaperExpanded: {
    width: "250px", // Set your expanded width
  },
  drawerPaperCollapsed: {
    width: "90px", // Set your collapsed width
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(0, 1),
    backgroundColor: "var(--tertiary-color)", // Ensure this CSS variable is defined
    ...theme.mixins.toolbar,
  },

  toggleButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 100,
  },
  ListItemButton: {
    color: "var(--tertiary-color)", // Ensure this CSS variable is defined
    backgroundColor: "var(--primary-color)", // Ensure this CSS variable is defined
  },
  ListItemButtonActive: {
    color: "white", // Ensure this CSS variable is defined
    backgroundColor: "var(--secondary-color)", // Ensure this CSS variable is defined
  },
  ListItemIcon: {
    color: "var(--tertiary-color)", // Ensure this CSS variable is defined
  },
  ListItemIconActive: {
    color: "white", // Ensure this CSS variable is defined
  },
}));
const Sidebar = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("isCollapsed") === "true"
  );
  const [ticketsSubMenuOpen, setTicketsSubMenuOpen] = useState(false); // State to manage the tickets submenu visibility
  const [arrivalsSubMenuOpen, setArrivalsSubMenuOpen] = useState(false); // State to manage the arrivals submenu visibility
  const classes = useStyles({ isCollapsed });
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 768) {
      props.setWidth(0);
    } else {
      props.setWidth(isCollapsed ? "90px" : "250px");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    // Set initial sidebar width based on initial window size
    handleWindowSizeChange();


    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [isCollapsed]);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
    localStorage.setItem("isCollapsed", !isCollapsed);
    props.setWidth(isCollapsed ? "250px" : "90px");
  };

  const toggleTicketsSubMenu = () => {
    setTicketsSubMenuOpen(!ticketsSubMenuOpen);
  };

  const toggleArrivalsSubMenu = () => {
    setArrivalsSubMenuOpen(!arrivalsSubMenuOpen);
  };

  let menuItems = [
    {
      text: "Dashboard",
      href: "/dashboard",
      icon: <Dashboard />,
      onClick: () => navigate("/dashboard"),
    },
    {
      text: "Tickets",
      href: "/tickets",
      icon: <Feed />,
      onClick: () => {
        if (isCollapsed && !ticketsSubMenuOpen) {
          toggleTicketsSubMenu();
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          toggleTicketsSubMenu();
        }
      },
      submenu: [
        {
          text: "All Tickets",
          href: "/tickets/all",
          onClick: () => navigate("/tickets/all"),
        },
        {
          text: "Assigned To Me",
          href: "/tickets/assigned-to-me",
          onClick: () => navigate("/tickets/assigned-to-me"),
        },
        {
          text: "Created By Me",
          href: "/tickets/created-by-me",
          onClick: () => navigate("/tickets/created-by-me"),
        },
        {
          text: "In Progress",
          href: "/tickets/on-progress",
          onClick: () => navigate("/tickets/on-progress"),
        },
        {
          text: "Open Tickets",
          href: "/tickets/open",
          onClick: () => navigate("/tickets/open"),
        },
        {
          text: "Completed Tickets",
          href: "/tickets/closed",
          onClick: () => navigate("/tickets/completed"),
        },

        {
          text: "Archived Tickets",
          href: "/tickets/archived",
          onClick: () => navigate("/tickets/archived"),
        },
        // {
        //   text: "Open Tickets",
        //   href: "/tickets/open",
        //   onClick: () => navigate("/tickets/open"),
        // },
        // {
        //   text: "Closed Tickets",
        //   href: "/tickets/closed",
        //   onClick: () => navigate("/tickets/done"),
        // },

        {
          text: "Settings",
          href: "/tickets/settings",
          onClick: () => navigate("/tickets/settings"),
        },
      ],
    },

    {
      text: "Arrivals",
      href: "/arrivals",
      icon: <FlightLand />,
      onClick: () => {
        if (isCollapsed && !arrivalsSubMenuOpen) {
          toggleArrivalsSubMenu();
          handleToggle();
        } else if (isCollapsed) {
          handleToggle();
        } else {
          toggleArrivalsSubMenu();
        }
      },
      submenu: [

        {
          text: "All Arrivals",
          href: "/arrivals/all",
          onClick: () => navigate("/arrivals/all"),
        },
        {
          text: "My Upcoming Arrivals",
          href: "/arrivals/upcoming",
          onClick: () => navigate("/arrivals/upcoming"),
        },
        {
          text: "Assign Upcoming",
          href: "/arrivals/assign",
          onClick: () => navigate("/arrivals/assign"),
        },
     
        {
          text: "Settings",
          href: "/arrivals/settings",
          onClick: () => navigate("/arrivals/settings"),
        }
      ],
    },
    {
      text: "Chat",
      href: "/inbox",
      icon: <Inbox />,
      onClick: () => navigate("/inbox"),
    },
    // {
    //   text: "Calendar",
    //   href: "/calendar",
    //   icon: <CalendarMonth />,
    //   onClick: () => {},
    // },
    {
      text: "Reservations",
      href: "/reservations",
      icon: <Receipt />,
      onClick: () => navigate("/reservations"),
    },
    {
      text: "Properties",
      href: "/properties",
      icon: <MapsHomeWork />,
      onClick: () => navigate("/properties"),
    },
    {
      text: "Groups",
      href: "/groups",
      icon: <Group />,
      onClick: () => {
        navigate("/groups");
      },
    },
    {
      text: "Users",
      href: "/users",
      icon: <Person />,
      onClick: () => {
        navigate("/users");
      },
    },
  ];

  const filterMenuItems = (menuItems, user) => {
    return menuItems.reduce((acc, item) => {
      if (item.submenu) {
        const filteredSubmenu = item.submenu.filter(subItem => {
          if (subItem.text === "All Tickets" && !user.permissions.canViewAllTickets) return false;
          if (subItem.text === "Settings" && !user.permissions.canEditTicketSettings) return false;
          if (subItem.text === "Created By Me" && !user.permissions.canCreateTicket) return false;
          if (subItem.text === "Open Tickets" && !user.permissions.canViewOpenTickets) return false;
          if (subItem.text === "Completed Tickets" && !user.permissions.canViewCompletedTickets) return false;
          if (subItem.text ===  "Arrivals" && !user.permissions.canViewArrival  ) return false;
          if (subItem.text === "All Arrivals" && !user.permissions.canViewAllArrivals) return false;
          if (subItem.text === "My Upcoming Arrivals" && !user.permissions.canViewArrival) return false;
          if (subItem.text === "Assign Upcoming" && !user.permissions.canAssignArrival) return false;
          return true;
        });

        if (filteredSubmenu.length > 0) {
          acc.push({ ...item, submenu: filteredSubmenu });
        }
      } else {
        if (item.text === "Groups" && !user.permissions.canCreateGroup) return acc;
        if (item.text === "Users" && !user.permissions.canChangePermissions) return acc;
        if (item.text === "Properties" && !user.permissions.canViewProperty) return acc;
        if (item.text === "Reservations" && !user.permissions.canViewReservation) return acc;
       
        acc.push(item);
      }
      return acc;
    }, []);
  };

  menuItems = filterMenuItems(menuItems, user);
  

  return (
    <div className="sidebar">
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{
          paper: `${classes.drawerPaper} ${
            isCollapsed
              ? classes.drawerPaperCollapsed
              : classes.drawerPaperExpanded
          }`,
        }}
      >
        <div className={classes.toolbar}>
          <img
            src={logo}
            alt="logo"
            style={{
              width: isCollapsed ? "50px" : "100px",
              height: isCollapsed ? "60px" : "100px",
              transition: "all 0.5s",
            }}
          />
          <IconButton onClick={handleToggle} className={classes.toggleButton}>
            {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <List>
          {menuItems.map((item, index) => (
            <div key={index}>
              <Tooltip
                key={item.text}
                title={item.text}
                placement="right"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10],
                      },
                    },
                  ],
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      fontSize: "14px",
                      backgroundColor: "var(--tertiary-color)",
                    },
                  },
                }}
              >
                <ListItem
                  button
                  key={item.text}
                  onClick={item.onClick}
                  className={
                    item.href === window.location.pathname ||
                    item.submenu?.some(
                      (subItem) => subItem.href === window.location.pathname
                    )
                      ? classes.ListItemButtonActive
                      : classes.ListItemButton
                  }
                  style={{
                    paddingLeft: isCollapsed ? "25px" : "10px",
                    transition: "all 0.5s",
                  }}
                >
                  <ListItemIcon
                    className={
                      item.href === window.location.pathname ||
                      item.submenu?.some(
                        (subItem) => subItem.href === window.location.pathname
                      )
                        ? classes.ListItemIconActive
                        : classes.ListItemIcon
                    }
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    style={{
                      visibility: isCollapsed ? "hidden" : "visible",
                      opacity: isCollapsed ? "0" : "1",
                      transition: "all 0.5s",
                    }}
                  />
                  {item.submenu &&
                    !isCollapsed &&
                    (item.text === "Tickets" ? (
                      ticketsSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : item.text === "Arrivals" ? (
                      arrivalsSubMenuOpen ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : null)}
                </ListItem>
              </Tooltip>
              {item.submenu && !isCollapsed && (
                <Collapse
                  in={
                    item.text === "Tickets"
                      ? ticketsSubMenuOpen
                      : item.text === "Arrivals"
                      ? arrivalsSubMenuOpen
                      : false
                  }
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((subItem) => (
                      <Tooltip
                        title={subItem.text}
                        placement="right"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -10],
                              },
                            },
                          ],
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: "14px",
                              backgroundColor: "var(--tertiary-color)",
                            },
                          },
                        }}
                      >
                        <ListItem
                          button
                          key={subItem.text}
                          className={
                            subItem.href === window.location.pathname
                              ? classes.ListItemButtonActive
                              : classes.ListItemButton
                          }
                          onClick={subItem.onClick}
                        >
                          <ListItemText inset primary={subItem.text} />
                        </ListItem>
                      </Tooltip>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
