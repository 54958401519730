import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";

import "./Inbox.css";
import {
  AddReservationButton,
  AddTicketButton,
  Chatroom,
  Chatrooms,
  CreateChatroomButton,
  Sidebar,
  TaskManager,
  TicketTable,
} from "../../components";
import { PageStructure } from "../../pages";
import { Add, Task } from "@mui/icons-material";
const getChatroomIdFromUrl = () => {
  const url = window.location.href;
  const parts = url.split("/");
  return parts[parts.length - 1];
};



const Inbox = () => {
  const isMobile = window.innerWidth <= 768;
  return (
    <PageStructure>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          height: "100%",
          overflow: "hidden",
      }}
      >
        {
          localStorage.getItem("legacy") === "false" &&
          <CreateChatroomButton />}
        <div
          style={{
            padding: "0px",
            margin: "0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "100%",
            height: "100%",
            borderTop: "1px solid #e0e0e0",
          }}
        >
         
          
          {isMobile &&getChatroomIdFromUrl() === "inbox" && <Chatrooms />}
          {!isMobile && <Chatrooms />}

          {getChatroomIdFromUrl() !== "inbox" && <Chatroom />}
        </div>
      </div>
    </PageStructure>
  );
};
export default Inbox;
