import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../contexts/UserContext";
import Select, { components } from "react-select";
import { Checkbox, Tooltip, Alert } from "@mui/material";

import "./Users.css";
import { Profile, UsersPermissions } from "../../components";
import { PageStructure } from "../../pages";
import { Loader } from "../../components";
import apiUrl from "../../components/api";

import axios from "axios";

const CustomOption = (props) => {
  return (
    <Tooltip
      title={props.data.tooltip}
      placement={props.data.tooltipPlacement || "bottom"}
      arrow
      PopperProps={{
        style: { zIndex: 10001 },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: "14px",
          },
        },
      }}
    >
      <div>
        <components.Option {...props} />
      </div>
    </Tooltip>
  );
};
const Users = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUserChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/auth/getUsersFullInfo`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        });
        setUsers(response.data.users);
      } catch (error) {
        console.error("Failed to fetch users: ", error);
      }
      setIsLoading(false);
    };
    fetchUsers();
  }, []);
  return (
    <PageStructure>
      <h1>Users' Permissions</h1>

      <Select
        className="users-permissions__select"
        options={users.map((user) => ({
          value: user,
          label: user.username,
          tooltip: user.name,
        }))}
        onChange={handleUserChange}
        placeholder="Select a user..."
        components={{ Option: CustomOption }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--secondary-color)",
            primary25: "var(--tertiary-color)",
          },
        })}
        isClearable
      />

      {isLoading && <Loader />}
      {selectedUser && (
        <div
        style={{ marginTop: "20px" }}
        >
           <Profile key={selectedUser.value._id} user={selectedUser.value} /> 
        </div>
      )}
    </PageStructure>
  );
};
export default Users;
