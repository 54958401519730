import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from "draft-js";
import { Delete } from "@mui/icons-material";
import { Tooltip, Alert, TextField } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import apiUrl from "../api";

import { ContentState, convertFromHTML } from "draft-js";

import DraftEditor from "../Editor/DraftEditor";
function formatDateData(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}
const EditArrivalForm = (props) => {
  const [selectedArrival, setSelectedArrival] = useState(props.selectedArrival);

  // Convert HTML string to EditorState
  const contentBlock = htmlToDraft(selectedArrival.notes);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newNote, setNewNote] = useState(editorState);
  const arrivals = props.arrivals;
  const setArrivals = props.setArrivals;

  const user = JSON.parse(localStorage.getItem("user"));

  const handleNewNoteChange = (editorState) => {
    setNewNote(editorState);
  };

  const handleUpdateArrival = async (arrivalId, notes, date) => {
    try {
      setSuccessMessage("");
      setErrorMessage("");
      const response = await axios.put(
        `${apiUrl}/arrival/update/${arrivalId}`,
        {
          date: new Date(date).setHours(0, 0, 0, 0),
          notes: notes.length > 0 ? notes : "No notes",
          done: selectedArrival.done.value === "done" ? true : false,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setArrivals(
        arrivals.map((arrival) => {
          if (arrival._id === arrivalId) {
            return {
              ...arrival,
              date: response.data.arrival.date,
              notes: notes.length > 0 ? notes : "No notes",
              done: selectedArrival.done.value === "done" ? true : false,
            };
          }
          return arrival;
        })
      );
      setSuccessMessage("Arrival updated successfully");
    } catch (error) {
      console.error("Failed to update arrival: ", error);
      setErrorMessage("Failed to update arrival");
    }
  };

  return (
    <div>
      <h2>Edit Arrival</h2>

      <input
        value={formatDateData(selectedArrival.date)}
        type="date"
        className="form-field-input"
        style={{}}
        onChange={(e) => {
          setSelectedArrival((prevArrival) => ({
            ...prevArrival,
            date: e.target.value,
          }));
        }}
      />

      <Select
        id="status"
        name="status"
        value={
          selectedArrival.done
            ? { value: "done", label: "Done" }
            : { value: "pending", label: "Pending" }
        }
        onChange={(e) => {
          setSelectedArrival((prevArrival) => ({
            ...prevArrival,
            done: e.value === "done" ? true : false,
          }));
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "var(--primary-color)",
            primary25: "var(--secondary-color)",
          },
        })}
        options={[
          { value: "done", label: "Done" },
          { value: "pending", label: "Pending" },
        ]} // Add more options as needed
        isClearable
      />

      <DraftEditor editorState={newNote} setEditorState={handleNewNoteChange} />
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div className="add-ticket-button">
        <button
          className="add-ticket-button__button"
          style={{
            backgroundColor: "var(--primary-color)",
            color: "var(--primary-text-color)",
            fontSize: "15px",
            fontWeight: "600",
            width: "100%",
          }}
          onClick={() => {
            // handleUpdateArrival(selectedArrival, newNote);
            handleUpdateArrival(
              selectedArrival._id,
              draftToHtml(convertToRaw(newNote.getCurrentContent())),
              selectedArrival.date
            );
          }}
        >
          <span>Update Notes</span>
        </button>
      </div>
    </div>
  );
};

export default EditArrivalForm;
