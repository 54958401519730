import React, { useEffect, useRef } from "react";
import {
  Editor,
  RichUtils,
  convertToRaw,
  ContentState,
  DefaultDraftBlockRenderMap
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import Immutable from 'immutable';
import Toolbar from "./Toolbar/Toolbar";
import "./DraftEditor.css";

const DraftEditor = ({ editorState, setEditorState }) => {
  const editor = useRef(null);

  useEffect(() => {
    focusEditor();
  }, []);

  const focusEditor = () => {
    editor.current.focus();
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };

  const styleMap = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
    HIGHLIGHT: {
      backgroundColor: "#F4F7A5",
    },
    UNDERLINE: {
      textDecoration: 'underline',
    },
    UPPERCASE: {
      textTransform: "uppercase",
    },
    LOWERCASE: {
      textTransform: "lowercase",
    },
    CODEBLOCK: {
      fontFamily: '"fira-code", "monospace"',
      fontSize: "inherit",
      background: "#ffeff0",
      fontStyle: "italic",
      lineHeight: 1.5,
      padding: "0.3rem 0.5rem",
      borderRadius: "0.2rem",
    },
    SUPERSCRIPT: {
      verticalAlign: "super",
      fontSize: "80%",
    },
    SUBSCRIPT: {
      verticalAlign: "sub",
      fontSize: "80%",
    }
  };

  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    switch (type) {
      case "blockQuote":
        return "superFancyBlockquote";
      case "leftAlign":
        return "leftAlign";
      case "rightAlign":
        return "rightAlign";
      case "centerAlign":
        return "centerAlign";
      case "justifyAlign":
        return "justifyAlign";
      default:
        return null;
    }
  };

  const blockRenderMap = Immutable.Map({
    'unstyled': {
      element: 'p',
    }
  });

  const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

  const customInlineStyleFn = (styleSet) => {
    const styles = [];
    styleSet.forEach((style) => {
      switch (style) {
        case 'HIGHLIGHT':
          styles.push({ element: 'm' });
          break;
        case 'UNDERLINE':
          styles.push({ element: 'u' });
          break;
        case 'UPPERCASE':
          styles.push({ style: { textTransform: 'uppercase' } });
          break;
        case 'LOWERCASE':
          styles.push({ style: { textTransform: 'lowercase' } });
          break;
        case 'CODEBLOCK':
          styles.push({
            style: {
              fontFamily: '"fira-code", "monospace"',
              background: '#ffeff0',
              fontStyle: 'italic',
              lineHeight: 1.5,
              padding: '0.3rem 0.5rem',
              borderRadius: '0.2rem',
            }
          });
          break;
        case 'SUPERSCRIPT':
          styles.push({ style: { verticalAlign: 'super', fontSize: '80%' } });
          break;
        case 'SUBSCRIPT':
          styles.push({ style: { verticalAlign: 'sub', fontSize: '80%' } });
          break;
        default:
          break;
      }
    });
    return styles;
  };

  const blockRenderFn = (block) => {
    switch (block.type) {
      case 'header-one':
        return `<h1>${block.text}</h1><br />`;
      case 'header-two':
        return `<h2>${block.text}</h2><br />`;
      case 'header-three':
        return `<h3>${block.text}</h3><br />`;
      case 'header-four':
        return `<h4>${block.text}</h4><br />`;
      case 'header-five':
        return `<h5>${block.text}</h5><br />`;
      case 'header-six':
        return `<h6>${block.text}</h6><br />`;
      default:
        return block.text.split('\n').join('<br />');
    }
  };

  const logHTML = () => {
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(
      convertToRaw(contentState),
      undefined,
      blockRenderFn,
      customInlineStyleFn
    );

  };

  return (
    <div className="editor-wrapper" onClick={focusEditor}>
      <Toolbar editorState={editorState} setEditorState={setEditorState} handleKeyCommand={handleKeyCommand} />
      <div className="editor-container">
        <Editor
          ref={editor}
          placeholder=""
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          customStyleMap={styleMap}
          blockStyleFn={myBlockStyleFn}
          blockRenderMap={extendedBlockRenderMap}
          onChange={(editorState) => {
            const contentState = editorState.getCurrentContent();
            setEditorState(editorState);
            const html = draftToHtml(
              convertToRaw(contentState),
              undefined,
              blockRenderFn,
              customInlineStyleFn
            );

          }}
        />
      </div>
      
    </div>
  );
};

export default DraftEditor;
