import React from "react";
import { useUser } from "../../contexts/UserContext";

import "./Arrival.css";
import { AddArrivalOption, ArrivalTablePagination, UpdateArrivalOption } from "../../components";
import { PageStructure } from "../../pages";
import ArrivalTable from "../../components/arrivalsTable/ArrivalTable";

const Arrivals = (props) => {
  return (
    <PageStructure>
      <h1>Arrivals</h1>
      <h2>{props.subtitle}</h2>
      <hr />

      <ArrivalTable 
        key={props.subtitle}
        canAssign={props.canAssign}
      />
      {/* <ArrivalTablePagination /> */}
    </PageStructure>
  );
};

export default Arrivals;
