import React, { useState, useEffect } from "react";
import { Upload } from "@mui/icons-material";
import axios from "axios";
import { TextField, Alert, Typography, Box } from "@mui/material";
import "./ViewProperty.css";
import Loader from "../loader/Loader";
import apiUrl from "../api";

// Custom Tab Component
const CustomTab = ({ label, isActive, onClick }) => (
  <button
    className={`custom-tab ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {label}
  </button>
);

// Make text clickable function
const makeClickable = (text) => {
  // Regex patterns for detecting URLs, emails, and phone numbers
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const emailPattern = /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b)/gi;
  const phonePattern = /(\b\d{3}[-.]?\d{3}[-.]?\d{4}\b)/g;

  // Replace URLs with anchor tags
  text = text.replace(
    urlPattern,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );

  // Replace emails with mailto links
  text = text.replace(
    emailPattern,
    (email) => `<a href="mailto:${email}">${email}</a>`
  );

  // Replace phone numbers with tel links
  text = text.replace(
    phonePattern,
    (phone) => `<a href="tel:${phone}">${phone}</a>`
  );

  return text;
};

const ViewProperty = (props) => {
  const [propertyName, setPropertyName] = useState(props.property?.name || "");
  const [owner_name, setOwnerName] = useState(props.property?.owner_name || "");
  const [owner_email, setOwnerEmail] = useState(
    props.property?.owner_email || ""
  );
  const [owner_phone, setOwnerPhone] = useState(
    props.property?.owner_phone || ""
  );
  const [owner_phone2, setOwnerPhone2] = useState(
    props.property?.owner_phone2 || ""
  );
  const [owner_home_number, setOwnerHomeNumber] = useState(
    props.property?.owner_home_number || ""
  );
  const [accounting_name, setaccountingName] = useState(
    props.property?.accounting_name || ""
  );
  const [accounting_phone, setaccountingPhone] = useState(
    props.property?.accounting_phone || ""
  );
  const [accounting_email, setaccountingEmail] = useState(
    props.property?.accounting_email || ""
  );
  const [accounting_phone2, setaccountingPhone2] = useState(
    props.property?.accounting_phone2 || ""
  );
  const [cleaning_name, setCleaningName] = useState(
    props.property?.cleaning_name || ""
  );
  const [cleaning_phone, setCleaningPhone] = useState(
    props.property?.cleaning_phone || ""
  );
  const [cleaning_email, setCleaningEmail] = useState(
    props.property?.cleaning_email || ""
  );
  const [cleaning_phone2, setCleaningPhone2] = useState(
    props.property?.cleaning_phone2 || ""
  );
  const [maintenance_name, setMaintenanceName] = useState(
    props.property?.maintenance_name || ""
  );
  const [maintenance_email, setMaintenanceEmail] = useState(
    props.property?.maintenance_email || ""
  );
  const [maintenance_phone, setMaintenancePhone] = useState(
    props.property?.maintenance_phone || ""
  );
  const [maintenance_phone2, setMaintenancePhone2] = useState(
    props.property?.maintenance_phone2 || ""
  );
  const [pool_name, setPoolName] = useState(props.property?.pool_name || "");
  const [pool_phone, setPoolPhone] = useState(props.property?.pool_phone || "");
  const [pool_email, setPoolEmail] = useState(props.property?.pool_email || "");
  const [pool_phone2, setPoolPhone2] = useState(
    props.property?.pool_phone2 || ""
  );
  const [property_location, setPropertyLocation] = useState(
    props.property?.property_location || ""
  );
  const [master_lock, setMasterLock] = useState(
    props.property?.master_lock || ""
  );
  const [wifi_username, setWifiUsername] = useState(
    props.property?.wifi_username || ""
  );
  const [wifi_password, setWifiPassword] = useState(
    props.property?.wifi_password || ""
  );
  const [manual_link, setManualLink] = useState(
    props.property?.manual_link || ""
  );
  const [arrivals, setArrivals] = useState(props.property?.arrivals || "");
  const [more_info, setMoreInfo] = useState(props.property?.more_info || "");
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const fetchProperty = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/property/getProperty/${props.propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setPropertyName(response.data.property.name);
      setOwnerName(response.data.property.owner_name);
      setOwnerEmail(response.data.property.owner_email);
      setOwnerPhone(response.data.property.owner_phone);
      setOwnerPhone2(response.data.property.owner_phone2);
      setOwnerHomeNumber(response.data.property.owner_home_number);
      setaccountingName(response.data.property.accounting_name);
      setaccountingPhone(response.data.property.accounting_phone);
      setaccountingEmail(response.data.property.accounting_email);
      setaccountingPhone2(response.data.property.accounting_phone2);
      setCleaningName(response.data.property.cleaning_name);
      setCleaningPhone(response.data.property.cleaning_phone);
      setCleaningEmail(response.data.property.cleaning_email);
      setCleaningPhone2(response.data.property.cleaning_phone2);
      setMaintenanceName(response.data.property.maintenance_name);
      setMaintenanceEmail(response.data.property.maintenance_email);
      setMaintenancePhone(response.data.property.maintenance_phone);
      setMaintenancePhone2(response.data.property.maintenance_phone2);
      setPoolName(response.data.property.pool_name);
      setPoolPhone(response.data.property.pool_phone);
      setPoolEmail(response.data.property.pool_email);
      setPoolPhone2(response.data.property.pool_phone2);
      setPropertyLocation(response.data.property.property_location);
      setMasterLock(response.data.property.master_lock);
      setWifiUsername(response.data.property.wifi_username);
      setWifiPassword(response.data.property.wifi_password);
      setManualLink(response.data.property.manual_link);
      setArrivals(response.data.property.arrivals);
      setMoreInfo(response.data.property.more_info);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props.propertyId) {
      fetchProperty();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className="view-property-container">
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div className="custom-tabs-wrapper">
            <div className="custom-tabs">
              <CustomTab
                label="Property Details"
                isActive={tabValue === 0}
                onClick={() => handleTabChange(0)}
              />
              <CustomTab
                label="Owner Details"
                isActive={tabValue === 1}
                onClick={() => handleTabChange(1)}
              />
              <CustomTab
                label="Accounting Details"
                isActive={tabValue === 2}
                onClick={() => handleTabChange(2)}
              />
              <CustomTab
                label="Cleaning Details"
                isActive={tabValue === 3}
                onClick={() => handleTabChange(3)}
              />
              <CustomTab
                label="Maintenance Details"
                isActive={tabValue === 4}
                onClick={() => handleTabChange(4)}
              />
              <CustomTab
                label="Pool Details"
                isActive={tabValue === 5}
                onClick={() => handleTabChange(5)}
              />
            </div>
          </div>

          <div>
            <div
              style={{
                display: tabValue === 0 ? "block" : "none",
              }}
              className="view-property-info"
            >
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Property Name:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>{propertyName}</p>
                </div>
              </div>
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Property Location:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(property_location),
                    }}
                  ></p>
                </div>
              </div>
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Master Lock:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>{master_lock}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Wifi Username:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p>{wifi_username}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Wifi Password:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p>{wifi_password}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Manual Link:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(manual_link),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Arrivals:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>
                    {arrivals
                      ? arrivals.is_group
                        ? arrivals.group.name
                        : arrivals.name
                      : ""}
                  </p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>More Info:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p>{more_info}</p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: tabValue === 1 ? "block" : "none",
              }}
              className="view-property-info"
            >
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Owner Name:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>{owner_name}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Owner Email:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(owner_email),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Owner Phone:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(owner_phone),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Owner Phone II:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(owner_phone2),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Owner Home Number:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(owner_home_number),
                    }}
                  ></p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: tabValue === 2 ? "block" : "none",
              }}
              className="view-property-info"
            >
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Accounting Name:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>{accounting_name}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Accounting Phone:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(accounting_phone),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Accounting Email:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(accounting_email),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Accounting Phone II:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(accounting_phone2),
                    }}
                  ></p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: tabValue === 3 ? "block" : "none",
              }}
              className="view-property-info"
            >
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Cleaning Name:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>{cleaning_name}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Cleaning Phone:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(cleaning_phone),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Cleaning Email:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(cleaning_email),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Cleaning Phone II:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(cleaning_phone2),
                    }}
                  ></p>
                </div>
              </div>
            </div>

            <div
              style={{
                display: tabValue === 4 ? "block" : "none",
              }}
              className="view-property-info"
            >
              <h4>
                <strong>Maintenance Name:</strong> {maintenance_name}
              </h4>
              <h4>
                <strong>Maintenance Phone:</strong>{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(maintenance_phone),
                  }}
                ></p>
              </h4>
              <h4>
                <strong>Maintenance Email:</strong>{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(maintenance_email),
                  }}
                ></p>
              </h4>
              <h4>
                <strong>Maintenance Phone II:</strong>{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: makeClickable(maintenance_phone2),
                  }}
                ></p>
              </h4>
            </div>

            <div
              style={{
                display: tabValue === 5 ? "block" : "none",
              }}
              className="view-property-info"
            >
              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Pool Name:</strong>
                  </h4>
                </div>

                <div className="property-info-description">
                  <p>{pool_name}</p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Pool Phone:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(pool_phone),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Pool Email:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(pool_email),
                    }}
                  ></p>
                </div>
              </div>

              <div className="property-info-container">
                <div className="property-info-title">
                  <h4>
                    <strong>Pool Phone II:</strong>
                  </h4>
                </div>
                <div className="property-info-description">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: makeClickable(pool_phone2),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewProperty;
