import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
  Chip,
  TableSortLabel,
  Button,
  TextField,
  Alert,
  Avatar,
  Checkbox,
  Pagination,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import Select from "react-select";
import { RemoveRedEye, Search, Clear } from "@mui/icons-material/";
import { debounce } from "lodash"; // Import debounce function from lodash

import {
  Loader,
  PopUpWindow,
  ViewArrivalForm,
  DraftEditor,
  CreateArrivalButton,
  EditArrivalForm,
} from "../../components";
import apiUrl from "../api";

import { CheckCircle, Pending } from "@mui/icons-material/";
import io from "socket.io-client";
import axios from "axios";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateCalendar(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const findDueDateColor = (dueDate, selectedDay) => {
  const date = new Date(dueDate);
  const today = new Date(selectedDay);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#00F7FF";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const AssignedToCell = ({ userIds, allUsers, currentUser }) => {
  const users = userIds.map(
    (userId) =>
      allUsers.find((user) => user._id === userId) || { name: "Unknown" }
  );

  // Move current user to the front if they are assigned
  const sortedUsers = users.sort((a, b) =>
    a._id === currentUser._id ? -1 : b._id === currentUser._id ? 1 : 0
  );

  const displayedUsers = sortedUsers.slice(0, 3);
  const remainingUsers = sortedUsers.slice(3);

  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      {displayedUsers.map((user) => (
        <Tooltip key={user._id} title={user.name}>
          <Avatar
            style={{
              backgroundColor: getColor(user.name),
              border: user._id === currentUser._id ? "2px solid black" : "none",
            }}
          >
            {user.name[0]}
          </Avatar>
        </Tooltip>
      ))}
      {remainingUsers.length > 0 && (
        <Tooltip
          title={
            <Box>
              {remainingUsers.map((user, index) => (
                <Typography key={index} variant="body2">
                  {user.name}
                </Typography>
              ))}
            </Box>
          }
        >
          <Avatar>
            <Typography variant="caption">+{remainingUsers.length}</Typography>
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

const stringOutArray = (array) => {
  let string = "";
  if (array === null || array === undefined || array === "") {
    return string;
  }
  array.forEach((element) => {
    string += element.value._id + ",";
  });
  return string.slice(0, -1);
};
const UserChip = ({ user, tasks, onClick, isActive }) => {
  return (
    <div className={`user-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: getColor(user.name),
          width: "30px",
          height: "30px",
          fontSize: "20px",
        }}
      >
        {user.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {user.name} ({tasks})
        </p>
      </div>
    </div>
  );
};

const socket = io(`${apiUrl}/`);

const ArrivalTablePagination = () => {
  const [arrivals, setArrivals] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sort, setSort] = useState({
    field: "date",
    direction: "asc",
  });
  // const [selectedArrivals, setSelectedArrivals] = useState([]);
  const [selectedArrival, setSelectedArrival] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [viewArrival, setViewArrival] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  const [selectedDateStart, setSelectedDateStart] = useState(new Date());
  const [selectedDateEnd, setSelectedDateEnd] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [arrivalsPerPage, setArrivalsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchProperties, setSearchProperties] = useState([]);
  const [searchGroup, setSearchGroup] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [searchAssignedTo, setSearchAssignedTo] = useState([]);
  const [searchStatus, setSearchStatus] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const convertHTMLToText = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent ||
      doc.body.innerText ||
      doc.body.innerHTML.replace(/<[^>]*>/g, "")
      ? doc.body.textContent ||
          doc.body.innerText ||
          doc.body.innerHTML.replace(/<[^>]*>/g, "")
      : "No notes";
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apiUrl}/auth/getUsers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      setUsers(response.data.users);
    } catch (error) {
      console.error("Failed to fetch users: ", error);
    }
  };

  const fetchArrivals = async (
    term = "",
    selectedDateStart = "",
    selectedDateEnd = "",
    property = "",
    group = "",
    type = "",
    assignedTo = "",
    searchStatus = ""
  ) => {
    try {
      const dateParam =
        selectedDateStart !== "NaN-NaN-NaN"
          ? `&date=${formatDateCalendar(selectedDateStart)}`
          : "";
      const endDateParam =
        selectedDateEnd !== "NaN-NaN-NaN"
          ? `&endDate=${formatDateCalendar(selectedDateEnd)}`
          : "";

      const response = await axios.get(
        `${apiUrl}/arrival/getArrivals?reservationId=${term}&property=${stringOutArray(
          property
        )}&managedBy=${
          group ? group.map((item) => item.value.group._id).join(",") : ""
        }&type=${type ? type.value : ""}&assignedTo=${stringOutArray(
          assignedTo
        )}&page=${currentPage}&limit=${arrivalsPerPage}&sort=${
          sort.field
        }&direction=${sort.direction}&done=${
          searchStatus ? searchStatus.label === "Done" : ""
        }${dateParam}${endDateParam}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setArrivals(response.data.arrivals);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalArrivals);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchArrivalOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/arrival-options/all`,

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const fetchedOptions = response.data.arrivalOptions.map((option) => ({
        value: option,
        label: option.is_group ? option.group.name : option.name,
      }));

      setGroups(response.data.arrivalOptions);
    } catch (error) {
      console.error("Failed to fetch arrival options: ", error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/property/getPropertiesNames`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setProperties(response.data.propertiesNames);
    } catch (error) {
      console.error("Failed to fetch properties: ", error);
    }
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchArrivals(
      searchTerm,
      formatDateCalendar(selectedDateStart),
      formatDateCalendar(selectedDateEnd),
      searchProperties,
      searchGroup,
      searchType,
      searchAssignedTo,
      searchStatus
    );
  };

  const handleClearSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedDateStart(new Date());
    setSelectedDateEnd(new Date(new Date().setDate(new Date().getDate() + 1)));
    setSearchTerm("");
    setSort({ field: "date", direction: "asc" });
    setCurrentPage(1);
    setSearchProperties("");
    setSearchGroup("");
    setSearchType("");
    setSearchAssignedTo([]);
    fetchArrivals(
      "",
      formatDateCalendar(selectedDateStart),
      formatDateCalendar(selectedDateEnd),
      [],
      [],
      "",
      [],
      ""
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchArrivals(
      searchTerm,
      formatDateCalendar(selectedDateStart),
      formatDateCalendar(selectedDateEnd),
      searchProperties,
      searchGroup,
      searchType,
      searchAssignedTo,
      searchStatus
    );
  }, [currentPage, arrivalsPerPage, sort.field, sort.direction]);

  useEffect(() => {
    fetchUsers();
    fetchArrivalOptions();
    fetchProperties();
    setLoading(false);
  }, []);

  useEffect(() => {
    const handleArrivalUpdate = (arrival) => {
      if (arrivals.find((a) => a._id === arrival._id)) {
        setArrivals((prevArrivals) =>
          prevArrivals.map((a) => (a._id === arrival._id ? arrival : a))
        );
      }
    };

    const handleArrivalDelete = (arrivalId) => {
      setArrivals((prevArrivals) =>
        prevArrivals.filter((arrival) => arrival._id !== arrivalId)
      );
    };

    const handleArrivalFinished = (arrivalId) => {
      setArrivals((prevArrivals) =>
        prevArrivals.map((arrival) =>
          arrival._id === arrivalId ? { ...arrival, done: true } : arrival
        )
      );
    };

    if (!loading) {
      socket.on("arrivalUpdated", (arrival) => {
        handleArrivalUpdate(arrival);
      });
      socket.on("arrivalDeleted", handleArrivalDelete);
      socket.on("arrivalFinished", handleArrivalFinished);

      return () => {
        socket.off("arrivalUpdated");
        socket.off("arrivalDeleted");
        socket.off("arrivalFinished");
      };
    }
  }, [arrivals]);
  return (
    <>
      {/* Succes Alert */}
      {showAlert && (
        <PopUpWindow onClose={() => setShowAlert(false)}>
          <Alert severity="success">
            Arrival has been updated successfully!
          </Alert>
        </PopUpWindow>
      )}

      {/* View Arrival */}
      {viewArrival && (
        <PopUpWindow onClose={() => setViewArrival(false)}>
          <ViewArrivalForm
            arrivalId={selectedArrival._id}
            propertyName={selectedArrival.property.name}
            propertyId={selectedArrival.property._id}
            arrivalDate={selectedArrival.date}
            arrivalTime={selectedArrival.arrivalTime}
            arrivalType={selectedArrival.type}
            assignedTo={selectedArrival.assignedTo}
            notes={selectedArrival.notes}
            reservationId={selectedArrival.reservation}
            done={selectedArrival.done}
            closeForm={() => setViewArrival(false)}
            doneArrival={() => {
              setArrivals((prevArrivals) =>
                prevArrivals.map((arrival) =>
                  arrival._id === selectedArrival._id
                    ? { ...arrival, done: true }
                    : arrival
                )
              );
            }}
          />
        </PopUpWindow>
      )}
      {/* Loader */}
      {loading && <Loader />}

      {/* Search */}
      <form
        onSubmit={handleSearchSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            variant="outlined"
            placeholder="Search Reservation ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <Tooltip title="Clear search">
                    <IconButton
                      onClick={() => {
                        setSearchTerm("");
                        fetchArrivals();
                      }}
                      aria-label="clear search"
                    >
                      <Clear />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            sx={{ marginLeft: "10px", width: "20%" }}
            value={
              formatDateCalendar(selectedDateStart) ||
              formatDateCalendar(new Date())
            }
            onChange={(e) => {
              setSelectedDateStart(e.target.value);
              if (
                formatDateCalendar(selectedDateEnd) <
                formatDateCalendar(e.target.value)
              ) {
                setSelectedDateEnd(e.target.value);
              }
            }}
          />

          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            sx={{ marginLeft: "10px", width: "20%" }}
            InputProps={{
              inputProps: { min: formatDateCalendar(selectedDateStart) },
            }}
            value={formatDateCalendar(selectedDateEnd)}
            onChange={(e) => setSelectedDateEnd(e.target.value)}
          />
        </div>

        <div
          className="form-group"
          style={{
            zIndex: 1001,
          }}
        >
          <div className="form-field">
            <label htmlFor="type" className="form-field-label">
              Type:
            </label>
            <Select
              id="type"
              name="type"
              value={searchType}
              onChange={(selectedOption) => setSearchType(selectedOption)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={[
                { value: "Check-In", label: "Check-In" },
                { value: "Check-Out", label: "Check-Out" },
              ]} // Add more options as needed
              isClearable
            />
          </div>{" "}
          <div className="form-field">
            <label htmlFor="status" className="form-field-label">
              Status:
            </label>

            <Select
              id="status"
              name="status"
              value={searchStatus}
              onChange={(selectedOption) => setSearchStatus(selectedOption)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={[
                { value: "done", label: "Done" },
                { value: "pending", label: "Pending" },
              ]} // Add more options as needed
              isClearable
            />
          </div>
        </div>
        <div
          className="form-group"
          style={{
            zIndex: 1000,
          }}
        >
          <div className="form-field">
            <label htmlFor="property" className="form-field-label">
              Property:
            </label>
            <Select
              id="property"
              name="property"
              value={searchProperties}
              onChange={(selectedOption) => setSearchProperties(selectedOption)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={
                properties &&
                properties.map((property) => ({
                  value: property,
                  label: property.name,
                }))
              } // Add more options as needed
              isClearable
              isMulti
            />
          </div>

          <div className="form-field">
            <label htmlFor="group" className="form-field-label">
              Group:
            </label>
            <Select
              id="group"
              name="group"
              value={searchGroup}
              onChange={(selectedOption) => setSearchGroup(selectedOption)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              options={
                groups &&
                groups.map((group) => ({
                  value: group,
                  label: group.is_group ? group.group.name : group.name,
                }))
              } // Add more options as needed
              isClearable
              isMulti
            />
          </div>

          <div
            className="form-field"
            style={{
              visibility: user.permissions.canViewAllTickets
                ? "visible"
                : "hidden",
              width: user.permissions.canViewAllTickets ? "100%" : "0%",
            }}
          >
            <label htmlFor="assignedTo" className="form-field-label">
              Assigned To:
            </label>
            <Select
              id="assignedTo"
              name="assignedTo"
              value={searchAssignedTo}
              onChange={(selectedOption) => setSearchAssignedTo(selectedOption)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
                zIndex: 1000,
              })}
              options={
                users &&
                users.map((user) => ({
                  value: user,
                  label: user.username,
                }))
              } // Add more options as needed
              isMulti
              isClearable
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            style={{
              padding: "10px",
              width: "20%",
              backgroundColor: "var(--primary-color)",
              color: "var(--primary-text-color)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            onClick={handleSearchSubmit}
          >
            Search
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{
              marginLeft: "10px",
              padding: "10px",
              width: "20%",
              backgroundColor: "var(--secondary-color)",
              color: "var(--primary-text-color)",
              fontSize: "15px",
              fontWeight: "500",
            }}
            onClick={handleClearSearch}
          >
            Clear
          </Button>
        </div>
      </form>

      {/* Table */}
      {!loading && (
        <>
          {arrivals.length !== 0 && (
            <TableContainer
              component={Paper}
              sx={{
                maxheight: 540,
                overflow: "auto",
                overflowX: "scroll",
                width: "100%",
                paddingRight: "20px",
              }}
            >
              <Table
                aria-label="simple table"
                stickyHeader
                sx={{
                  padding: "10px",
                }}
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                    <Checkbox
                      checked={selectedArrivals.length === arrivals.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedArrivals(
                            arrivals.map((arrival) => arrival._id)
                          );
                        } else {
                          setSelectedArrivals([]);
                        }
                      }}
                    />
                  </TableCell> */}
                    <TableCell></TableCell>

                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "done"}
                        direction={
                          sort.field === "done" ? sort.direction : "asc"
                        }
                        // onClick={() => handleSort("done")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>

                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "property"}
                        direction={
                          sort.field === "property" ? sort.direction : "asc"
                        }
                        // onClick={() => handleSort("property")}
                      >
                        Property
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "date"}
                        direction={
                          sort.field === "date" ? sort.direction : "asc"
                        }
                        // onClick={() => handleSort("date")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "notes"}
                        direction={
                          sort.field === "notes" ? sort.direction : "asc"
                        }
                        // onClick={() => handleSort("notes")}
                      >
                        Notes
                      </TableSortLabel>
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <TableSortLabel
                        active={sort.field === "assignedTo"}
                        direction={
                          sort.field === "assignedTo" ? sort.direction : "asc"
                        }
                        // onClick={() => handleSort("assignedTo")}
                      >
                        Assigned To
                      </TableSortLabel>
                    </TableCell>

                    <TableCell>Group</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sort.field === "type"}
                        direction={
                          sort.field === "type" ? sort.direction : "asc"
                        }
                        // onClick={() => handleSort("type")}
                      >
                        Type
                      </TableSortLabel>
                    </TableCell>

                    {/* <TableCell></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrivals.map((arrival, index) => (
                    <TableRow key={index}>
                      {/* <TableCell>
                      <Checkbox
                        checked={selectedArrivals.includes(arrival._id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedArrivals([
                              ...selectedArrivals,
                              arrival._id,
                            ]);
                          } else {
                            setSelectedArrivals(
                              selectedArrivals.filter(
                                (id) => id !== arrival._id
                              )
                            );
                          }
                        }}
                      />
                    </TableCell> */}

                      <TableCell sx={{ padding: "0px" }}>
                        <Tooltip title="View" placement="right">
                          <Button
                            onClick={() => {
                              setSelectedArrival(arrival);
                              setViewArrival(true);
                            }}
                          >
                            <RemoveRedEye
                              sx={{ color: "var(--primary-color)" }}
                            />
                          </Button>
                        </Tooltip>
                      </TableCell>

                      <TableCell>
                        <Chip
                          icon={
                            arrival.done ? (
                              <CheckCircle style={{ color: "#fff" }} />
                            ) : (
                              <Pending style={{ color: "#fff" }} />
                            )
                          }
                          label={arrival.done ? "Done" : "Pending"}
                          style={{
                            backgroundColor: arrival.done
                              ? "#65FD5A"
                              : "#53B2FF",
                            color: findContrastColor(
                              arrival.done ? "#11ff00" : "#FB0909"
                            ),
                          }}
                        />
                      </TableCell>

                      <TableCell>{arrival.property.name}</TableCell>
                      <TableCell>
                        <Chip
                          label={formatDate(arrival.date)}
                          style={{
                            backgroundColor: findDueDateColor(
                              arrival.date,
                              selectedDateStart
                            ),
                            color: findContrastColor(
                              findDueDateColor(arrival.date, selectedDateStart)
                            ),
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {arrival.arrivalTime ? arrival.arrivalTime : "N/A"}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          maxWidth: "150px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Tooltip
                          title={convertHTMLToText(arrival.notes)}
                          PopperProps={{
                            style: { zIndex: 10001 },
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -10],
                                },
                              },
                            ],
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                fontSize: "13px",
                              },
                            },
                          }}
                        >
                          <span>
                            {convertHTMLToText(arrival.notes).substring(0, 50) +
                              (convertHTMLToText(arrival.notes).length > 50
                                ? "..."
                                : "")}
                          </span>
                        </Tooltip>
                      </TableCell>

                      <TableCell>
                        {arrival.assignedTo && (
                          <AssignedToCell
                            userIds={[arrival.assignedTo]}
                            allUsers={[...users]}
                            currentUser={user}
                          />
                        )}
                      </TableCell>

                      <TableCell>
                        <Chip
                          label={
                            groups.find(
                              (item) => item.group?._id === arrival.managedBy
                            )
                              ? groups.find(
                                  (item) =>
                                    item.group?._id === arrival.managedBy
                                ).group.name
                              : "Unknown"
                          }
                        />
                      </TableCell>

                      <TableCell>
                        {capitalizeFirstLetter(arrival.type)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {arrivals.length === 0 && (
            <Alert severity="info">No arrivals found for this day</Alert>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
          >
            <Typography>
              Showing{" "}
              {Math.min((currentPage - 1) * arrivalsPerPage + 1, totalItems)}-
              {Math.min(currentPage * arrivalsPerPage, totalItems)} of{" "}
              {totalItems} items
            </Typography>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => {
                setCurrentPage(value);
              }}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
};

export default ArrivalTablePagination;
