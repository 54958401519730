import React, { useState, useEffect, useRef } from "react";
import { Info } from "@mui/icons-material";
import axios from "axios";
import io from "socket.io-client";

import {
  TextField,
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  MenuItem,
  Chip,
  Avatar,
  Box,
} from "@mui/material";

import { Loader, PopUpWindow, StyledTextareaInbox } from "../";
import {
  AttachFile,
  Send,
  Delete,
  Article,
  Download,
} from "@mui/icons-material";
import "./Chatroom.css";
import apiUrl from "../api";

const sitelocation = `${apiUrl}/`; 

const parseAndHighlightUsernames = (text, allUsers) => {
  const usernamePattern = /@(\w+)/g;
  const allUsernames = allUsers.map((user) => user.username); // Assuming your user objects have a username field

  return text.split(usernamePattern).map((part, index) => {
    if (index % 2 === 1 && allUsernames.includes(part)) {
      return (
        <span
          key={index}
          style={{
            backgroundColor: "var(--tertiary-color)",
            color: "#fff",
            textDecoration: "underline",
            padding: "2px",
          }}
        >
          @{part}
        </span>
      );
    }
    return part;
  });
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};
// Function to generate a color based on the username
const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const AssignedToCell = ({ userName }) => {
  return (
    <Box display="flex" gap={1} justifyContent={"center"}>
      <Tooltip key={userName} title={userName}>
        <Avatar style={{ backgroundColor: getColor(userName) }}>
          {userName.charAt(0)}
        </Avatar>
      </Tooltip>
    </Box>
  );
};
const checkFileType = (fileString) => {
  return fileString.split(".").pop().toLowerCase();
};

const isImageFile = (fileString) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  const fileExtension = checkFileType(fileString);
  return imageExtensions.includes(fileExtension);
};

const MessageBubble = ({ message, user, users }) => {
  return (
    <div
      className={`message-bubble-wrapper ${
        message.sender._id === user._id ? "sent" : "received"
      }`}
    >
      <div className="message-bubble__sender">
        <AssignedToCell userName={message.sender.name} />
      </div>
      <div
        className={`message-bubble ${
          message.sender._id === user._id ? "sent" : "received"
        }`}
      >
        <div className="message-bubble__content">
          <p>{parseAndHighlightUsernames(message.message, users)}</p>
          {message.attachments.length > 0 && (
            <>
              <div className="message-bubble__images">
                {message.attachments
                  .filter((file) => isImageFile(file))
                  .map((file, index) => (
                    <div key={index} className="message-bubble__image">
                      <img
                        src={`${sitelocation}${file}`}
                        alt={file}
                        onClick={() => {
                          window.open(`${sitelocation}${file}`);
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div className="message-bubble__attachments">
                {message.attachments
                  .filter((file) => !isImageFile(file))
                  .map((file, index) => (
                    <div
                      key={index}
                      className="message-bubble__attachment"
                      onClick={() => {
                        window.open(sitelocation + file, "_blank");
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <Article />
                      <a href={`${sitelocation}${file}`} target="_blank">
                        {file.split("/").pop()}
                      </a>
                      <Download />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>

        <div className="message-bubble__time"
          style={{
            

          }}
        >
          {new Date(message.updated_at).toLocaleDateString()} -{" "}
          {new Date(message.updated_at).toLocaleTimeString()}
        </div>
      </div>
    </div>
  );
};

const Chatroom = () => {
  const [chatroom, setChatroom] = useState({
    _id: "",
    name: "",
    attachments: [],
    users: [],
    messages: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [users, setUsers] = useState([]);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [textareaKey, setTextareaKey] = useState(Date.now());
  const [page, setPage] = useState(1);
  const [messagesReceived, setMessagesReceived] = useState(0);

  const [chatroomName, setChatroomName] = useState("");
  const [totalMessages, setTotalMessages] = useState(0);
  const fileInputRef = useRef(null);

  const user = JSON.parse(localStorage.getItem("user"));
  const getChatroomIdFromUrl = () => {
    const url = window.location.href;
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const messagesEndRef = useRef(null);


  const fetchChatroom = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/chatroom/getChatroom/${getChatroomIdFromUrl()}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setChatroom(response.data.chatroom);
      setChatroomName(
        response.data.chatroom.name ? response.data.chatroom.name :response.data.chatroom.users.filter((u) => u._id !== user._id).map((u) => u.username).join(", ")
      )
    } catch (error) {
      console.error("Failed to fetch messages: ", error);
      setError("Failed to fetch messages");
      setIsLoading(false);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/chatroom/getMessages/${getChatroomIdFromUrl()}?page=${page}`,

        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      const newMessages = response.data.messages;
      const existingMessageIds = new Set(
        chatroom.messages.map((msg) => msg._id)
      );

      // Filter out any new messages that are already present in the current state
      const uniqueNewMessages = newMessages.filter(
        (msg) => !existingMessageIds.has(msg._id)
      );

      if (page === 1) {
        setChatroom((prevChatroom) => ({
          ...prevChatroom,
          messages: uniqueNewMessages,
        }));
      } else {
        setChatroom((prevChatroom) => ({
          ...prevChatroom,
          messages: [...prevChatroom.messages, ...uniqueNewMessages],
        }));
      }

      setTotalMessages(response.data.totalItems);
    } catch (error) {
      console.error("Failed to fetch messages: ", error);
      setError("Failed to fetch messages");
    }
  };

  useEffect(() => {
    const socket = io(`${apiUrl}/`);

    const handleMessageSent = (data) => {
      if (data.chatroom !== getChatroomIdFromUrl()) return;

      setChatroom((prevChatroom) => ({
        ...prevChatroom,
        messages: [data.message, ...prevChatroom.messages],
      }));
      setMessagesReceived((prevMessagesReceived) => prevMessagesReceived + 1);
    };

    socket.on("messageSent", handleMessageSent);

    return () => {
      socket.off("messageSent");
    };
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
    const fetchData = async () => {
      await fetchChatroom();
      await fetchMessages();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (page > 1) {
      fetchMessages();
    }
  }, [page]);

  useEffect(() => {
    if (isLoading) return;
    scrollToBottom();
  }, [messagesReceived]);
  const clearMessage = () => {
    setNewMessage("");
    setSelectedFiles([]);
    setTextareaKey(Date.now());
  };
  // Adjusted to handle multiple file selections
  const handleFileSelection = (event) => {
    const newFiles = Array.from(event.target.files); // Convert FileList to Array
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append new files
  };
  // Remove a file based on its index
  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleNewMessage = async (files, messageText) => {
    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("attachments", files[i]);
      }
    }

    formData.append("message", messageText);

    try {
      await axios.put(
        `${apiUrl}/chatroom/sendMessage/${chatroom._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      setNewMessage("");
      setTextareaKey(Date.now());
      clearMessage();
    } catch (error) {
      console.error("Failed to send message: ", error);
      setError("Failed to send message");
    }
  };

  const handleMessageTextChange = (messageText) => {
    setNewMessage(messageText);
  };

  const scrollToBottom = () => {
    const messagesContainer = document.querySelector(
      ".chatroom-messages-container"
    );
    messagesContainer.scrollTo({
      top: messagesContainer.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleSendMessage = () => {
    if (newMessage.trim() !== "" || selectedFiles.length > 0) {
      handleNewMessage(selectedFiles, newMessage);
    }
  };

  return (
    <>

    {
      infoWindowOpen && (
        <PopUpWindow
          title="Chatroom Info"
          color="var(--secondary-color)"
          onClose={() => setInfoWindowOpen(false)}
        >
          <div className="chatroom-info">
            <div className="chatroom-info__users">
              <h3>Users</h3>
              <div className="chatroom-info__users-list">
                {chatroom.users.map((user, index) => (
                  <div key={index} className="chatroom-info__user">
                    <AssignedToCell userName={user.name} />
                    <p>{user.name}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </PopUpWindow>
      )
    }
    <div className="chatroom-wrapper">
      {isLoading && <Loader />}
      {error && <Alert severity="error">{error}</Alert>}

      {!isLoading && (
        <div className="chatroom">
          <div className="chatroom-header">
            <div className="chatroom-header__userInfo">
              <div className="chatroom-header__avatar">
                <AssignedToCell
                  userName={
                    chatroom.name
                      ? chatroom.name
                      : chatroom.users.filter((u) => u._id !== user._id)[0].name
                  }
                />
              </div>

              <div className="chatroom-header__userName">
                <h2 className="chatroom-header__name">
                  {chatroomName}
                </h2>
                {/* <h3 className="chatroom-header__status">Active</h3> */}
              </div>
            </div>

            <div className="chatroom-header__actions">
              <Info
                sx={{ cursor: "pointer" }}
                onClick={() => setInfoWindowOpen(true)}
              />
            </div>
          </div>
          <div className="chatroom-messages-container" ref={messagesEndRef}>
            {chatroom.messages.map((message, index) => (
              <MessageBubble
                key={index}
                message={message}
                user={user}
                users={chatroom.users}
              />
            ))}

            {chatroom.messages.length < totalMessages && (
              <div
                className="load-more"
                onClick={() => {
                  setPage((prevPage) => prevPage + 1);
                }}
              >
                Load More
              </div>
            )}
          </div>

          <div className="chatroom-input">
            <div className="chatroom-input__message">
              <StyledTextareaInbox
                reset={textareaKey}
                users={chatroom.users}
                onCommentChange={handleMessageTextChange}
                onSendMessage={handleSendMessage} // Passing the new prop
              />

              <div
                className="card-main-add-comment-buttons"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Tooltip
                  title="Attach File"
                  PopperProps={{
                    style: { zIndex: 10001 },
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: "14px",
                      },
                    },
                  }}
                >
                  <input
                    type="file"
                    multiple
                    onChange={handleFileSelection}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />

                  <AttachFile
                    sx={[
                      { color: "var(--primary-color)" },
                      { cursor: "pointer" },
                    ]}
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  />
                </Tooltip>

                <Tooltip title="Send" arrow>
                  <Send
                    sx={[
                      { color: "var(--primary-color)" },
                      { cursor: "pointer" },
                    ]}
                    onClick={() => {
                      if (
                        newMessage.trim() !== "" ||
                        selectedFiles.length > 0
                      ) {
                        handleNewMessage(selectedFiles, newMessage);
                      }
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="chatroom-input__attachments">
              {selectedFiles.length > 0 && (
                <div className="file-list">
                  <p>Selected Files:</p>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          color: "var(--primary-color)",
                        }}
                      >
                        {file.name}
                        <Tooltip
                          title="Remove File"
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                fontSize: "15px",
                              },
                            },
                            style: { zIndex: 10001, fontSize: "16px" },
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -10],
                                },
                              },
                            ],
                          }}
                          arrow
                          placement="right"
                        >
                          <Delete
                            sx={{
                              color: "var(--primary-color)",
                              fontSize: "20px",
                              cursor: "pointer",
                              "&:hover": {
                                color: "var(--secondary-color)",
                              },
                              transition: "color 0.3s",
                              alignSelf: "center",
                              textAlign: "center",

                              padding: "10px",
                            }}
                            onClick={() => removeFile(index)}
                          />
                        </Tooltip>
                        {/* Adjust styling as needed */}
                      </li>
                    ))}
                  </ul>
                  <p>Total Files: {selectedFiles.length}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
    </>

  );
};

export default Chatroom;
